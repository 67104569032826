import React, { Component } from "react";
import { Form } from "react-bootstrap";

class Forminputtext extends Component {
  state = {};
  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        <Form.Control
          name={this.props.name}
          value={this.props.projectName}
          onChange={this.props.onchange}
          placeholder={this.props.placeholder}
        />
        {this.props.div}
      </Form.Group>
    );
  }
}

export default Forminputtext;
