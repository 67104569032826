import React, { Component } from "react";
import {
  Form,
  Col,
  Button,
  Container,
  Row,
} from "react-bootstrap";
import { Modal, Alert } from "rsuite";
import jsPDF from "jspdf";
import axios from "axios";
import Joi from "joi-browser";
import DatePICKER from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import data from "../../../../env/config";
import calculationContext from "../../../context/calculationContext";
import config from "../../../../env/config";
import CardComponent from "./../../../common/card";
import Forminputtext from "../../../common/Forminputtext";
import PdfModal from "../../../common/PdfModal";

class Bundle extends Component {
  state = {
    bundleList: [],
    filterBundleList: [],
    selectedBundles: [],
    selectedTimeFrameBundle: { factor: 1 },
    fresh: true,
    smd: false,
    data: data,
    timeFrame: "",
    regions: "",
    services: [],
    rateOffered: 0,
    min: 8,
    max: 25,
    totalHours: 0,
    selectedData: {
      regions: { regionFactor: "", regionName: "" },
      services: { expertise: "0", discount: 0 },
      currencies: { currencyFactor: "1", currencySymbol: "$" },
      countries: {},
    },
    totalCost: 0,
    projectName: "",
    errors: [],
    errorlen: 1,
    prospectName: "",
    expiryDate: "",
    currencies: "",
    finalPriceWithDiscount: 0,
    discount: "",
    currencyButton: true,
    discountButton: true,
  };
  schema = {
    projectName: Joi.string().max(24).required().label("Project Name"),
    prospectName: Joi.string().max(24).required().label("Prospect Name"),
  };
  componentDidMount = () => {
    if (this.props.match.params.id) {
      const id = this.props.match.params.id.match(/\d/g).join("");
      const token = {
        token: data.currentUser.token,
      };
      const api =
        Number(data.currentUser.user_type) === 1
          ? data[data.currentUser.user_type].getTemplateById + id
          : data[data.currentUser.user_type].getTemplateById +
          data.currentUser.id +
          "/" +
          id;

      axios
        .post(api, token)
        .then(async (res) => {
          if (res.data.status === 200) {
            let estimationData = {};
            if (Number(data.currentUser.user_type) === 1) {
              estimationData = JSON.parse(res.data.data.estimationData);
            } else {
              estimationData = JSON.parse(res.data.data[0].estimationData);
            }
            this.alert("You Can Edit Now !!");

            await this.setState({
              fresh: false,
              id,
              selectedTimeFrameBundle: estimationData.selectedTimeFrameBundle,
              selectedBundles: estimationData.selectedBundles,
              regions: estimationData.regions,
              services: estimationData.services,
              timeFrame: estimationData.timeFrame,
              discount: estimationData.discount,
              currencies: estimationData.currencies,
              selectedData: estimationData.selectedData,
              projectName: estimationData.projectName,
              prospectName: estimationData.prospectName,
              expiryDate: estimationData.expiryDate,
            });

            let serviceIDd = estimationData.selectedData.services.id;
            setTimeout(() => {
              let foundData = data.globalData.services.filter((element) => {
                return element.id === serviceIDd;
              });
              if (foundData.length) {
                let services = foundData[0];
                this.setState({
                  selectedData: { ...this.state.selectedData, services },
                });
              }
            }, 1000);
            this.calculate();
          } else if (res.status === 201) {
            this.danger("Something Wrong, Try Later!");
          }
        })
        .catch((error) => {
          this.danger("Error From Server, Try Later!");
        });
    }

    axios
      .post(data[data.currentUser.user_type].getServiceBundle, {
        token: data.currentUser.token,
      })
      .then((res) => {
        if (Number(res.data.status) === 200) {
          // console.log(res.data.data);
          let data = res.data.data;
          this.setState({
            bundleList: data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });

    let a = new Date();
    let Today =
      ("0" + a.getDate()).slice(-2) +
      "-" +
      ("0" + (a.getMonth() + 1)).slice(-2) +
      "-" +
      a.getFullYear();

    let d = new Date();
    d.setDate(d.getDate() + data.expiryDays);
    let expiryDate =
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear();

    this.setState({
      todayDate: Today,
      userId: this.state.data.currentUser.id,
      expiryDate,
    });
  };
  handleSelectDataChange = async (id) => {
    let foundData = await data.globalData.services.filter(
      (element) => element.id === id
    );
    if (foundData.length) {
      let services = foundData[0];
      this.setState({
        selectedData: { ...this.state.selectedData, services },
      });
    }
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      projectName: this.state.projectName,
      prospectName: this.state.prospectName,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleSelect = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;

    await this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
    if (input.name === "tags") {
      let tagArray = input.value.split(",");
      this.setState({ tags: tagArray });
    }

    if (
      input.name === "services" ||
      input.name === "regions" ||
      input.name === "currencies"
    ) {
      if (input.name === "regions") {
        let selectedRegions = input.value.split("###");
        var foundData = await data.globalData[input.name].filter((element) => {
          return selectedRegions[1] === element.id;
        });
      } else {
        foundData = await data.globalData[input.name].filter((element) => {
          return input.value === element.id;
        });
      }
      if (foundData.length) {
        this.state.selectedData[input.name] = foundData[0];
        if (input.name === "services" || input.name === "regions") {
          // console.log(foundData[0], "===");
          if (input.name === "services") {
            console.log(foundData[0].serviceName, "services");
            this.setState({
              tags: [foundData[0].serviceName],
            });
          } else {
            console.log(foundData[0], "region");
          }
        }
      }
    }

    //finding bundle with service id
    if (input.name === "services") {
      let serviceId = this.state.services;
      let filterBundleList = this.state.bundleList.filter((list) => {
        list.checkTrigger = false;
        return Number(list.service_Id) === Number(serviceId);
      });
      this.setState({ filterBundleList });
    }

    if (input.name === "discount") {
      if (input.value > 100) {
        this.setState({ discount: 100 });
      } else if (input.value < 0) {
        this.setState({ discount: "" });
      }

      if (input.value > Number(this.state.selectedData.services.discount)) {
        this.danger("Max Discount Reached");
      } else if (input.value === "") {
        this.alert("Discount Removed");
        this.setState({ discount: "" });
      } else if (
        input.value > 0 &&
        input.value < Number(this.state.selectedData.services.discount)
      ) {
        this.alert("Discount Applied!");
      }

      if (input.value === 0) {
        this.setState({
          discount: "",
        });
      }
    }

    if (input.name === "timeFrame") {
      if (input.value) {
        let z = config.timeFrameBundle.filter(
          (x) => Number(x.id) === Number(input.value)
        );
        console.log("z[0]====dddddddddddddddddd======", z[0]);
        this.setState({ selectedTimeFrameBundle: z[0] });
        // console.log(this.state.selectedTimeFrameBundle);
      } else {
        this.setState({
          selectedTimeFrameBundle: { factor: 1 },
        });
      }
    }
    this.calculate();
  };
  handleChange = (event) => {
    let date = new Date(event);
    let formatedExpiryDate =
      ("0" + date.getDate()).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();
    this.setState({
      expiryDate: formatedExpiryDate,
    });
  };
  handlecurrencyButton = () => {
    this.setState({
      currencyButton: !this.state.currencyButton,
    });
  };
  handleDiscountButton = () => {
    this.setState({
      discountButton: !this.state.discountButton,
    });
  };
  onSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      let zh = Object.values(errors);
      this.danger(zh[0]);
      return;
    }

    this.setState({
      sm: true,
      smd: false,
    });
  };
  handleAdd = () => {
    const template = {
      title: this.state.projectName,
      type: "Bundle",
      userId: this.state.userId,
      tags: JSON.stringify(this.state.tags),

      estimationData: JSON.stringify({
        selectedTimeFrameBundle: this.state.selectedTimeFrameBundle.label,
        selectedBundles: this.state.selectedBundles,
        regions: this.state.regions,
        services: this.state.services,
        timeFrame: this.state.timeFrame,
        discount: this.state.discount,
        currencies: this.state.currencies,
        selectedData: this.state.selectedData,
        projectName: this.state.projectName,
        prospectName: this.state.prospectName,
        expiryDate: this.state.expiryDate,
      }),
      token: data.currentUser.token,
    };
    let api =
      Number(data.currentUser.user_type) === 1
        ? data[data.currentUser.user_type].addTemplate
        : data[data.currentUser.user_type].addTemplate + data.currentUser.id;
    axios
      .post(api, template)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Calculation Saved!");
          this.htmlpdf();
          this.setState({
            addEditLoading: false,
            // projectName: "",
            // prospectName: "",
            smd: false,
            sm: false,
          });
        } else if (res.status === 201) {
          this.setState({
            regions: "",
            services: "",
            weeklyHours: "",
            timeFrame: "",
            projectName: "",
            prospectName: "",
            sm: false,
          });
          this.danger("Something Wrong, Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          regions: "",
          services: "",
          timeFrame: "",
          projectName: "",
          prospectName: "",
          sm: false,
        });
        this.danger("Error From Server, Try Later!");
      });
  };
  handleUpdate = () => {
    const { id } = this.state;
    const template = {
      title: this.state.projectName,
      type: "Bundle",
      status: 1,
      estimationData: JSON.stringify({
        regions: this.state.regions,
        services: this.state.services,
        weeklyHours: this.state.weeklyHours,
        timeFrame: this.state.timeFrame,
        complexity: this.state.complexity,
        expertise: this.state.expertise,
        discount: this.state.discount,
        currencies: this.state.currencies,
        selectedData: this.state.selectedData,
        projectName: this.state.projectName,
        prospectName: this.state.prospectName,
        expiryDate: this.state.expiryDate,
      }),
      token: data.currentUser.token,
    };
    let api =
      Number(data.currentUser.user_type) === 1
        ? data[data.currentUser.user_type].updateTemplate + id
        : data[data.currentUser.user_type].updateTemplate +
        data.currentUser.id +
        "/" +
        id;
    axios
      .post(api, template)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Calculation Updated!");
          window.location.href = "#/savedcalculation";
          this.handlePDF();
          this.setState({
            addEditLoading: false,
            projectName: "",
            // expiryDate:'',
            prospectName: "",
            smd: false,
            sm: false,
            id: "",
          });
        } else if (res.status === 201) {
          this.setState({
            regions: "",
            services: "",
            weeklyHours: "",
            timeFrame: "",
            complexity: "",
            expertise: "",
            projectName: "",
            prospectName: "",
            // expiryDate:'',
            sm: false,
          });
          this.danger("Something Wrong, Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          regions: "",
          services: "",
          weeklyHours: "",
          timeFrame: "",
          complexity: "",
          expertise: "",
          // expiryDate:'',
          projectName: "",
          prospectName: "",
          sm: false,
        });
        this.danger("Error From Server, Try Later!");
      });
  };
  calculate = async () => {
    let { selectedBundles, discount, selectedTimeFrameBundle, selectedData } =
      await this.state;

    let bundlesRecursive = await selectedBundles.filter(
      (x) => Number(x.type) === 2
    );
    let bundlesFixed = await selectedBundles.filter(
      (x) => Number(x.type) === 1
    );

    if (selectedTimeFrameBundle) {
      let totalRecursiveBundlePrice = 0;
      let totalFixedBundlePrice = 0;
      bundlesRecursive.map(
        (x) => (totalRecursiveBundlePrice += Number(x.servicePrice))
      );
      bundlesFixed.map(
        (x) => (totalFixedBundlePrice += Number(x.servicePrice))
      );
      const finalPrice =
        totalRecursiveBundlePrice *
        selectedTimeFrameBundle.label *
        selectedTimeFrameBundle.factor +
        totalFixedBundlePrice;

      const discountPrice = (finalPrice / 100) * discount;
      const finalPriceWithDiscount =
        (finalPrice - discountPrice) *
        selectedData.currencies.currencyFactor *
        selectedData.regions.regionFactor;

      /*  console.log("selectedTimeFrameBundle======", selectedTimeFrameBundle);
       console.log("bundlesRecursive======", bundlesRecursive);
       console.log("bundlesFixed======", bundlesFixed);
       console.log("finalPrice======", finalPrice);
       console.log("finalPriceWithDiscount======", finalPriceWithDiscount); */
      this.setState({
        rateOffered: finalPrice,
        finalPriceWithDiscount,
      });
      // console.log(this.state.selectedData.regions);
    }
  };

  htmlpdf = () => {
    const input = document.getElementById("bundleTemplate");
    const pdf = new jsPDF({ unit: "px", format: "a4", userUnit: "px" });

    //with inspect
    // pdf.html(input, { html2canvas: { scale: 0.66 } }).then(() => {
    //without inspect on desktop
    pdf.html(input, { html2canvas: { scale: 0.42 } }).then(() => {
      pdf.save("test.pdf");
    });
  };
  setSmdFalse = () => {
    if (this.props.match.params.id) {
      this.setState({
        smd: false,
      });
    } else {
      this.setState({
        smd: false,
        addEditLoading: false,
        projectName: "",
        prospectName: "",
      });
    }
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
    });
    this.setSmdFalse();
  };
  smdShow = () => {
    this.setState({
      smd: true,
    });
  };
  danger = (s) => {
    Alert.error(s, 4000);
  };
  alert = (s) => {
    Alert.success(s);
  };
  handleRedirect = () => {
    window.location.href = "#/savedcalculation";
  };
  handleSelectUnselectBundle = async (e) => {
    e.checkTrigger = e.checkTrigger ? false : true;
    await this.setState({
      filterBundleList: [...this.state.filterBundleList],
    });
    let selectedBundles = await this.state.filterBundleList.filter(
      (x) => x.checkTrigger === true
    );
    await this.setState({ selectedBundles });
    this.calculate();
  };
  handleType = (exp) => {
    let z = config.type.filter((x) => Number(x.value) === Number(exp));
    if (z.length) {
      return z[0].label
    }
  };

  render() {
    const allValue =
      this.state.services.length > 0 &&
      this.state.selectedBundles.length > 0 &&
      this.state.timeFrame.length > 0 &&
      this.state.regions;

    let filterService;
    let filterCurrency;
    let filterCountries;
    if (this.props.match.params.id) {
      filterService = data.globalData.services
        ? data.globalData.services.filter((ser) => {
          return Number(ser.status) === 1 || Number(ser.status) === 2;
        })
        : {};
      filterCurrency = data.globalData.currencies
        ? data.globalData.currencies.filter((ser) => {
          return Number(ser.status) === 1 || Number(ser.status) === 2;
        })
        : {};
      filterCountries = data.globalData.countries
        ? data.globalData.countries.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
    } else {
      // filterRegion = data.globalData.regions ? data.globalData.regions.filter(ser => Number(ser.status) == 1) : {}
      filterService = data.globalData.services
        ? data.globalData.services.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
      filterCurrency = data.globalData.currencies
        ? data.globalData.currencies.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
      filterCountries = data.globalData.countries
        ? data.globalData.countries.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
    }

    let timeFrameBundle = data.timeFrameBundle;
    return (
      <Container>
        <PdfModal
          show={this.state.sm}
          onhide={this.setSmFalse}
          prospectName={this.state.prospectName}
          todayDate={this.state.todayDate}
          projectName={this.state.projectName}
          serviceName={this.state.selectedData.services.serviceName}
          timeFrame={this.state.timeFrame}
          weeklyHours={this.state.weeklyHours}
          currencySymbol={this.state.selectedData.currencies.currencySymbol}
          finalPriceWithDiscount={this.state.finalPriceWithDiscount}
          expiryDate={this.state.expiryDate}
          id={this.state.id}
          handleAdd={this.handleAdd}
          calculationType="bundle"
        />
        <Modal
          size="sm"
          className=""
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
        >
          <Modal.Header>
            <Modal.Title>Save Calculation</Modal.Title>
          </Modal.Header>

          <Modal.Body className="">
            <Form onSubmit={this.onSubmit}>
              <Forminputtext
                label="Project Name"
                name="projectName"
                value={this.state.projectName}
                onchange={this.handleSelect}
                placeholder="Type Here"
              />
              <Forminputtext
                label="Prospect Name"
                name="prospectName"
                value={this.state.prospectName}
                onchange={this.handleSelect}
                placeholder="Type Here"
              />
              <Forminputtext
                label="Tags"
                name="tags"
                value={this.state.tags}
                onchange={this.handleSelect}
                placeholder="Enter Tags Here"
              />
              <Form.Group>
                <Form.Label className="mr-2">Expiry Date </Form.Label>

                <DatePICKER
                  value={this.state.expiryDate}
                  onChange={(date) => this.handleChange(date)}
                  minDate={new Date()}
                  maxDate={new Date(+new Date() + 12096e5)}
                />
              </Form.Group>

              <div>
                <Button type="submit">Enter</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Row className="border-bottom">
          <Col>
            <div className="d-flex justify-content-between align-items-center h5 my-3">
              Bundle Wise Calculation
              {this.props.match.params.id ? (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={this.handleRedirect}
                >
                  {" "}
                  Back
                </Button>
              ) : (
                  ""
                )}
            </div>
          </Col>
        </Row>

        <Row className="mt-5 d-flex justify-content-between">
          <Col sm={12} lg={6} className="">
            <Form>
              <Form.Row className="">
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted">Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.regions}
                    name="regions"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select Region</option>
                    {filterCountries.map((reg) => (
                      <option
                        key={reg.id}
                        value={reg.id + "###" + reg.regionId}
                      >
                        {reg.countryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted"> Service *</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.services}
                    name="services"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select</option>
                    {filterService.map((services) => (
                      <option key={services.id} value={services.id}>
                        {services.serviceName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted">
                    Service Bundle *
                  </Form.Label>
                  <div className="border border-grey">
                    <div className="m-3">
                      {this.state.filterBundleList.length !== 0 ? (
                        this.state.filterBundleList
                          .sort((a, b) => (a.type > b.type ? 1 : -1))
                          .map((list) => (
                            <Form.Group controlId={list.id}>
                              <Form.Check
                                className="m-1"
                                key={list.id}
                                type="checkbox"
                                checked={list.checkTrigger}
                                onClick={() =>
                                  this.handleSelectUnselectBundle(list)
                                }
                                label={
                                  list.serviceBundle +
                                  " - " +
                                  (Number(list.type) === 1
                                    ? "Fixed"
                                    : "Recursive")
                                }
                              />{" "}
                            </Form.Group>
                          ))
                      ) : (
                          <p>No Bundle Found</p>
                        )}
                    </div>
                  </div>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted"> Time Frame *</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.timeFrame}
                    name="timeFrame"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select</option>
                    {timeFrameBundle && timeFrameBundle.length
                      ? timeFrameBundle.map((frame) => (
                        <option key={frame.id} value={frame.id}>
                          {frame.label}
                        </option>
                      ))
                      : ""}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>

          <Col sm={12} lg={6} className="d-flex align-items-start">
            <CardComponent
              calculationName="bundle"
              selectedTimeFrameBundle={this.state.selectedTimeFrameBundle}
              allValue={allValue}
              selectedData={this.state.selectedData}
              selectedBundles={this.state.selectedBundles}
              symbol={this.state.selectedData.currencies.currencySymbol}
              finalprice={this.state.finalPriceWithDiscount}
              discount={this.state.discount}
              weeklyhours={this.state.weeklyHours}
              timeframe={this.state.timeFrame}
              maxdiscount={this.state.selectedData.services.discount}
              services={this.state.services}
              complexity={this.state.complexity}
              filtercurrency={filterCurrency}
              handleSelect={this.handleSelect}
              smdShow={this.smdShow}
              handleDiscountButton={this.handleDiscountButton}
              handlecurrencyButton={this.handlecurrencyButton}
              handleType={this.handleType}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Bundle.contextType = calculationContext;
export default Bundle;
