import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import { Alert } from "rsuite";
// import { useParams } from "react-router-dom";
import { Table, Button, Form, Col, Row, Container } from "react-bootstrap";
import config from "../../../env/config";
// import { matchSorter } from "match-sorter";

class Tab extends Component {
  state = {
    packageName: "",
    package: {
      essential: {
        essentialCalculation: {
          total: 0,
          totalFixedAmount: 0,
          recursiveMontly: 0,
        },
      },
      professional: {
        professionalCalculation: {
          total: 0,
          totalFixedAmount: 0,
          recursiveMontly: 0,
        },
      },
      enterprise: {
        enterpriseCalculation: {
          total: 0,
          totalFixedAmount: 0,
          recursiveMontly: 0,
        },
      },
    },

    selectedBundles: [],
    filterBundleList: [],
    serviceId: 0,
    errorlen: 1,
    errors: {},
    packageList: [],

    serviceListOption: "",
    addEditLoading: false,
    data: [],
    allData: [],
    serviceList: [],
    loader: true,

    serviceBundle: "",
    type: "",
    servicePrice: "",

    edit: 0,
  };
  schema = {
    packageName: Joi.string().required().label("Package Name"),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      packageName: this.state.packageName,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : "";
  };
  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;
    if (
      input.name === "professional" ||
      input.name === "essential" ||
      input.name === "enterprise"
    ) {
      let z = config.timeFrameBundle.filter(
        (x) => Number(x.id) === Number(input.value)
      );
      await this.setState({
        package: {
          ...this.state.package,
          [input.name]: {
            ...this.state.package[input.name],
            timeFrame: input.value,
            timeFrameBundle: z[0],
            // bundles:[]
          },
        },
      });
      this.calculate();
    }

    if (input.name === "serviceBundle") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
      if (this.state.data) {
        this.checkAlreadyExitSerivcePackages();
      }
    } else {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }

    if (input.name === "serviceId") {
      let serviceId = this.state.serviceId;

      let filterBundleList = this.state.data.filter(
        (list) => list.service_Id === serviceId
      );
      filterBundleList.map(
        (x) =>
          (x.package = {
            essentialCol: false,
            professionalCol: false,
            enterpriseCol: false,
          })
      );

      await this.setState({
        filterBundleList,
        package: {
          essential: {
            essentialCalculation: {
              total: 0,
              totalFixedAmount: 0,
              recursiveMontly: 0,
            },
            // timeFrameBundle:''
          },
          professional: {
            professionalCalculation: {
              total: 0,
              totalFixedAmount: 0,
              recursiveMontly: 0,
            },
            // timeFrameBundle:''
          },
          enterprise: {
            enterpriseCalculation: {
              total: 0,
              totalFixedAmount: 0,
              recursiveMontly: 0,
            },
            // timeFrameBundle:''
          },
        },
      });
    }

    if (input.name === "packageName") {
      this.checkAlreadyExitSerivcePackages();
    }
  };
  componentDidMount = () => {
    // console.log(this.props.match.params.id)
    // console.log(match.params.id)

    console.log(this.props.params);
    axios
      .post(config[config.currentUser.user_type].getService, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        let filterArray = [];
        if (Number(res.data.status) === 200) {
          array = res.data.data;
          filterArray = array.filter((ar) => Number(ar.status) === 1);
        }
        this.setState({
          serviceList: array,
          serviceListOption: filterArray,
        });
        this.getServiceBundleList();
        this.getServicePackagelist();
      })
      .catch((error) => {
        this.setState({
          loader: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.packageName) {
      if (!this.state.packageId && this.state.packageList.length && this.state.servicePackage) {
        let existPackage = await this.state.packageList.filter(
          (pack) =>
            // this.state.packageId !== pack.id &&
            pack.servicePackage.toLowerCase() ===
            this.state.packageName.toLowerCase()
        );

        console.log(existPackage);

        if (existPackage.length) {
          this.danger("Already Exist Package");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      } else {
        if (this.state.data.length && this.state.servicePackage) {
          let existPackage = await this.state.data.filter((pack) => {
            return (
              pack.servicePackage.toLowerCase() ===
              this.state.servicePackage.toLowerCase()
            );
          });
          if (existPackage.length) {
            this.danger("already Exist package");
            this.setState({
              checkAlreadyExit: true,
            });
          } else {
            this.setState({
              checkAlreadyExit: false,
            });
          }
        }
      }
    }
  };
  getServicePackagelist = () => {
    axios
      .post(config[config.currentUser.user_type].getServicePackage, {
        token: config.currentUser.token,
      })
      .then((res) => {
        // console.log(res.data.data);

        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          packageList: array,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  getServiceBundleList = async () => {
    await axios
      .post(config[config.currentUser.user_type].getServiceBundle, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          allData: array,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleSelectUnselectBundle = async ({ currentTarget: input }, e) => {
    e.package[input.name] = e.package[input.name] ? false : true;
    await this.setState({
      filterBundleList: [...this.state.filterBundleList],
    });

    let selectedBundleByEssential = await this.state.filterBundleList.filter(
      (x) => x.package.essentialCol === true
    );
    let selectedBundleByProfessional = await this.state.filterBundleList.filter(
      (x) => x.package.professionalCol === true
    );
    let selectedBundleByEnterprise = await this.state.filterBundleList.filter(
      (x) => x.package.enterpriseCol === true
    );

    this.setState({
      package: {
        essential: {
          ...this.state.package.essential,
          bundles: selectedBundleByEssential,
        },
        professional: {
          ...this.state.package.professional,
          bundles: selectedBundleByProfessional,
        },
        enterprise: {
          ...this.state.package.enterprise,
          bundles: selectedBundleByEnterprise,
        },
      },
    });
    // console.log(this.state.package);

    this.calculate();
  };
  recursiveAndFixed = (e) => {
    const { bundles } = e;
    let bundlesRecursive = bundles
      ? bundles.filter((b) => Number(b.type) === 2)
      : [];
    let bundlesFixed = bundles
      ? bundles.filter((b) => Number(b.type) === 1)
      : [];
    return { bundlesRecursive, bundlesFixed };
  };

  calculation = (bundles, timeFrameBundle) => {
    let totalRecursiveAmount = 0;
    bundles.bundlesRecursive.map(
      (x) => (totalRecursiveAmount += Number(x.servicePrice))
    );
    let recursiveMontly = totalRecursiveAmount * timeFrameBundle.factor;
    totalRecursiveAmount =
      totalRecursiveAmount * timeFrameBundle.label * timeFrameBundle.factor;

    let totalFixedAmount = 0;
    bundles.bundlesFixed.map(
      (x) => (totalFixedAmount += Number(x.servicePrice))
    );

    // console.log(timeFrameBundle)
    let total = totalRecursiveAmount + totalFixedAmount;
    let calculation = {
      total,
      totalFixedAmount,
      totalRecursiveAmount,
      recursiveMontly,
    };
    return calculation;
  };
  calculate = async () => {
    const { enterprise, essential, professional } = this.state.package;

    const enterpriseBoth = this.recursiveAndFixed(enterprise);
    const essentialBoth = this.recursiveAndFixed(essential);
    const professionalBoth = this.recursiveAndFixed(professional);

    let enterpriseTimeFrame = enterprise.timeFrameBundle;
    let essentialTimeFrame = essential.timeFrameBundle;
    let professionalTimeFrame = professional.timeFrameBundle;
    // console.log(essential)

    let enterpriseCalculation = enterpriseTimeFrame
      ? this.calculation(enterpriseBoth, enterpriseTimeFrame)
      : "";
    let essentialCalculation = essentialTimeFrame
      ? this.calculation(essentialBoth, essentialTimeFrame)
      : "";
    let professionalCalculation = professionalTimeFrame
      ? this.calculation(professionalBoth, professionalTimeFrame)
      : "";

    // console.log(enterpriseCalculation);
    // console.log(essentialCalculation);
    // console.log(professionalCalculation);

    this.setState({
      package: {
        ...this.state.package,
        enterprise: { ...this.state.package.enterprise, enterpriseCalculation },
        professional: {
          ...this.state.package.professional,
          professionalCalculation,
        },
        essential: { ...this.state.package.essential, essentialCalculation },
      },
    });
    // console.log(this.state.package.professional);
  };

  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      return;
    } else if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Package");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    axios
      .post(config[config.currentUser.user_type].addServicePackage, {
        serviceId: this.state.serviceId,
        servicePackage: this.state.packageName,
        // expertise: this.state.expertise,
        essential: this.state.package.essential.essentialCalculation.total,
        professional: this.state.package.professional.professionalCalculation
          .total,
        enterprise: this.state.package.enterprise.enterpriseCalculation.total,
        packageData: JSON.stringify({
          packages: this.state.package,
        }),
        token: config.currentUser.token,
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Added!");
          // let resService = res.data.data;
          // console.log(resService);
          window.location.href = "#/servicepackage";
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      addEditLoading: true,
    });
    // const errors = this.validate();

    if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Bundle");
      return;
    }
    const {
      serviceId,
      serviceBundle,
      type,
      servicePrice,
      status,
      id,
    } = this.state;
    const Bundle = {
      serviceId: serviceId,
      serviceBundle: serviceBundle,
      type: type,
      servicePrice: servicePrice,
      status: status,
      token: config.currentUser.token,
    };
    axios
      .post(
        config[config.currentUser.user_type].updateServiceBundle + id,
        Bundle
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Updated!");
          let resBundleUpd = res.data.data;

          let found = this.state.data.filter(
            (arr) => arr.id === resBundleUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);

            let data = [...this.state.data];
            data[index] = resBundleUpd;
            this.setState({ data });
          }

          let allFound = this.state.allData.filter(
            (arr) => arr.id === resBundleUpd.id
          );
          if (allFound.length) {
            const index2 = this.state.allData.indexOf(allFound[0]);
            // this.state.allData[index2] = resBundleUpd;

            let allData = [...this.state.allData];
            allData[index2] = resBundleUpd;
            this.setState({ allData });
          }
          this.setState({
            sm: false,
            serviceId: "",
            serviceBundle: "",
            expertise: "",
            servicePrice: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        } else {
          this.danger("Please Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };

  danger = (s) => {
    Alert.warning(s, 4000);
  };
  alert = (s) => {
    Alert.success(s);
  };

  showServiceName = (id) => {
    let filtered = this.state.serviceList.filter((arr) => arr.id === id);
    if (filtered.length) {
      return filtered[0].serviceName;
    }
  };
  handleType = (exp) => {
    let z = config.type.filter((x) => Number(x.value) === Number(exp));
    if (z.length) {
      return z[0].label
    }
  };

  render() {
    const { essential, professional, enterprise } = this.state.package;
    let serviceIdErrorLen = this.state.errors.serviceId
      ? this.state.errors.serviceId.length
      : 0;
    let packageNameErrorLen = this.state.errors.packageName
      ? this.state.errors.packageName.length
      : 0;
    let timeFrameBundle = config.timeFrameBundle;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col className="">
              <div className="my-3 d-flex flex-row justify-content-between flex-wrap">
                <div className="h4">Create Package</div>
                <div className="d-flex">
                  <Button
                    className=" btn-sm ml-3"
                    onClick={() => (window.location.href = "#/servicepackage")}
                  >
                    {/* <div className="mr-2 mb-1" /> */}
                    Back
                  </Button>
                </div>
              </div>

              <div className="my-custom-scrollbar">
                <Form
                  onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}
                >
                  <Form.Row>
                    <Col lg={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Enter Package Name</Form.Label>
                        <Form.Control
                          name="packageName"
                          value={this.state.packageName}
                          onChange={this.handleChange}
                          placeholder="Enter Service Package Name"
                        />
                        <div
                          className={
                            packageNameErrorLen === 0
                              ? ""
                              : "alert alert-danger p-1"
                          }
                        >
                          {this.state.errors.packageName}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Choose Service</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.serviceId}
                          name="serviceId"
                          onChange={this.handleChange}
                        >
                          {this.state.serviceListOption.length ? (
                            <option value="">Select Service</option>
                          ) : (
                              <option value="">
                                Create/Active Service First
                              </option>
                            )}
                          {this.state.serviceListOption.length
                            ? this.state.serviceListOption.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                                {ser.serviceName}
                              </option>
                            ))
                            : ""}
                        </Form.Control>
                        <div
                          className={
                            serviceIdErrorLen === 0
                              ? ""
                              : "alert alert-danger p-1"
                          }
                        >
                          {this.state.errors.serviceId}
                        </div>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Bundle Name</th>
                        <th>Type</th>
                        <th>
                          <Form.Group as={Col} sm={12} lg={12}>
                            <Form.Label className="text-muted">
                              Essential
                            </Form.Label>
                            <Form.Control
                              size="sm"
                              as="select"
                              value={this.state.package.essential.timeFrame}
                              name="essential"
                              onChange={this.handleChange}
                            >
                              <option value="">Select</option>
                              {timeFrameBundle && timeFrameBundle.length ? timeFrameBundle.map((frame) => (
                                <option key={frame.id} value={frame.id}>
                                  {frame.label}
                                </option>
                              )) : ""}
                            </Form.Control>
                          </Form.Group>
                        </th>
                        <th>
                          <Form.Group as={Col} sm={12} lg={12}>
                            <Form.Label className="text-muted">
                              Professional
                            </Form.Label>
                            <Form.Control
                              size="sm"
                              as="select"
                              value={this.state.package.professional.timeFrame}
                              name="professional"
                              onChange={this.handleChange}
                            >
                              <option value="">Select</option>
                              {timeFrameBundle && timeFrameBundle.length ? timeFrameBundle.map((frame) => (
                                <option key={frame.id} value={frame.id}>
                                  {frame.label}
                                </option>
                              )) : ""}
                            </Form.Control>
                          </Form.Group>
                        </th>
                        <th>
                          <Form.Group as={Col} sm={12} lg={12}>
                            <Form.Label className="text-muted">
                              Enterprise
                            </Form.Label>
                            <Form.Control
                              size="sm"
                              as="select"
                              value={this.state.package.enterprise.timeFrame}
                              name="enterprise"
                              onChange={this.handleChange}
                            >
                              <option value="">Select</option>
                              {timeFrameBundle && timeFrameBundle.length ? timeFrameBundle.map((frame) => (
                                <option key={frame.id} value={frame.id}>
                                  {frame.label}
                                </option>
                              )) : ""}
                            </Form.Control>
                          </Form.Group>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filterBundleList.map((x, index) => (
                        <tr>
                          {/* <td>{index + 1}</td> */}
                          <td>{x.serviceBundle}</td>
                          <td>{this.handleType(x.type)}</td>
                          <td className="text-center">
                            <Form.Check
                              className="m-1"
                              name="essentialCol"
                              key={x.id}
                              type="checkbox"
                              checked={x.package.essentialCol}
                              disabled={
                                !this.state.package.essential.timeFrameBundle
                              }
                              onClick={(e) =>
                                this.handleSelectUnselectBundle(e, x)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Form.Check
                              className="m-1"
                              name="professionalCol"
                              key={x.id}
                              disabled={
                                !this.state.package.professional.timeFrameBundle
                              }
                              type="checkbox"
                              checked={x.package.professionalCol}
                              onClick={(e) =>
                                this.handleSelectUnselectBundle(e, x)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Form.Check
                              className="m-1"
                              key={x.id}
                              name="enterpriseCol"
                              type="checkbox"
                              disabled={
                                !this.state.package.enterprise.timeFrameBundle
                              }
                              checked={x.package.enterpriseCol}
                              onClick={(e) =>
                                this.handleSelectUnselectBundle(e, x)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Package Name</th>
                        <th>Essential Total</th>
                        <th>Professional Total</th>
                        <th>Enterprise Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.packageName}</td>
                        <td>
                          Total :{" "}
                          {this.state.package.essential.essentialCalculation
                            .total && essential.timeFrameBundle
                            ? this.state.package.essential.essentialCalculation
                              .total
                            : "Please Calculate First!"}
                        </td>
                        <td>
                          Total :{" "}
                          {this.state.package.professional
                            .professionalCalculation.total &&
                            professional.timeFrameBundle
                            ? this.state.package.professional
                              .professionalCalculation.total
                            : "Please Calculate First"}
                        </td>
                        <td>
                          Total :{" "}
                          {this.state.package.enterprise.enterpriseCalculation
                            .total && enterprise.timeFrameBundle
                            ? this.state.package.enterprise
                              .enterpriseCalculation.total
                            : "Please Calculate First"}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          Fixed :{" "}
                          {this.state.package.essential.essentialCalculation
                            .totalFixedAmount && essential.timeFrameBundle
                            ? this.state.package.essential.essentialCalculation
                              .totalFixedAmount
                            : "0"}
                        </td>
                        <td>
                          Fixed :{" "}
                          {this.state.package.professional
                            .professionalCalculation.totalFixedAmount &&
                            professional.timeFrameBundle
                            ? this.state.package.professional
                              .professionalCalculation.totalFixedAmount
                            : "0"}
                        </td>
                        <td>
                          Fixed :{" "}
                          {this.state.package.enterprise.enterpriseCalculation
                            .totalFixedAmount && enterprise.timeFrameBundle
                            ? this.state.package.enterprise
                              .enterpriseCalculation.totalFixedAmount
                            : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          Recursive :{" "}
                          {this.state.package.essential.essentialCalculation
                            .recursiveMontly && essential.timeFrameBundle
                            ? this.state.package.essential.essentialCalculation
                              .recursiveMontly +
                            " X " +
                            essential.timeFrameBundle.label +
                            " / Monthly"
                            : "0"}
                        </td>
                        <td>
                          Recursive :{" "}
                          {this.state.package.professional
                            .professionalCalculation.recursiveMontly &&
                            professional.timeFrameBundle
                            ? this.state.package.professional
                              .professionalCalculation.recursiveMontly +
                            " X " +
                            professional.timeFrameBundle.label +
                            " / Monthly"
                            : "0"}
                        </td>
                        <td>
                          Recursive :{" "}
                          {this.state.package.enterprise.enterpriseCalculation
                            .recursiveMontly && enterprise.timeFrameBundle
                            ? this.state.package.enterprise
                              .enterpriseCalculation.recursiveMontly +
                            " X " +
                            enterprise.timeFrameBundle.label +
                            " / Monthly"
                            : "0"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Button
                    disabled={
                      this.state.errorlen || this.state.checkAlreadyExit || !this.state.serviceId ||
                      !this.state.package.professional
                        .professionalCalculation.total || !this.state.package.essential.essentialCalculation
                          .total || !this.state.package.enterprise
                            .enterpriseCalculation.total
                    }
                    type="submit"
                  >
                    {this.state.id ? "Update" : "Save"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
// Tab.contextType = ServiceContext
export default Tab;
