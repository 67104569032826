import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import { FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Modal, Loader, Tooltip, Whisper, Toggle, Alert } from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import { Table, Button, Form, Col, Row, Container } from "react-bootstrap";
import config from "../../../env/config";
import ServiceContext from "../../context/serviceContext";
import { matchSorter } from "match-sorter";

class Tab extends Component {
  state = {
    addEditLoading: false,

    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },

    data: [],
    sm: false,
    username: "",
    email: "",
    smd: false,
    loader: true,

    edit: 0,
    errorlen: 1,
    errors: {
      serviceName: "",
      expertise: "",
      teamSize: "",
      servicePrice: "",
    },
  };

  schema = {
    serviceName: Joi.string().min(3).max(24).required().label("Service Name"),
    expertise: Joi.required().label("Expertise"),
    teamSize: Joi.number().min(1).max(100).required().label("Team Size"),
    hourlyPrice: Joi.number().min(1).required().label("Hourly Price"),
    monthlyPrice: Joi.number().min(1).required().label("Monthly Price"),
    pagePrice: Joi.number().min(1).required().label("Page Price"),
    devicePrice: Joi.number().min(1).required().label("Device Price"),
    discount: Joi.number().min(0).max(100).label("Discount"),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      serviceName: this.state.serviceName,
      expertise: this.state.expertise,
      teamSize: this.state.teamSize,
      hourlyPrice: this.state.hourlyPrice,
      monthlyPrice: this.state.monthlyPrice,
      pagePrice: this.state.pagePrice,
      devicePrice: this.state.devicePrice,
      discount: this.state.discount,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;
    if (input.name === "serviceName") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
      this.checkAlreadyExitSerivcePackages();
    } else {
      this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }
  };

  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
      [input.name]: input.value,
    });
    this.handleFilter(this.state.searchString);
  };

  handleFilter = (string) => {
    const dataForFilter = this.state.dataForFilter;
    let z = matchSorter(dataForFilter, string, { keys: ["serviceName"] });
    this.setState({
      data: z,
      page: 1,
    });
  };
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.data.length) {
      if (!this.state.id) {
        let existService = await this.state.data.filter((pack) => {
          return (
            this.state.id !== pack.id &&
            pack.serviceName.toLowerCase() ===
            this.state.serviceName.toLowerCase()
          );
        });
        if (existService.length) {
          this.danger("already Exist package");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      } else {
        let existService = await this.state.data.filter((pack) => {
          return (
            pack.serviceName.toLowerCase() ===
            this.state.serviceName.toLowerCase()
          );
        });
        if (existService.length) {
          this.danger("Already Exist Service");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      }
    }
  };

  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };

  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},
    });
  };

  setSmFalse = () => {
    this.setState({
      sm: false,
      serviceName: "",
      expertise: "",
      teamSize: "",
      hourlyPrice: "",
      monthlyPrice: "",
      pagePrice: "",
      devicePrice: "",
      status: "",
      id: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };

  setSmdFalse = () => {
    this.setState({
      smd: false,
      error: {},
      serviceName: "",
      expertise: "",
      teamSize: "",
      hourlyPrice: "",
      monthlyPrice: "",
      pagePrice: "",
      devicePrice: "",
      status: "",
      id: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };

  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getService, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          dataForFilter: array,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      return;
    } else if (this.state.checkAlreadyExit) {
      this.danger("already Exist package");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    // const existService = this.state.data.filter(service=>
    //   service.serviceName.toLowerCase() === this.state.serviceName.toLowerCase()
    //   )
    // if(existService.length){
    //   this.danger('Service Already Exist!')
    //   this.setState({
    //     serviceName: "",
    //     expertise: "",
    //     teamSize: "",
    //     hourlyPrice: "",
    //     monthlyPrice:"",
    //     pagePrice:"",
    //     devicePrice:"",
    //     status:"",
    //     id: "",
    //     sm: false,
    //     edit: 1,
    //     addEditLoading: false,
    //   });
    // }else{
    axios
      .post(config[config.currentUser.user_type].addService, {
        serviceName: this.state.serviceName,
        expertise: this.state.expertise,
        teamSize: this.state.teamSize,
        hourlyPrice: this.state.hourlyPrice,
        monthlyPrice: this.state.monthlyPrice,
        pagePrice: this.state.pagePrice,
        devicePrice: this.state.devicePrice,
        discount: this.state.discount,

        token: config.currentUser.token,
      })
      .then((res) => {
        if (res.data.status === 200) {
          // console.log(res.status,res.message)
          this.alert("Successfully Added!");
          // this.componentDidMount();
          let resService = res.data.data;
          config.globalData.services = [...this.state.data, resService];

          this.setState({
            data: [...this.state.data, resService],
            serviceName: "",
            expertise: "",
            teamSize: "",
            hourlyPrice: "",
            monthlyPrice: "",
            pagePrice: "",
            devicePrice: "",
            id: "",
            status: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });

    // }
  };

  handleEdit = (data) => {
    this.setState({
      edit: 1,
      serviceName: data.serviceName,
      expertise: data.expertise,
      teamSize: data.teamSize,
      hourlyPrice: data.hourlyPrice,
      monthlyPrice: data.monthlyPrice,
      pagePrice: data.pagePrice,
      devicePrice: data.devicePrice,
      status: data.status,
      discount: data.discount,

      id: data.id,
      sm: true,
    });
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      addEditLoading: true,
    });
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      return;
    } else if (this.state.checkAlreadyExit) {
      this.danger("already Exist package");
      return;
    }
    // const existService = this.state.data.filter(service=>
    //   service.serviceName.toLowerCase() === this.state.serviceName.toLowerCase()
    //   )
    // if(existService.length){
    //   this.danger('Service Already Exist!')
    //   this.setState({
    //     serviceName: "",
    //     expertise: "",
    //     teamSize: "",
    //     hourlyPrice: "",
    //     monthlyPrice:"",
    //     pagePrice:"",
    //     devicePrice:"",
    //     status:"",
    //     id: "",
    //     sm: false,
    //     edit: 1,
    //     addEditLoading: false,
    //   });
    // }else{
    const {
      serviceName,
      expertise,
      teamSize,
      hourlyPrice,
      monthlyPrice,
      pagePrice,
      devicePrice,
      id,
      status,
      discount,
    } = this.state;
    const user = {
      serviceName,
      expertise,
      teamSize,
      hourlyPrice,
      monthlyPrice,
      pagePrice,
      devicePrice,
      status,
      discount,
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].updateService + id, user)
      .then((res) => {
        if (res.data.status === 200) {
          // console.log(res)
          this.alert("Successfully Updated!");
          // this.componentDidMount();

          let resServiceUpd = res.data.data;
          this.state.data.map((thi) => thi);
          let found = this.state.data.filter(
            (arr) => arr.id === resServiceUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resServiceUpd;

            let data = [...this.state.data];
            data[index] = resServiceUpd;
            this.setState({ data });
          }
          this.setState({
            sm: false,
            serviceName: "",
            expertise: "",
            teamSize: "",
            hourlyPrice: "",
            monthlyPrice: "",
            pagePrice: "",
            devicePrice: "",
            id: "",
            status: "",
            edit: 0,
            addEditLoading: false,
          });
        } else {
          this.danger("Please Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
    // }
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const user = {
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].deleteService + id, user)
      .then((res) => {
        // console.log(res)
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);
          if (found.length) {
            let page = "";
            if (found.length % this.state.displayLength) {
              page = this.state.page;
            } else {
              page = Math.ceil(found.length / this.state.displayLength);
            }
            this.setState({
              data: found,
              page,
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            serviceName: "",
            expertise: "",
            teamSize: "",
            hourlyPrice: "",
            monthlyPrice: "",
            pagePrice: "",
            devicePrice: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data) => {
    await this.setState({
      edit: 1,
      serviceName: data.serviceName,
      discount: data.discount,
      expertise: data.expertise,
      teamSize: data.teamSize,
      hourlyPrice: data.hourlyPrice,
      monthlyPrice: data.monthlyPrice,
      pagePrice: data.pagePrice,
      devicePrice: data.devicePrice,
      status: Number(data.status) === 1 ? 2 : 1,
      id: data.id,
    });
    this.onUpdate();
  };
  danger = (s) => {
    Alert.warning(s, 4000);
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s);
    // Notification.success({
    //   title: s,
    //   // description:s
    // });
  };
  handleExpertise = (exp) => {
    if (config.expertise && config.expertise.length) {
      let z = config.expertise.filter((x) => Number(x.value) === Number(exp));
      if (z.length) {
        return z[0].label
      }
    }
  };

  render() {
    // console.log(config.expertise)
    // console.log(this.state)
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );
    // console.log(filterData)
    let discountErrorLen = this.state.errors.discount
      ? this.state.errors.discount.length
      : 0;
    let expertiseErrorLen = this.state.errors.expertise
      ? this.state.errors.expertise.length
      : 0;
    let hourlyPriceErrorLen = this.state.errors.hourlyPrice
      ? this.state.errors.hourlyPrice.length
      : 0;
    let monthlyPriceErrorLen = this.state.errors.monthlyPrice
      ? this.state.errors.monthlyPrice.length
      : 0;
    let pagePriceErrorLen = this.state.errors.pagePrice
      ? this.state.errors.pagePrice.length
      : 0;
    let devicePriceErrorLen = this.state.errors.devicePrice
      ? this.state.errors.devicePrice.length
      : 0;
    let serviceNameErrorLen = this.state.errors.serviceName
      ? this.state.errors.serviceName.length
      : 0;
    let teamSizeErrorLen = this.state.errors.teamSize
      ? this.state.errors.teamSize.length
      : 0;

    return (
      <ServiceContext.Provider value={{ data: this.state.data }}>
        <div>
          {/* <hr/> */}
          {/* <Button onClick={() => this.open('success')}> Success </Button> */}

          <Modal
            size="xs"
            backdrop={false}
            show={this.state.sm}
            onHide={this.setSmFalse}
          >
            <Modal.Header>
              <Modal.Title>
                {this.state.id ? "Update Service" : "Add Service"}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}>
                <Form.Group>
                  <Form.Label>Service Name</Form.Label>
                  <Form.Control
                    name="serviceName"
                    value={this.state.serviceName}
                    onChange={this.handleChange}
                    placeholder="Enter Service Name"
                  />
                  <div
                    className={
                      serviceNameErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.serviceName}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Expertise</Form.Label>
                  <Form.Control
                    as="select"
                    name="expertise"
                    value={this.state.expertise}
                    onChange={this.handleChange}
                  >
                    <option value="">Choose Expertise</option>
                    {config.expertise && config.expertise.length ? config.expertise.map((x) => (
                      <option key={x.label} value={x.value}>
                        {x.label}
                      </option>
                    )) : ''}
                  </Form.Control>

                  <div
                    className={
                      expertiseErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.expertise}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Team Size</Form.Label>
                  <Form.Control
                    type="number"
                    name="teamSize"
                    value={this.state.teamSize}
                    onChange={this.handleChange}
                    placeholder="Enter Team Size"
                  />
                  <div
                    className={
                      teamSizeErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.teamSize}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Discount in %</Form.Label>
                  <Form.Control
                    type="number"
                    name="discount"
                    min="0"
                    max="100"
                    value={this.state.discount}
                    onChange={this.handleChange}
                    placeholder="Enter Service Discount"
                  />
                  <div
                    className={
                      discountErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.discount}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Hourly Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="hourlyPrice"
                    value={this.state.hourlyPrice}
                    onChange={this.handleChange}
                    placeholder="Enter Hourly Price"
                  />
                  <div
                    className={
                      hourlyPriceErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.hourlyPrice}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Monthly Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="monthlyPrice"
                    value={this.state.monthlyPrice}
                    onChange={this.handleChange}
                    placeholder="Enter Monthly Price"
                  />
                  <div
                    className={
                      monthlyPriceErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.monthlyPrice}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Per Page Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="pagePrice"
                    value={this.state.pagePrice}
                    onChange={this.handleChange}
                    placeholder="Enter Service Price"
                  />
                  <div
                    className={
                      pagePriceErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.pagePrice}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Per Device Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="devicePrice"
                    value={this.state.devicePrice}
                    onChange={this.handleChange}
                    placeholder="Enter Device Price"
                  />
                  <div
                    className={
                      devicePriceErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.devicePrice}
                  </div>
                </Form.Group>

                <Button disabled={this.state.errorlen} type="submit">
                  {this.state.id ? "Update" : "Add"}
                </Button>
                {this.state.addEditLoading ? (
                  <Loader
                    size="md"
                    color="#4285f4"
                    center
                    backdrop
                    content="Processing Please Wait."
                  />
                ) : (
                    ""
                  )}
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            size="xs"
            backdrop={false}
            show={this.state.smd}
            onHide={this.setSmdFalse}
            style={{
              marginTop: "100px",
            }}
          >
            <Modal.Header>
              <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ textAlign: "center" }}>
              <Button
                onClick={() => this.handleDelete()}
                className="m-2 btn btn-danger"
              >
                Delete
              </Button>
              <Button onClick={() => this.setSmdFalse()} className="m-2">
                Cancel
              </Button>
              {this.state.addEditLoading ? (
                <Loader
                  size="md"
                  color="#4285f4"
                  center
                  backdrop
                  content="Deleting Please Wait."
                />
              ) : (
                  ""
                )}
            </Modal.Body>
          </Modal>
          <Container fluid className="">
            <Row>
              <Col className="">
                <div className="">
                  <div className="my-3 d-flex flex-row justify-content-between h4">
                    Manage Services
                    <div className="d-flex">
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder="Search Here."
                          name="searchString"
                          value={this.state.searchString}
                          onChange={this.handleSelect}
                        />
                      </Form.Group>
                      <Button
                        className=" btn-sm ml-3"
                        onClick={() => this.setSmTrue()}
                      >
                        <FaPlus className="mr-2 mb-1" />
                        Add New
                      </Button>
                    </div>
                  </div>
                  <div className="my-custom-scrollbar">
                    <Table
                      striped
                      bordered
                      hover
                      responsive="lg"
                      className="mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Service Name</th>
                          <th>Expertise</th>
                          <th>Team Size</th>
                          <th>Discount</th>
                          <th>Hourly Price</th>
                          <th>Monthly Price</th>
                          <th>Per Page Price</th>
                          <th>Per Device Price</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      {this.state.loader ? (
                        <Loader
                          size="lg"
                          center
                          backdrop
                          content="Loading Please Wait.."
                        />
                      ) : (
                          <tbody>
                            {filterData.map((data, index) => (
                              <tr key={data.id}>
                                <td>
                                  {this.state.displayLength *
                                    (this.state.page - 1) +
                                    index +
                                    1}
                                </td>
                                <td>{data.serviceName}</td>
                                <td>{this.handleExpertise(data.expertise)}</td>
                                <td>{data.teamSize}</td>
                                <td>{data.discount}</td>
                                <td>{data.hourlyPrice}</td>
                                <td>{data.monthlyPrice}</td>
                                <td>{data.pagePrice}</td>
                                <td>{data.devicePrice}</td>
                                <td>
                                  <Toggle
                                    name="status"
                                    size="md"
                                    checkedChildren="Active"
                                    unCheckedChildren="Inactive"
                                    defaultChecked={
                                      Number(data.status) === 1 ? true : false
                                    }
                                    onChange={() =>
                                      this.handleActiveInactive(data)
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <Whisper
                                      trigger="hover"
                                      placement="bottom"
                                      speaker={<Tooltip>Edit</Tooltip>}
                                    >
                                      <button
                                        onClick={() => {
                                          this.handleEdit(data);
                                        }}
                                        className="btn btn-primary btn-sm"
                                        style={{ margin: "1px" }}
                                      >
                                        <FaRegEdit />
                                      </button>
                                    </Whisper>

                                    <Whisper
                                      trigger="hover"
                                      placement="bottom"
                                      speaker={<Tooltip>Delete</Tooltip>}
                                    >
                                      {/* <Button appearance="subtle">click</Button> */}
                                      <button
                                        onClick={() => this.handleRemove(data)}
                                        className="btn btn-danger btn-sm"
                                        style={{ margin: "1px" }}
                                      >
                                        <FaRegTrashAlt />
                                      </button>
                                    </Whisper>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                    </Table>
                  </div>
                  {filterData.length === 0 ? (
                    <div
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                        minHeight: "300px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.state.loader ? "" : "No Items, Please Add One"}
                    </div>
                  ) : (
                      ""
                    )}
                  {filterData.length ? (
                    <Pagination
                      itemsCount={this.state.data.length}
                      pageSize={this.state.displayLength}
                      currentPage={this.state.page}
                      onPageChange={this.handleChangePage}
                    />
                  ) : (
                      ""
                    )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ServiceContext.Provider>
    );
  }
}

export default Tab;
