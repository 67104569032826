import React, { Component } from "react";
import { Card, Row, Col, Form, Button, Table } from "react-bootstrap";
import { FaFileDownload, FaPercentage, FaDollarSign } from "react-icons/fa";

class CardComponent extends Component {
  state = {
    currencyButton: true,
    discountButton: true,
  };

  handlecurrencyButton = () => {
    this.setState({
      currencyButton: !this.state.currencyButton,
    });
  };
  handleDiscountButton = () => {
    this.setState({
      discountButton: !this.state.discountButton,
    });
  };
  handleSelect = async ({ currentTarget: input }) => {
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else errors[input.name] = "";

    // const errorlen = errorMessage;

    await this.setState({
      [input.name]: input.value,
      //   errors,
      //   errorlen,
    });

    // this.calculate();
  };
  render() {
    const allValue = this.props.allValue;
    let discountBool =
      Number(this.props.discount) > Number(this.props.maxdiscount);

    let calculationName = this.props.calculationName;
    let hourlyTimeFrameCondition =
      this.props.weeklyhours * this.props.timeframe;
    // console.log("allValue========", allValue);
    // console.log("this.props=======", this.props.selectedBundles);

    return (
      <div className="d-flex flex-grow-1">
        <Card border="success" className="text-center d-flex flex-grow-1">
          <Card.Header>Estimation</Card.Header>
          <Card.Body style={{ backgroundColor: "#99f3bd" }}>
            <div className={allValue ? "" : "d-none"}>
              {calculationName === "ServiceModule" ? (
                <Card.Text>
                  {this.props.selectedBundles && this.props.selectedBundles.length ?
                    this.props.selectedBundles.map((bundles, index) => (
                      bundles.user && this.props.totalModuleDays ?
                        <div>
                          <b class="card-title pull-left">User type: {bundles.user}</b>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Service Modules</th>
                                <th>Unit</th>
                                <th>Total Time Period in Hours</th>
                                {/* <th>Price in {this.props.symbol}</th> */}
                              </tr>
                            </thead>
                            {bundles.bundle.map((x, index) =>
                              x.checked ?
                                <tbody>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{x.moduleName}</td>
                                    <td>{x.unit}</td>
                                    <td>{x.timeRequired * x.unit}</td>
                                    {/*  <td>
                                      {(
                                        Math.round(
                                          x.unit * x.timeRequired * this.props.selectedData.services.hourlyPrice *
                                          this.props.selectedData.currencies
                                            .currencyFactor *
                                          this.props.selectedData.regions
                                            .regionFactor *
                                          100
                                        ) / 100
                                      ).toFixed(2)}
                                    </td> */}
                                  </tr>
                                </tbody>
                                : ''
                            )}
                          </Table>
                        </div>
                        : ''
                    ))
                    : ''}
                  <p>  <b>Estimation Hours :</b>{" "}
                    {this.props.totalModuleDays}{" "}</p>
                  <p>  <b>Estimation Approx Days :</b>{" "}
                    {(Math.round(this.props.totalModuleDays / this.props.approxDayCal.divideValDays))}{" "}</p>
                  <p>   <b> Estimation Price :</b>{" "}
                    {this.props.selectedData.currencies.currencySymbol}{" "}
                    {(Math.round(this.props.finalprice * 100) / 100).toFixed(2)}{" "}</p>
                </Card.Text>
              ) : (
                  ""
                )}

              {calculationName === "bundle" ? (
                <Card.Text>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Bundle Name</th>
                        <th>Type</th>
                        <th>Time Period</th>
                        <th>Price in {this.props.symbol}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.selectedBundles.map((x, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{x.serviceBundle}</td>
                          <td>{this.props.handleType(x.type)}</td>
                          <td>{Number(x.type) === 2 ? this.props.selectedTimeFrameBundle.label : "-"}</td>
                          <td>
                            {Number(x.type) === 2
                              ? (
                                Math.round(
                                  x.servicePrice *
                                  // this.props.timeframe *
                                  this.props.selectedTimeFrameBundle
                                    .factor *
                                  this.props.selectedData.currencies
                                    .currencyFactor *
                                  this.props.selectedData.regions
                                    .regionFactor * this.props.selectedTimeFrameBundle.label *
                                  100
                                ) / 100
                              ).toFixed(2)
                              : (
                                Math.round(
                                  x.servicePrice *
                                  this.props.selectedData.currencies
                                    .currencyFactor *
                                  this.props.selectedData.regions
                                    .regionFactor *
                                  100
                                ) / 100
                              ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Text>
              ) : (
                  ""
                )}
              {calculationName === "hourly" ? (
                <Card.Text className="h5">
                  Estimation Price : {this.props.symbol}{" "}
                  {(Math.round(this.props.finalprice * 100) / 100).toFixed(2)} /
                  hr
                </Card.Text>
              ) : (
                  ""
                )}
              {calculationName === "resources" ? (
                <Card.Text className="h5">
                  Estimation Price : {this.props.symbol}{" "}
                  {(Math.round(this.props.finalprice * 100) / 100).toFixed(2)} /
                  Resource / Month
                </Card.Text>
              ) : (
                  ""
                )}

              {/* discount started */}
              {calculationName === "package" ? (
                ""
              ) : (
                  <Card.Text
                    className={this.props.discount === "" ? "d-none" : ""}
                  >
                    with {this.props.discount} % Discount
                  </Card.Text>
                )}
              {/* discount completed */}

              {/* bundle started */}
              {calculationName === "bundle" ? (
                <Card.Text className="h5">
                  Estimation Price :{" "}
                  {this.props.selectedData.currencies.currencySymbol}{" "}
                  {(Math.round(this.props.finalprice * 100) / 100).toFixed(2)}{" "}
                </Card.Text>
              ) : (
                  ""
                )}
              {/* bundle completed */}

              {/* hourly started */}
              {calculationName === "hourly" ? (
                <Card.Text>
                  Total Hours : {hourlyTimeFrameCondition}
                  {/* <FaClock /> */} <br />
                  Total Cost : {this.props.symbol}
                  {(
                    Math.round(
                      this.props.finalprice *
                      this.props.weeklyhours *
                      this.props.timeframe *
                      100
                    ) / 100
                  ).toFixed(2)}{" "}
                </Card.Text>
              ) : (
                  ""
                )}
              {/* hourly completed */}

              {/* resources started */}
              {calculationName === "resources" ? (
                <Card.Text>
                  Total Months: {this.props.timeframe}
                  <br />
                  Total No. of Resources: {this.props.resources}
                  <br />
                  Total Monthly Cost: {this.props.symbol}{" "} {this.props.totalMonthlyCost}
                  <br />
                  Total Project Cost: {this.props.symbol}{" "} {this.props.totalProjectCost}
                  <br />
                </Card.Text>
              ) : (
                  ""
                )}
              {/* resources completed */}

              {/* package started */}
              {calculationName === "package" ? (
                <Card.Text>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Package Name</th>
                        <th>Essential</th>
                        <th>Professional</th>
                        <th>Enterprise</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.selectedPackages.map((x, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{x.servicePackage}</td>
                          <td>{x.essential}</td>
                          <td>{x.professional}</td>
                          <td>{x.enterprise}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Text>
              ) : (
                  ""
                )}
              {/* package completed */}
            </div>

            <div className={allValue ? "d-none" : ""}>
              <Card.Text className="h5">
                Please Provide Complete & Proper Information
              </Card.Text>
            </div>

            <div className={allValue ? "d-none" : ""}>
              {calculationName !== 'ServiceModule' ? <Button
                disabled={!allValue}
                onClick={this.props.smdShow}
                className="btn-sm m-2"
                variant="primary"
              >
                {" "}
                <FaFileDownload className="mr-2" />
                Proceed
              </Button> : ''}

            </div>

            <Row className={allValue ? "discount mt-3" : "d-none"}>
              <Col className="col-auto px-0">
                <Button
                  // disabled={!allValue}
                  onClick={this.handleDiscountButton}
                  className={this.state.discountButton ? "btn-sm" : "d-none"}
                  variant="warning"
                >
                  {" "}
                  <FaPercentage className="mr-2" />
                  Discount
                </Button>

                <Form.Group
                  className={
                    !this.state.discountButton
                      ? "mb-0 discount-width"
                      : "d-none"
                  }
                >
                  <Form.Control
                    type="number"
                    value={this.state.discount}
                    name="discount"
                    onChange={this.props.handleSelect}
                    placeholder="Enter in %"
                    size="sm"
                    step="1"
                    min="0"
                    max={this.props.maxDiscount}
                  />
                  <Form.Text className="text-mute " style={{ fontSize: "70%" }}>
                    Max Discount : {this.props.maxdiscount}%
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col className="col-auto px-0">
                {calculationName !== 'ServiceModule' ?
                  <Button
                    disabled={discountBool}
                    onClick={this.props.smdShow}
                    className="btn-sm"
                    variant="primary"
                  >
                    {" "}
                    <FaFileDownload className="mr-2" />
                  Proceed
                </Button>
                  : ''}
              </Col>
              <Col className="col-auto px-0">
                <Button
                  // name='currencyButton'
                  onClick={this.handlecurrencyButton}
                  className={this.state.currencyButton ? "btn-sm" : "d-none"}
                  variant="danger"
                >
                  {" "}
                  <FaDollarSign className="mr-2" />
                  Currency
                </Button>

                <Form.Group
                  className={
                    !this.state.currencyButton
                      ? "mb-0 discount-width"
                      : "d-none"
                  }
                >
                  <Form.Control
                    size="sm"
                    as="select"
                    value={this.state.currencies}
                    name="currencies"
                    onChange={this.props.handleSelect}
                  >
                    {this.props.filtercurrency.map((curr) => (
                      <option key={curr.id} value={curr.id}>
                        {curr.currencySymbol}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-muted"> AM Webtech Pvt.Ltd.</Card.Footer>
        </Card>
      </div>
    );
  }
}

export default CardComponent;
