import React, { Component } from "react";
import "./App.css";

import Home from "./components/layout/Home";
import Login from "../src/components/pages/login/login";

import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/styles/rsuite-default.css";

import DataContext from "./components/context/dataContext";

import config from "./env/config";


class App extends Component {
  state = {
    logo: config.AMW_Logo,
    isLoggedIn: false,
    userType: 1, //means no user
    data: {},
  };

  componentDidMount = () => {
    let token = localStorage.getItem("currentUser");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    config.currentUser = currentUser;
    this.setState({
      isLoggedIn: token ? true : false,
      data: currentUser,
    });
  };
  handleLoginChange = (e) => {
    this.setState({
      isLoggedIn: e,
    });
  };
  handleDataChange = (e) => {
    this.setState({
      data: e,
    });
  };

  render() {
    return (
      <DataContext.Provider
        value={{
          data: this.state,
          handleLoginChange: this.handleLoginChange,
          handleDataChange: this.handleDataChange,
        }}
      >
        {/* <Table  /> */}
        {this.state.isLoggedIn ? <Home /> : <Login />}
      </DataContext.Provider>
    );
  }
}

export default App;
