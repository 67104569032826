import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import {FaPlus,FaRegEdit,FaRegTrashAlt} from 'react-icons/fa'
import { Modal, Loader ,Tooltip, Whisper,Toggle,Alert} from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import { Table, Button, Form, Col, Row ,Container} from "react-bootstrap";
import config from "../../../env/config";
import {matchSorter} from 'match-sorter'


class Tab extends Component {
  state = {
    addEditLoading: false,

    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    sm: false,
    currencyName: "",
    currencyFactor: "",
    currencySymbol: "",
    smd: false,
    loader: true,

    edit: 0,
    errorlen: 1,
    errors: {
      currencyName: "",
      currencyFactor: "",
      currencySymbol: "",
    },
  };

  schema = {
    currencyName: Joi.string().min(2).max(24).required().label('Currency Name'),
    currencyFactor: Joi.number().min(0.1).max(1000).required().label('Currency Factor'),
    currencySymbol: Joi.string().required().label('Currency Symbol'),
  };

  validate = () => {
    const options = { abortEarly: false };
    const data = {
      currencyName: this.state.currencyName,
      currencyFactor: this.state.currencyFactor,
      currencySymbol: this.state.currencySymbol,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : "";
    //chnages here 1 of else part in strinng to null
  };

  handleChange =async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;
    if (input.name === "currencyName") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
      this.checkAlreadyExitSerivcePackages();
    } else {
      this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }
  };
  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
        [input.name]: input.value,
      });
    this.handleFilter(this.state.searchString)
  };

  handleFilter = (string) =>{
      const dataForFilter = this.state.dataForFilter
      let z = matchSorter(dataForFilter, string, {keys: ['currencyName','currencySymbol']})
      this.setState({
          data:z,page:1
      })
  }
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.data.length) {
      if (!this.state.id) {
        let existCurrency = await this.state.data.filter(
          (pack) => {
            return this.state.id !== pack.id &&
            pack.currencyName.toLowerCase() ===
              this.state.currencyName.toLowerCase()
          } );
          if (existCurrency.length)   {
            this.danger("Already Currency Exist");
            this.setState({
              checkAlreadyExit: true,
            });         
          } else {
            this.setState({
              checkAlreadyExit: false,
            });  
          }
      } else {
        let existCurrency = await this.state.data.filter(
          (pack) => {
           return pack.currencyName.toLowerCase() ===
            this.state.currencyName.toLowerCase()
          });
          if (existCurrency.length)   {
            this.danger("already Exist package");
            this.setState({
              checkAlreadyExit: true,
            });         
          } else {
              this.setState({
                checkAlreadyExit: false,
              });  
          }
      }      
    }
  };

  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };

  setSmTrue = () => {
    this.setState({
      sm: true,
      currency: {},
    });
  };

  setSmFalse = () => {
    this.setState({
      sm: false,
      addEditLoading: false,
      currencyName: "",
      currencyFactor: "",
      currencySymbol: "",
      checkAlreadyExit: false,
      id: "",
    });
  };

  setSmdFalse = () => {
    this.setState({
      smd: false,
      error: {},
      currencyName: "",
      currencyFactor: "",
      currencySymbol: "",
      addEditLoading: false,
      checkAlreadyExit: false,
      id: "",
    });
  };

  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getCurrency, {
        token: config.currentUser.token,
      })
      .then((res) => {
        // console.log("res.data.data===========", res)
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          dataForFilter:array,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };

  onAddSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const errors = this.validate();
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }else if (this.state.checkAlreadyExit) {
      this.danger("already Exist package");
      return;
    }
    this.setState({
      addEditLoading: true,
    });

    // const existCurrency = this.state.data.filter(service=>
    //   service.currencyName.toLowerCase() === this.state.currencyName.toLowerCase()
    //   )
    // if(existCurrency.length){
    //   this.danger('Currency Already Exist!')
    //   this.setState({
    //     currencyName: "",
    //     currencyFactor: "",
    //     currencySymbol:"",
    //     id: "",
    //     sm: false,
    //     edit: 1,
    //     addEditLoading: false,
    //   });
    // }else{
      axios
      .post(config[config.currentUser.user_type].addCurrency, {
        currencyName: this.state.currencyName,
        currencyFactor: this.state.currencyFactor,
        currencySymbol: this.state.currencySymbol,
        id: this.state.currency.id,
        token: config.currentUser.token,
      })
      .then((res) => {
        // console.log(res)
        if (res.data.status === 200) {
          // console.log(res.status,res.message)
          this.alert("Successfully Added!");
          // this.componentDidMount();
          let resCurrency = res.data.data;
          config.globalData.currencies = [...this.state.data, resCurrency];

          this.setState({
            data: [...this.state.data, resCurrency],
            currencyName: "",
            currencyFactor: "",
            currencySymbol: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );

      });
  
    // }
  
  };

  handleEdit = (data) => {
    // console.log(data,'data herer=======')

    this.setState({
      edit: 1,
      currencyFactor: data.currencyFactor,
      currencySymbol: data.currencySymbol,
      currencyName: data.currencyName,
      status: data.status,
      id: data.id,
      sm: true,
    });
  };

  onUpdate = (event) => {
    if(event){
      event.preventDefault();
    }
    const errors =this.validate()
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }else if (this.state.checkAlreadyExit) {
      this.danger("Already Currency Exist");
      return;
    }
    this.setState({
      addEditLoading: true,
    });

    // const existCurrency = this.state.data.filter(service=>
    //   service.currencyName.toLowerCase() === this.state.currencyName.toLowerCase()
    //   )
    // if(existCurrency.length>1){
    //   this.danger('Currency Already Exist!')
    //   this.setState({
    //     currencyName: "",
    //     currencyFactor: "",
    //     currencySymbol:"",
    //     id: "",
    //     sm: false,
    //     edit: 1,
    //     addEditLoading: false,
    //   });
    // }else{
    const {
      currencySymbol,
      currencyName,
      currencyFactor,
      status,
      id,
    } = this.state;

    const currency = {
      currencyFactor,
      currencySymbol,
      currencyName,
      status,
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].updateCurrency + id, currency)
      .then((res) => {
        if (res.data.status === 200) {
          // console.log(res);
          this.alert("Successfully Updated!");
          // this.componentDidMount();
          let resCurrencyUpd = res.data.data;
          let found = this.state.data.filter(
            (arr) => arr.id === resCurrencyUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resCurrencyUpd;
            
            let data = [...this.state.data]
            data[index] = resCurrencyUpd;
            this.setState({data});
            
          }

          this.setState({
            sm: false,
            currencyName: "",
            currencyFactor: "",
            currencySymbol: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
    // }
  };
 
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const currency = {
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].deleteCurrency + id, currency)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          
          const found = this.state.data.filter((obj) => obj.id !== id);
          if (found.length) {
            let page = ''
            if(found.length % this.state.displayLength){
              page =this.state.page
            }else{
              page = Math.ceil(found.length  / this.state.displayLength)
            }
            this.setState({
              data: found,
              page
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            currencyName: "",
            currencyFactor: "",
            currencySymbol: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data)=>{
    await this.setState({
       edit: 1,
       currencyName: data.currencyName,
       currencyFactor: data.currencyFactor,
       currencySymbol:data.currencySymbol,
       status: Number(data.status) === 1 ? 2 : 1 ,
       id: data.id,
     });
     this.onUpdate();
 
   
     };
  danger = (s) => {
    Alert.warning(s,4000)
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s)
    // Notification.success({
    //   title: s,
    //   // description:s
    // });
  };

  render() {
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );
    // console.log(filterData)
    let currencyNameErrorLen = this.state.errors.currencyName
      ? this.state.errors.currencyName.length
      : 0;
    let currencyFactorErrorLen = this.state.errors.currencyFactor
      ? this.state.errors.currencyFactor.length
      : 0;
    let currencySymbolErrorLen = this.state.errors.currencySymbol
      ? this.state.errors.currencySymbol.length
      : 0;

    return (
      <div>
     
        <Modal size='xs' backdrop={false} show={this.state.sm} onHide={this.setSmFalse}>
          <Modal.Header>
            <Modal.Title>{this.state.id ? "Update Currency" : "Add Currency"}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}>
              <Form.Group>
                <Form.Label>Currency Name</Form.Label>
                <Form.Control
                  name="currencyName"
                  value={this.state.currencyName}
                  onChange={this.handleChange}
                  placeholder="Enter Currency"
                />
                <div
                  className={
                    currencyNameErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.currencyName}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Currency Factor</Form.Label>
                <Form.Control
                  type="number"
                  // step="0.1"
                  // min="0.1"
                  max="1000"
                  name="currencyFactor"
                  value={this.state.currencyFactor}
                  onChange={this.handleChange}
                  placeholder="Enter Currency Factor"
                />
                <div
                  className={
                    currencyFactorErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.currencyFactor}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Currency Symbol</Form.Label>
                <Form.Control
                  name="currencySymbol"
                  value={this.state.currencySymbol}
                  onChange={this.handleChange}
                  placeholder="Enter Currency Symbol"
                />
                <div
                  className={
                    currencySymbolErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.currencySymbol}
                </div>
              </Form.Group>

              <Button disabled={this.state.errorlen} type="submit">
                {this.state.id ? "Update" : "Add"}
              </Button>
              {this.state.addEditLoading ? (
                <Loader
                  size="md"
                  color="#4285f4"
                  center
                  backdrop
                  content="Processing Please Wait."
                />
              ) : (
                ""
              )}
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop:'100px'
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="md"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>
    <Container fluid className="">
      <Row>
      <Col className="">
      <div className=''>
      <div className="my-3 d-flex flex-row justify-content-between h4">Manage Currency
          
      <div className="d-flex">
        <Form.Group className="mb-0" >
          <Form.Control placeholder='Search Here.' 
            name='searchString' 
            value ={this.state.searchString} 
            onChange={this.handleSelect}
          />
        </Form.Group>
        <Button className=" btn-sm ml-3" onClick={() => this.setSmTrue()}>
        <FaPlus className='mr-2 mb-1'/>
          Add New
        </Button>
        </div> 
          </div>
          
          <div className="my-custom-scrollbar"> 
          <Table striped bordered hover responsive="lg" className="mb-0">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Currency Name</th>
              <th>Currency Factor</th>
              <th>Currency Symbol</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>

          {this.state.loader ? (
            <Loader size="lg" center backdrop content="Loading Please Wait.." />
          ) : (
            <tbody>
              {filterData.map((data,index) => (
                <tr key={data.id}>
                  <td>{this.state.displayLength *(this.state.page -1)+ index+1}</td>
                  <td>{data.currencyName}</td>
                  <td>{data.currencyFactor}</td>
                  <td>{data.currencySymbol}</td>
                  <td>
                  <Toggle 
                  disabled={Number(data.id) === config.defaultCurrencyId?true:false}
                  name='status'
                  size="md" 
                  checkedChildren="Active" 
                  unCheckedChildren="Inactive"
                  defaultChecked={Number(data.status) === 1 ? true : false}
                   onChange={()=>this.handleActiveInactive(data)}/>
                  </td>
                  <td>
                  <div className="d-flex">
                    <Whisper
                      trigger="hover"
                      placement='bottom'
                      speaker={
                        <Tooltip>
                          Edit
                        </Tooltip>
                      }>
                      <button
                      disabled={data.id === 43 ? true:false}
                      onClick={() => {
                        this.handleEdit(data);
                      }}
                      className="btn btn-primary btn-sm"
                      style={{margin:'1px'}}
                    >
                      <FaRegEdit/>
                    </button>
                  </Whisper>
                    
                    
                    <Whisper
                      trigger="hover"
                      placement='bottom'
                      speaker={
                        <Tooltip>
                          Delete
                        </Tooltip>
                      }
                    >
                      {/* <Button appearance="subtle">click</Button> */}
                      <button
                      disabled={data.id ===43?true:false}
                      onClick={() => this.handleRemove(data)}
                      className="btn btn-danger btn-sm"
                      style={{margin:'1px'}}
                    >
                      <FaRegTrashAlt/>
                    </button>
                    </Whisper>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
          </div>

        {filterData.length === 0 ? (
          <div
            style={{
              fontSize: "24px",
              textAlign: "center",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.loader?'':'No Items, Please Add One'}
          </div>
        ) : (
          ""
        )}
        {filterData.length === 0 ? (
          ""
        ) : (
          <Pagination
            itemsCount={this.state.data.length}
            pageSize={this.state.displayLength}
            currentPage={this.state.page}
            onPageChange={this.handleChangePage}
          />
        )}
         </div>
        </Col>
          </Row>
          </Container>
      </div>
      
    );
  }
}

export default Tab;
