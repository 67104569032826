import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Modal, Loader, Tooltip, Whisper, Alert } from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import { Table, Button, Form, Col, Row, Container } from "react-bootstrap";
import config from "../../../env/config";
import { matchSorter } from "match-sorter";

class SavedCal extends Component {
  state = {
    addEditLoading: false,

    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    sm: false,
    username: "",
    email: "",
    smd: false,
    loader: true,

    edit: 0,
    errorlen: 1,
    errors: {
      username: "",
      email: "",
    },
  };

  schema = {
    username: Joi.string().min(2).max(24).required().label("User Name"),
    email: Joi.string().email().required().label("Email"),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      username: this.state.username,
      email: this.state.email,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;
    this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
  };
  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
      [input.name]: input.value,
    });
    this.handleFilter(this.state.searchString);
  };

  handleFilter = (string) => {
    const savedCal = this.state.savedCal;
    let z = matchSorter(savedCal, string, { keys: ["title", "tags"] });
    this.setState({
      data: z,
      page: 1,
    });
  };
  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };
  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},
    });
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
      error: {},
      username: "",
      email: "",
      id: "",
    });
  };
  setSmdFalse = () => {
    this.setState({
      smd: false,
      error: {},
      username: "",
      id: "",
      email: "",
    });
  };
  componentDidMount = () => {
    let api = "";
    if (Number(config.currentUser.user_type) === 1) {
      api = config[config.currentUser.user_type].getTemplate;
    } else {
      api =
        config[config.currentUser.user_type].getTemplate +
        config.currentUser.id;
    }
    axios
      .post(api, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res.data.status === 200) {
          array = res.data.data;
        }

        let reverseArray = array.reverse();
        // reverseArray[0].tags=['zero','ZERO']
        // reverseArray[1].tags=['one','ONE']
        // reverseArray[2].tags=['two','TWO']
        // reverseArray[3].tags=['three','THREE']
        // reverseArray[4].tags=['four','FOUR']
        // reverseArray[5].tags=['five','FIVE']
        // reverseArray[6].tags=['six','SIX']
        // reverseArray[7].tags=['seven','SEVEN']
        // reverseArray[8].tags=['eight','EIGHT']
        // let savedCalNames = reverseArray.map(x=>x.title);
        this.setState({
          data: reverseArray,
          savedCal: reverseArray,
          loader: false,
          // savedCalNames
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        // this.handleLogout()
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later after login in again!"
        );
      });
  };
  handleEdit = (data) => {
    this.setState({
      edit: 1,
      title: data.title,
      estimationData: data.estimationData,
      type: data.type,
      status: data.status,
      id: data.id,
    });
    //condition for checking whether it is hourly or

    if (data.type === "Hourly") {
      window.location.href = `#/hourly/:${data.id}`;
    } else if (data.type === "Resources") {
      window.location.href = `#/resources/:${data.id}`;
    } else if (data.type === "Bundle") {
      window.location.href = `#/bundle/:${data.id}`;
    }
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      addEditLoading: true,
    });
    const { title, estimationData, type, status, id } = this.state;
    const user = {
      title, //now manager is unable to change the user email
      estimationData,
      type,
      status,
      token: config.currentUser.token,
    };

    let api =
      Number(config.currentUser.user_type) === 1
        ? config[config.currentUser.user_type].updateTemplate + id
        : config[config.currentUser.user_type].updateTemplate +
          config.currentUser.id +
          "/" +
          id;
    axios
      .post(api, user)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Updated!");
          let resUserUpd = res.data.data;

          let found = this.state.data.filter((arr) => {
            return arr.id === resUserUpd.id;
          });
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resUserUpd;
            this.setState({ ...this.state.data, [index]: resUserUpd });
          }

          this.setState({
            sm: false,
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        } else {
          this.danger("Please Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
          edit: 0,
          email: "",
          username: "",
          user_type: "",
          status: "",
          id: "",
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const user = {
      token: config.currentUser.token,
    };
    let api =
      Number(config.currentUser.user_type) === 1
        ? config[config.currentUser.user_type].deleteTemplate + id
        : config[config.currentUser.user_type].deleteTemplate +
          config.currentUser.id +
          "/" +
          id;
    axios
      .post(api, user)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);
          if (found.length) {
            let page = "";
            if (found.length % this.state.displayLength) {
              page = this.state.page;
            } else {
              page = Math.ceil(found.length / this.state.displayLength);
            }
            this.setState({
              data: found,
              page,
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            username: "",
            email: "",
            user_type: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
          // this.open('Success')
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data) => {
    await this.setState({
      edit: 1,
      title: data.title,
      estimationData: data.estimationData,
      type: data.type,
      status: Number(data.status) === 1 ? 2 : 1,
      id: data.id,
    });
    this.onUpdate();
  };
  danger = (s) => {
    Alert.error(s, 4000);
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s);
    // Notification.success({
    //   title: s,
    // });
  };
  render() {
    console.log(this.state.data[0]);
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );

    return (
      <div>
        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop: "100px",
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="md"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>

        <Container fluid className="">
          <Row>
            <Col className="">
              <div className="">
                <div className="my-3 d-flex flex-row justify-content-between h4">
                  Manage Saved Calculations
                  <div className="d-flex">
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder="Search Here."
                        name="searchString"
                        value={this.state.searchString}
                        onChange={this.handleSelect}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="my-custom-scrollbar">
                  <Table
                    striped
                    bordered
                    hover
                    responsive="lg"
                    className="mb-0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Project Name</th>
                        {/* <th>Prospect Name</th> */}
                        <th>Type</th>
                        {/* <th>Service</th> */}
                        {/* <th>Created At</th> */}
                        {/* <th>Updated On</th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {this.state.loader ? (
                      <Loader
                        size="lg"
                        color="white"
                        center
                        backdrop
                        content={
                          "Loading Please Wait"
                          // <img style={{width:'150px'}} src={amwLoader}/>
                        }
                      />
                    ) : (
                      <tbody>
                        {filterData.map((data, index) => (
                          <tr key={data.id}>
                            <td>
                              {this.state.displayLength *
                                (this.state.page - 1) +
                                index +
                                1}
                            </td>
                            <td>{data.title}</td>
                            {/* <td>{console.log(data)}</td> */}
                            <td>{data.type}</td>
                            {/* <td>{data.createdAt}</td> */}
                            {/* <td>{data.lastModified}</td> */}
                            <td>
                              <Whisper
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>Edit</Tooltip>}
                              >
                                <button
                                  disabled={
                                    (Number(data.status) === 1
                                      ? false
                                      : true) || data.type === "Bundle"
                                  }
                                  onClick={() => {
                                    this.handleEdit(data);
                                  }}
                                  // disabled={data.type === 'Bundle'}//to make bundle edit disable
                                  className="btn btn-primary btn-sm "
                                  style={{ margin: "1px" }}
                                >
                                  <FaRegEdit />
                                </button>
                              </Whisper>

                              <Whisper
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>Delete</Tooltip>}
                              >
                                {/* <Button appearance="subtle">click</Button> */}
                                <button
                                  onClick={() => this.handleRemove(data)}
                                  className="btn btn-danger  btn-sm"
                                  style={{ margin: "1px" }}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </Whisper>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </div>
                {filterData.length === 0 ? (
                  <div
                    style={{
                      fontSize: "24px",
                      textAlign: "center",
                      minHeight: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.loader ? "" : "No Items, Please Add One"}
                  </div>
                ) : (
                  ""
                )}
                {filterData.length === 0 ? (
                  ""
                ) : (
                  <Pagination
                    itemsCount={this.state.data.length}
                    pageSize={this.state.displayLength}
                    currentPage={this.state.page}
                    onPageChange={this.handleChangePage}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SavedCal;
