import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import { FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Modal, Loader, Tooltip, Whisper, Toggle, Alert } from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import { Table, Button, Form, Col, Row, Container } from "react-bootstrap";
import config from "../../../env/config";
import { matchSorter } from "match-sorter";

class Tab extends Component {
  state = {
    filterServiceById: "",
    serviceListOption: "",
    allData: "",
    addEditLoading: false,
    tableMessage: "No Items.",
    displayLength: 7,
    addColumn: false,
    loading: false,
    checkAlreadyExit: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    serviceList: [],
    sm: false,
    smd: false,
    loader: true,

    serviceId: "",
    servicePackage: "",
    expertise: "",
    essential: "",
    professional: "",
    enterprise: "",

    edit: 0,
    errorlen: 1,
    errors: {
      serviceId: "",
      servicePackage: "",
      expertise: "",
      essential: "",
      professional: "",
      enterprise: "",
    },
  };
  schema = {
    serviceId: Joi.number().required(),
    servicePackage: Joi.string().required(),
    expertise: Joi.number().required(),
    essential: Joi.number().min(1).required(),
    professional: Joi.number().min(1).required(),
    enterprise: Joi.number().min(1).required(),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      serviceId: this.state.serviceId,
      servicePackage: this.state.servicePackage,
      expertise: this.state.expertise,
      essential: this.state.essential,
      professional: this.state.professional,
      enterprise: this.state.enterprise,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;
    if (input.name === "servicePackage") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
      this.checkAlreadyExitSerivcePackages();
    } else {
      this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }
  };
  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
      [input.name]: input.value,
    });
    this.handleFilterSearch(this.state.searchString);
  };

  handleFilterSearch = (string) => {
    const dataForFilter = this.state.dataForFilter;
    let z = matchSorter(dataForFilter, string, { keys: ["servicePackage"] });
    this.setState({
      data: z,
      page: 1,
    });
  };
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.data.length) {
      if (!this.state.id) {
        let existPackage = await this.state.data.filter((pack) => {
          return (
            this.state.id !== pack.id &&
            pack.servicePackage.toLowerCase() ===
            this.state.servicePackage.toLowerCase()
          );
        });
        if (existPackage.length) {
          this.danger("already Exist package");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      } else {
        let existPackage = await this.state.data.filter((pack) => {
          return (
            pack.servicePackage.toLowerCase() ===
            this.state.servicePackage.toLowerCase()
          );
        });
        if (existPackage.length) {
          this.danger("already Exist package");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      }
    }
  };

  handleFilter = async (event) => {
    await this.setState({
      loader: true,
      [event.target.name]: event.target.value,
    });

    if (this.state.filterServiceById) {
      let x = this.state.allData.filter(
        (y) => y.service_Id === this.state.filterServiceById
      );
      this.setState({
        loader: false,
        data: x,
        page: 1,
      });
      // console.log('--------line no.9xxxxxxxxxxxx3', x)
    } else {
      this.setState({
        loader: false,
        data: this.state.allData,
      });
    }
  };

  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };
  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},
    });
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
      serviceId: "",
      servicePackage: "",
      expertise: "",
      essential: "",
      professional: "",
      enterprise: "",
      id: "",
      errors: {},
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };
  setSmdFalse = () => {
    this.setState({
      smd: false,
      errors: {},
      serviceId: "",
      servicePackage: "",
      expertise: "",
      essential: "",
      professional: "",
      enterprise: "",
      id: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };
  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getService, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        let filterArray = [];
        if (res && res.status === 200) {
          // console.log(res.data.data)
          array = res.data.data;
          filterArray = array.filter((ar) => Number(ar.status) === 1);
        }
        this.setState({
          serviceList: array,
          serviceListOption: filterArray,
        });
        this.getServicePackagelist();
      })
      .catch((error) => {
        this.setState({
          loader: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
        console.log(error);
      });
  };
  getServicePackagelist = () => {
    axios
      .post(config[config.currentUser.user_type].getServicePackage, {
        token: config.currentUser.token,
      })
      .then((res) => {
        // console.log(res.data.data);

        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          loader: false,
          allData: array,
          dataForFilter: array,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      return;
    } else if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Package");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    axios
      .post(config[config.currentUser.user_type].addServicePackage, {
        serviceId: this.state.serviceId,
        servicePackage: this.state.servicePackage,
        expertise: this.state.expertise,
        essential: this.state.essential,
        professional: this.state.professional,
        enterprise: this.state.enterprise,
        token: config.currentUser.token,
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Added!");
          let resService = res.data.data;
          this.setState({
            serviceId: "",
            data: [...this.state.data, resService],
            allData: [...this.state.allData, resService],
            servicePackage: "",
            expertise: "",
            essential: "",
            professional: "",
            enterprise: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
            checkAlreadyExit: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };

  handleEdit = (data) => {
    this.setState({
      edit: 1,
      serviceId: data.service_Id,
      servicePackage: data.servicePackage,
      expertise: data.expertise,
      essential: data.essential,
      professional: data.professional,
      enterprise: data.enterprise,
      id: data.id,
      sm: true,
    });

    window.location.href = `#/package/:${data.id}`;
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Package");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    const {
      serviceId,
      servicePackage,
      expertise,
      essential,
      professional,
      enterprise,
      id,
      status,
      packageData,
    } = this.state;
    const Package = {
      packageData,
      serviceId,
      servicePackage,
      expertise,
      essential,
      professional,
      enterprise,
      status: status,
      token: config.currentUser.token,
    };
    // console.log("Package============", Package);
    axios
      .post(
        config[config.currentUser.user_type].updateServicePackage + id,
        Package
      )
      .then((res) => {
        if (res.data.status === 200) {
          // console.log(res);
          this.alert("Successfully Updated!");
          // this.componentDidMount();
          let resServiceUpd = res.data.data;

          let found = this.state.data.filter(
            (arr) => arr.id === resServiceUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resServiceUpd;

            let data = [...this.state.data];
            data[index] = resServiceUpd;
            this.setState({ data });
          }

          let allFound = this.state.allData.filter(
            (arr) => arr.id === resServiceUpd.id
          );
          if (allFound.length) {
            const index2 = this.state.allData.indexOf(allFound[0]);
            let allData = [...this.state.allData];
            allData[index2] = resServiceUpd;
            this.setState({ allData });
          }
          this.setState({
            sm: false,
            serviceId: "",
            servicePackage: "",
            expertise: "",
            essential: "",
            professional: "",
            enterprise: "",
            id: "",
            edit: 0,
            addEditLoading: false,
            checkAlreadyExit: false,
          });
        } else {
          this.danger("Please Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const user = {
      token: config.currentUser.token,
    };

    axios
      .post(
        config[config.currentUser.user_type].deleteServicePackage + id,
        user
      )
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);
          const allFound = this.state.allData.filter((obj) => obj.id !== id);
          if (found.length) {
            let page = "";
            if (found.length % this.state.displayLength) {
              page = this.state.page;
            } else {
              page = Math.ceil(found.length / this.state.displayLength);
            }
            this.setState({
              data: found,
              allData: allFound,
              page,
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            serviceId: "",
            servicePackage: "",
            expertise: "",
            essential: "",
            professional: "",
            enterprise: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data) => {
    await this.setState({
      edit: 1,
      servicePackage: data.servicePackage,
      serviceId: data.service_Id,
      essential: data.essential,
      professional: data.professional,
      enterprise: data.enterprise,
      status: Number(data.status) === 1 ? 2 : 1,
      packageData: data.packageData,
      id: data.id,
    });
    this.onUpdate();
  };
  danger = (s) => {
    Alert.warning(s, 4000);
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s);
    // Notification.success({
    //   title: s,
    //   // description:s
    // });
  };
  showServiceName = (id) => {
    let filtered = this.state.serviceList.filter((arr) => arr.id === id);
    if (filtered.length) {
      return filtered[0].serviceName;
    } else {
      return "";
    }
  };

  render() {
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );

    return (
      <div>
        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop: "100px",
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="md"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
                ""
              )}
          </Modal.Body>
        </Modal>

        <Container fluid>
          <Row>
            <Col className="">
              <div className="my-3 d-flex flex-row justify-content-between flex-wrap">
                <div className="h4">Manage Packages</div>
                <div className="d-flex">
                  <Form.Group className="mb-0">
                    <Form.Control
                      placeholder="Search Here."
                      name="searchString"
                      value={this.state.searchString}
                      onChange={this.handleSelect}
                    />
                  </Form.Group>
                  <Form.Group className="mb-0 ml-3">
                    <Form.Control
                      as="select"
                      value={this.state.filterServiceById}
                      name="filterServiceById"
                      onChange={this.handleFilter}
                    >
                      <option value="">All Service</option>
                      {this.state.serviceList.length
                        ? this.state.serviceList.map((ser) => (
                          <option key={ser.id} value={ser.id}>
                            {ser.serviceName}
                          </option>
                        ))
                        : ""}
                    </Form.Control>
                  </Form.Group>

                  {/* <Button className=" btn-sm ml-3" onClick={() => this.setSmTrue()}> */}
                  <Button
                    className=" btn-sm ml-3"
                    onClick={() => (window.location.href = "#/package")}
                  >
                    <FaPlus className="mr-2 mb-1" />
                    Add New
                  </Button>
                </div>
              </div>

              <div className="">
                <div className="my-custom-scrollbar">
                  <Table
                    striped
                    bordered
                    hover
                    responsive="lg"
                    className="mb-0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Package Name</th>
                        <th>Service Name</th>
                        {/* <th>Expertise</th> */}
                        <th>Essential</th>
                        <th>Professional</th>
                        <th>Enterprise</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {this.state.loader ? (
                      <Loader
                        size="lg"
                        center
                        backdrop
                        content="Loading Please Wait.."
                      />
                    ) : (
                        <tbody>
                          {filterData.map((data, index) => (
                            <tr key={data.id}>
                              <td>
                                {this.state.displayLength *
                                  (this.state.page - 1) +
                                  index +
                                  1}
                              </td>
                              <td>{data.servicePackage}</td>
                              <td>{this.showServiceName(data.service_Id)}</td>
                              {/* <td>{this.handleExpertise(data.expertise)}</td> */}

                              {/* <td>{Number(data.expertise) === 3 ? "Amateur" : Number(data.expertise) === 7 ? "Professional" : Number(data.expertise) === 10 ? "Expert" : "No Expertise"}</td> */}
                              <td>{data.essential}</td>
                              <td>{data.professional}</td>
                              <td>{data.enterprise}</td>
                              <td>
                                <Toggle
                                  name="status"
                                  size="md"
                                  checkedChildren="Active"
                                  unCheckedChildren="Inactive"
                                  defaultChecked={
                                    Number(data.status) === 1 ? true : false
                                  }
                                  onChange={() => this.handleActiveInactive(data)}
                                />
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Whisper
                                    trigger="hover"
                                    placement="bottom"
                                    speaker={<Tooltip>Edit</Tooltip>}
                                  >
                                    <button
                                      disabled={true}
                                      onClick={() => {
                                        this.handleEdit(data);
                                      }}
                                      className="btn btn-primary btn-sm m-1"
                                    >
                                      <FaRegEdit />
                                    </button>
                                  </Whisper>

                                  <Whisper
                                    trigger="hover"
                                    placement="bottom"
                                    speaker={<Tooltip>Delete</Tooltip>}
                                  >
                                    {/* <Button appearance="subtle">click</Button> */}
                                    <button
                                      onClick={() => this.handleRemove(data)}
                                      className="btn btn-danger m-1 btn-sm"
                                    >
                                      <FaRegTrashAlt />
                                    </button>
                                  </Whisper>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                  </Table>
                </div>
                {filterData.length === 0 ? (
                  <div
                    style={{
                      fontSize: "24px",
                      textAlign: "center",
                      minHeight: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.loader ? "" : this.state.tableMessage}
                  </div>
                ) : (
                    ""
                  )}
                {filterData.length === 0 ? (
                  ""
                ) : (
                    <Pagination
                      itemsCount={this.state.data.length}
                      pageSize={this.state.displayLength}
                      currentPage={this.state.page}
                      onPageChange={this.handleChangePage}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Tab;
