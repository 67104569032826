import React, { Component } from "react";

// importing Component

import { BrowserRouter as Router } from "react-router-dom";

class Dashboard extends Component {
  state = {};
  render() {
    return (
      <Router>
        <div
          className=""
          style={{
            display: "-ms-inline-flexbox",
          }}
        >
          <h4
            style={{
              textAlign: "center",
              color: "grey",
              marginTop: "10%",
              marginBottom: 15,
              fontFamily: "Montserrat",
            }}
          >
            Estimation Calculator, From AM Webtech Pvt. Ltd.
          </h4>
          <div
            style={{
              textAlign: "center",
            }}
          ></div>
        </div>
      </Router>
    );
  }
}

export default Dashboard;
