import React, { Component } from "react";
import { Form, Col, Button, Container, Row } from "react-bootstrap";
import { Modal, Alert } from "rsuite";
import jsPDF from "jspdf";
import axios from "axios";
import Joi from "joi-browser";
import DatePICKER from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import data from "../../../../env/config";
import calculationContext from "../../../context/calculationContext";
import CardComponent from "./../../../common/card";
import Forminputtext from "../../../common/Forminputtext";
import PdfModal from "../../../common/PdfModal";
// import footerImage from '../../../../images/footer.png'
// import SaveCalculationModal from "../../../common/SaveCalculationModal";

class HourlyWise extends Component {
  state = {
    typing: false,
    inputValue: "",
    tags: [],

    fresh: true,
    smd: false,
    data: data,
    weeklyHours: 20,
    timeFrame: 12,
    complexity: "",
    expertise: "",
    regions: "",
    services: 0,
    rateOffered: 0,
    min: 8,
    max: 25,
    totalHours: 0,
    selectedData: {
      regions: { regionFactor: "", regionName: "" },
      services: { expertise: "0", discount: 0 },
      currencies: { currencyFactor: "1", currencySymbol: "$" },
      countries: {},
    },
    totalCost: 0,
    projectName: "",
    errors: [],
    errorlen: 1,
    // projectName: "",
    prospectName: "",
    expiryDate: "",
    currencies: "",
    finalPriceWithDiscount: 0,
    discount: "",
    // expiryDate: '',
    currencyButton: true,
    discountButton: true,
  };
  schema = {
    projectName: Joi.string().max(24).required().label("Project Name"),
    prospectName: Joi.string().max(24).required().label("Prospect Name"),
  };
  componentDidMount = () => {
    if (this.props.match.params.id) {
      const id = this.props.match.params.id.match(/\d/g).join("");
      const token = {
        token: data.currentUser.token,
      };
      const api =
        Number(data.currentUser.user_type) === 1
          ? data[data.currentUser.user_type].getTemplateById + id
          : data[data.currentUser.user_type].getTemplate +
          data.currentUser.id +
          "/" +
          id;
      axios
        .post(api, token)
        .then(async (res) => {
          if (res.data.status === 200) {
            console.log(res.data);
            let estimationData = {};
            if (Number(data.currentUser.user_type) === 1) {
              estimationData = JSON.parse(res.data.data.estimationData);
            } else {
              estimationData = JSON.parse(res.data.data[0].estimationData);
            }
            // let templateTags = JSON.parse(estimationData.tags)
            this.alert("You Can Clone Now !!");

            await this.setState({
              fresh: false,
              id,
              regions: estimationData.regions,
              services: estimationData.services,
              weeklyHours: estimationData.weeklyHours,
              timeFrame: estimationData.timeFrame,
              complexity: estimationData.complexity,
              expertise: estimationData.expertise,
              discount: estimationData.discount,
              currencies: estimationData.currencies,
              selectedData: estimationData.selectedData,
              tags: estimationData.tags,
              projectName: estimationData.projectName,
              prospectName: estimationData.prospectName,
              expiryDate: estimationData.expiryDate,
            });

            let serviceIDd = estimationData.selectedData.services.id;
            setTimeout(() => {
              let foundData = data.globalData.services.filter((element) => {
                return element.id === serviceIDd;
              });
              if (foundData.length) {
                let services = foundData[0];
                this.setState({
                  selectedData: { ...this.state.selectedData, services },
                });
              }
            }, 1000);
            this.calculate();
          } else if (res.status === 201) {
            this.danger("Something Wrong, Try Later!");
          }
        })
        .catch((error) => {
          this.danger("Error From Server, Try Later!");
        });
    }

    let a = new Date();
    let Today =
      ("0" + a.getDate()).slice(-2) +
      "-" +
      ("0" + (a.getMonth() + 1)).slice(-2) +
      "-" +
      a.getFullYear();

    let d = new Date();
    d.setDate(d.getDate() + data.expiryDays);

    let expiryDate =
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear();

    this.setState({
      todayDate: Today,
      userId: this.state.data.currentUser.id,
      expiryDate,
    });
  };
  handleSelectDataChange = async (id) => {
    let foundData = await data.globalData.services.filter(
      (element) => element.id === id
    );
    if (foundData.length) {
      let services = foundData[0];
      this.setState({
        selectedData: { ...this.state.selectedData, services },
      });
    }
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      projectName: this.state.projectName,
      prospectName: this.state.prospectName,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleSelect = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;

    await this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
    // if (input.name === "regions") {
    //   console.log("enter in the region handle change block");
    //   console.log(input.value);
    //   let findData = data.globalData.countries.filter((element) => element.id === input.value);
    //   console.log(findData);
    //   this.setState({regions:findData[0].regionId});

    //   var foundData = await data.globalData[input.name].filter((element) => {
    //     return input.value === element.id;
    //   });
    //   if (foundData.length) {
    //     this.state.selectedData[input.name] = foundData[0];
    //   }
    // }
    if (input.name === "tags") {
      let tagArray = input.value.split(",");
      this.setState({ tags: tagArray });
    }
    if (
      input.name === "services" ||
      input.name === "regions" ||
      input.name === "currencies"
    ) {
      if (input.name === "regions") {
        let selectedRegions = input.value.split("###");
        var foundData = await data.globalData[input.name].filter((element) => {
          return selectedRegions[1] === element.id;
        });
      } else {
        foundData = await data.globalData[input.name].filter((element) => {
          return input.value === element.id;
        });
      }

      if (foundData.length) {
        this.state.selectedData[input.name] = foundData[0];
        if (input.name === "services" || input.name === "regions") {
          // console.log(foundData[0], "===");
          if (input.name === "services") {
            console.log(foundData[0].serviceName, "services");
            this.setState({
              tags: [foundData[0].serviceName],
            });
          } else {
            console.log(foundData[0], "region");
          }
        }
      }
    }
    if (input.name === "services") {
      let expertiseValue = this.state.selectedData.services.expertise;

      // this.schemaForDiscount.discount = Joi.number().min(0).max(Number(this.state.selectedData.services.discount)).label('Discount')
      this.setState({
        expertise: expertiseValue,
      });
    }
    if (input.name === "discount") {
      if (input.value > 100) {
        this.setState({ discount: 100 });
      } else if (input.value < 0) {
        this.setState({ discount: "" });
      }

      if (input.value > Number(this.state.selectedData.services.discount)) {
        this.danger("Max Discount Reached");
      } else if (input.value === "") {
        this.alert("Discount Removed");
        this.setState({ discount: "" });
      } else if (
        input.value > 0 &&
        input.value < Number(this.state.selectedData.services.discount)
      ) {
        this.alert("Discount Applied!");
      }

      if (input.value === 0) {
        this.setState({
          discount: "",
        });
      }
    }

    this.calculate();
  };
  handleChange = (event) => {
    let date = new Date(event);
    let formatedExpiryDate =
      ("0" + date.getDate()).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();
    this.setState({
      expiryDate: formatedExpiryDate,
    });
  };
  handleTimeFrameSlider = (event) => {
    this.setState({
      timeFrame: event,
    });
    this.calculate();
  };
  handleWeeklyHourSlider = (event) => {
    this.setState({
      weeklyHours: event,
    });
    this.calculate();
  };
  handlecurrencyButton = () => {
    this.setState({
      currencyButton: !this.state.currencyButton,
    });
  };
  handleDiscountButton = () => {
    this.setState({
      discountButton: !this.state.discountButton,
    });
  };
  onSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      let zh = Object.values(errors);
      this.danger(zh[0]);
      return;
    }

    this.setState({
      sm: true,
      smd: false,
    });
  };
  handleAdd = () => {
    const template = {
      title: this.state.projectName,
      tags: JSON.stringify(this.state.tags),
      type: "Hourly",
      userId: this.state.userId,
      estimationData: JSON.stringify({
        regions: this.state.regions,
        services: this.state.services,
        weeklyHours: this.state.weeklyHours,
        timeFrame: this.state.timeFrame,
        complexity: this.state.complexity,
        expertise: this.state.expertise,
        discount: this.state.discount,
        currencies: this.state.currencies,
        selectedData: this.state.selectedData,
        projectName: this.state.projectName,
        prospectName: this.state.prospectName,
        expiryDate: this.state.expiryDate,
      }),
      token: data.currentUser.token,
    };
    let api =
      Number(data.currentUser.user_type) === 1
        ? data[data.currentUser.user_type].addTemplate
        : data[data.currentUser.user_type].addTemplate + data.currentUser.id;
    console.log(template);
    axios
      .post(api, template)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Calculation Saved!");
          // this.handlePDF();
          this.htmlpdf();
          this.setState({
            addEditLoading: false,
            // projectName: "",
            // prospectName: "",
            smd: false,
            sm: false,
          });
        } else if (res.status === 201) {
          this.setState({
            regions: "",
            services: "",
            weeklyHours: "",
            timeFrame: "",
            complexity: "",
            expertise: "",
            projectName: "",
            prospectName: "",
            // expiryDate:'',
            sm: false,
          });
          this.danger("Something Went Wrong, Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          regions: "",
          services: "",
          weeklyHours: "",
          timeFrame: "",
          complexity: "",
          expertise: "",
          // expiryDate:'',
          projectName: "",
          prospectName: "",
          sm: false,
        });
        this.danger("Error From Server, Try Later!");
      });
  };
  calculate = async () => {
    let {
      weeklyHours,
      timeFrame,
      complexity,
      expertise,
      selectedData,
      discount,
    } = await this.state;

    let timeIndex = weeklyHours * timeFrame;
    // let expertiseIndex = complexity * expertise;
    let expertiseIndex = selectedData.services.expertise * expertise;
    let rateIndex = selectedData.services.hourlyPrice * (expertiseIndex / 100);
    // console.log("complexity=======", selectedData.services)
    // console.log("complexity=======", selectedData.services.expertise)
    // console.log("selectedData.services.hourlyPrice", selectedData.services.hourlyPrice)

    let rateOffered = 1;
    if (timeIndex > 600) {
      rateOffered = rateIndex * 0.8;
    } else if (timeIndex > 400) {
      rateOffered = rateIndex * 0.9;
    } else if (timeIndex < 200) {
      rateOffered = rateIndex * 1.25;
    } else {
      rateOffered = rateIndex * 1;
    }
    // console.log("timeIndex========", timeIndex)
    // console.log("rateOffered========", rateOffered)

    const finalPrice =
      rateOffered *
      selectedData.regions.regionFactor *
      selectedData.currencies.currencyFactor;
    const discountPrice = (finalPrice / 100) * discount;

    // console.log("selectedData.regions=====", selectedData.regions)
    // console.log("selectedData.currencies=====", selectedData.currencies)
    // console.log("finalPrice=====", finalPrice)
    // console.log("discountPrice=====", discountPrice)
    const finalPriceWithDiscount = finalPrice - discountPrice;

    this.setState({
      // ...this.state,
      rateOffered: finalPrice,
      finalPriceWithDiscount,
    });
  };
  htmlpdf = () => {
    const input = document.getElementById("hourlyTemplate");
    const pdf = new jsPDF({ unit: "px", format: "a4", userUnit: "px" });

    //with inspect
    // pdf.html(input, { html2canvas: { scale: 0.66 } }).then(() => {
    //without inspect on desktop
    // pdf.addImage(footerImage, "PNG", 0, 780, 595, 62);
    pdf.html(input, { html2canvas: { scale: 0.42 } }).then(() => {
      pdf.save("test.pdf");
    });
  };
  setSmdFalse = () => {
    if (this.props.match.params.id) {
      this.setState({
        smd: false,
      });
    } else {
      this.setState({
        smd: false,
        addEditLoading: false,
        projectName: "",
        // expiryDate:'',
        prospectName: "",
      });
    }
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
      // addEditLoading: false,
    });
    this.setSmdFalse();
  };
  smdShow = () => {
    this.setState({
      smd: true,
    });
  };
  danger = (s) => {
    Alert.error(s, 4000);
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s);
  };
  handleRedirect = () => {
    window.location.href = "#/savedcalculation";
  };
  render() {
    const allValue =
      this.state.services &&
      this.state.timeFrame &&
      this.state.weeklyHours &&
      this.state.regions;

    let filterService;
    let filterCurrency;
    let filterCountries;
    if (this.props.match.params.id) {
      filterService = data.globalData.services
        ? data.globalData.services.filter((ser) => {
          return Number(ser.status) === 1 || Number(ser.status) === 2;
        })
        : {};
      filterCurrency = data.globalData.currencies
        ? data.globalData.currencies.filter((ser) => {
          return Number(ser.status) === 1 || Number(ser.status) === 2;
        })
        : {};
      filterCountries = data.globalData.countries
        ? data.globalData.countries.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
    } else {
      // filterRegion = data.globalData.regions ? data.globalData.regions.filter(ser => Number(ser.status) == 1) : {}
      filterService = data.globalData.services
        ? data.globalData.services.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
      filterCurrency = data.globalData.currencies
        ? data.globalData.currencies.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};
      filterCountries = data.globalData.countries
        ? data.globalData.countries.filter((ser) => {
          return Number(ser.status) === 1;
        })
        : {};

      // console.log("filterService==", filterService);
      // console.log("filterCurrency==", filterCurrency);
      // console.log("filterCountries==", filterCountries);
    }

    return (
      <Container>
        <PdfModal
          show={this.state.sm}
          onhide={this.setSmFalse}
          prospectName={this.state.prospectName}
          todayDate={this.state.todayDate}
          projectName={this.state.projectName}
          serviceName={this.state.selectedData.services.serviceName}
          timeFrame={this.state.timeFrame}
          weeklyHours={this.state.weeklyHours}
          currencySymbol={this.state.selectedData.currencies.currencySymbol}
          finalPriceWithDiscount={this.state.finalPriceWithDiscount}
          expiryDate={this.state.expiryDate}
          id={this.state.id}
          handleAdd={this.handleAdd}
          calculationType="hourly"
        />
        {/* proceed modal open */}
        {/* <SaveCalculationModal
          show={this.state.smd}
          onhide={this.setSmdFalse}
          onsubmit={this.onSubmit}
          handleSelect={this.handleSelect}
          projectName={this.state.projectName}
          prospectName={this.state.prospectName}
          tags = {this.state.tags}
          
        /> */}
        <Modal
          size="sm"
          className=""
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
        >
          <Modal.Header>
            <Modal.Title>Save Calculation</Modal.Title>
          </Modal.Header>

          <Modal.Body className="">
            <Form onSubmit={this.onSubmit}>
              <Forminputtext
                label="Project Name"
                name="projectName"
                value={this.state.projectName}
                onchange={this.handleSelect}
                placeholder="Type Here"
              />
              <Forminputtext
                label="Prospect Name"
                name="prospectName"
                value={this.state.prospectName}
                onchange={this.handleSelect}
                placeholder="Type Here"
              />
              <Forminputtext
                label="Tags"
                name="tags"
                value={this.state.tags}
                onchange={this.handleSelect}
                placeholder="Enter Tags Here"
              />

              <Form.Group>
                <Form.Label className="mr-2">Expiry Date </Form.Label>
                <DatePICKER
                  value={this.state.expiryDate}
                  onChange={(date) => this.handleChange(date)}
                  minDate={new Date()}
                  maxDate={new Date(+new Date() + 12096e5)}
                />
              </Form.Group>

              <div>
                <Button type="submit">Enter</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* proceed modal close */}

        <Row className="border-bottom">
          <Col>
            <div className="d-flex justify-content-between align-items-center h5 my-3">
              Hourly Wise Calculation
              {this.props.match.params.id ? (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={this.handleRedirect}
                >
                  {" "}
                  Back
                </Button>
              ) : (
                  ""
                )}
            </div>
          </Col>
        </Row>

        <Row className="mt-5 d-flex justify-content-between">
          <Col sm={12} lg={6} className="">
            <Form>
              <Form.Row className="">
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted">Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.regions}
                    name="regions"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select Region</option>

                    {/* <option key={reg.id} value={reg.regionId}> */}
                    {filterCountries.map((reg) => (
                      <option
                        key={reg.id}
                        value={reg.id + "###" + reg.regionId}
                      >
                        {reg.countryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted"> Service *</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.services}
                    name="services"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select</option>
                    {filterService.map((services) => (
                      <option key={services.id} value={services.id}>
                        {services.serviceName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              {/*  <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted">Complexity *</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.complexity}
                    name="complexity"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select</option>
                    {this.state.data.complexity &&
                      this.state.data.complexity.length
                      ? this.state.data.complexity.map((comp) => (
                        <option key={comp.value} value={comp.value}>
                          {comp.label}
                        </option>
                      ))
                      : ""}
                  </Form.Control>
                </Form.Group>
              </Form.Row> */}
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label>
                    Weekly Hours : <span>{this.state.weeklyHours}</span>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    min={5}
                    max={40}
                    value={this.state.weeklyHours}
                    name="weeklyHours"
                    onChange={this.handleSelect}
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label>
                    Time Frame in Weeks : <span>{this.state.timeFrame}</span>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    value={this.state.timeFrame}
                    name="timeFrame"
                    onChange={this.handleSelect}
                    min={1}
                    max={52}
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
            {/* <Tag/> */}
          </Col>

          <Col sm={12} lg={6} className="d-flex align-items-start">
            <CardComponent
              calculationName="hourly"
              allValue={allValue}
              symbol={this.state.selectedData.currencies.currencySymbol}
              finalprice={this.state.finalPriceWithDiscount}
              discount={this.state.discount}
              weeklyhours={this.state.weeklyHours}
              timeframe={this.state.timeFrame}
              maxdiscount={this.state.selectedData.services.discount}
              services={this.state.services}
              complexity={this.state.complexity}
              timerame={this.state.timeFrame}
              filtercurrency={filterCurrency}
              handleSelect={this.handleSelect}
              smdShow={this.smdShow}
              handleDiscountButton={this.handleDiscountButton}
              handlecurrencyButton={this.handlecurrencyButton}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

HourlyWise.contextType = calculationContext;
export default HourlyWise;
