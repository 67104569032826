import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import { FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Modal, Loader, Tooltip, Whisper, Alert, Toggle } from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import {
  Table,
  Button,
  Form,
  Col,
  Row,
  Container,
  InputGroup,
} from "react-bootstrap";
import config from "../../../env/config";
import { matchSorter } from "match-sorter";
class Tab extends Component {
  state = {
    addEditLoading: false,

    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    sm: false,
    username: "",
    email: "",
    smd: false,
    loader: true,
    user_type: 2,

    edit: 0,
    errorlen: 1,
    errors: {
      username: "",
      email: "",
    },
  };
  schema = {
    username: Joi.string().min(2).max(24).required().label("User Name"),
    email: Joi.string()
      .regex(/^[A-Za-z0-9._-]+$/)
      .required()
      .label("Email"),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      username: this.state.username,
      email: this.state.email,
      // user_type:this.state.user_type
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";
    const errorlen = errorMessage;
    this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
  };
  // handleChange=(event)=>{
  //   this.setState({
  //      [event.target.name]: event.target.value
  //   });
  //   }
  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
      [input.name]: input.value,
    });
    this.handleFilter(this.state.searchString);
  };

  handleFilter = (string) => {
    const userData = this.state.userData;
    let z = matchSorter(userData, string, { keys: ["username", "email"] });
    this.setState({
      data: z,
      page: 1,
    });
  };
  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };
  // doSort = (path,order) => {
  //   const filterData = paginate(this.state.data ,this.state.page,this.state.displayLength)
  //   const data = [...filterData]
  //  this.order = this.order !== 'asc' ? 'asc' : 'desc'
  //  let x = _.orderBy(data,[path],[this.order])
  //  this.setState({
  //      data:x
  //  })};
  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},
    });
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
      error: {},
      username: "",
      email: "",
      id: "",
    });
  };
  setSmdFalse = () => {
    this.setState({
      smd: false,
      error: {},
      username: "",
      id: "",
      email: "",
    });
  };
  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getUser, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res.data.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          userData: array,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later after login in again!"
        );
      });
  };
  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    axios
      .post(config[config.currentUser.user_type].addUser, {
        username: this.state.username,
        email: this.state.email + "@amwebtech.com",
        user_type: this.state.user_type,
        id: this.state.user.id,

        token: config.currentUser.token,
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Added!");
          let resUser = res.data.data;

          this.setState({
            username: "",
            data: [...this.state.data, resUser],
            email: "",
            user_type: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
        if (res.data.status === 201) {
          this.danger("User Email already registered!!");
          this.setState({
            username: "",
            email: "",
            user_type: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleEdit = (data) => {
    this.setState({
      edit: 1,
      email: data.email,
      username: data.username,
      user_type: data.user_type,
      status: data.status,
      id: data.id,
      sm: true,
    });
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      addEditLoading: true,
    });
    const { email, username, user_type, status, id } = this.state;
    const user = {
      email, //now manager is unable to change the user email
      username,
      user_type,
      status,
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].updateUser + id, user)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Updated!");
          let resUserUpd = res.data.data;

          let found = this.state.data.filter((arr) => arr.id === resUserUpd.id);
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resUserUpd;

            let data = [...this.state.data];
            data[index] = resUserUpd;
            this.setState({ data });
          }

          this.setState({
            sm: false,
            username: "",
            email: "",
            user_type: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        } else {
          this.danger("Please Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
          edit: 0,
          email: "",
          username: "",
          user_type: "",
          status: "",
          id: "",
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const user = {
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].deleteUser + id, user)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);

          if (found.length) {
            let page = "";
            if (found.length % this.state.displayLength) {
              page = this.state.page;
            } else {
              page = Math.ceil(found.length / this.state.displayLength);
            }
            this.setState({
              data: found,
              page,
              // page:Math.ceil(found.length / this.state.displayLength)
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            username: "",
            email: "",
            user_type: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
          // this.open('Success')
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data) => {
    await this.setState({
      edit: 1,
      email: data.email,
      username: data.username,
      user_type: data.user_type,
      status: Number(data.status) === 1 ? 2 : 1,
      id: data.id,
    });
    this.onUpdate();
  };
  handleUserType = () => {
    this.setState({
      user_type: !this.state.user_type,
    });
  };
  danger = (s) => {
    Alert.error(s, 4000);
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s);
    // Notification.success({
    //   title: s,
    // });
  };
  render() {
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );
    let usernameErrorLen = this.state.errors.username
      ? this.state.errors.username.length
      : 0;
    let emailErrorLen = this.state.errors.email
      ? this.state.errors.email.length
      : 0;

    return (
      <div>
        <Modal
          size="xs"
          backdrop={false}
          show={this.state.sm}
          onHide={this.setSmFalse}
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.id ? "Update User" : "Add User"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingBottom: "0px" }}>
            <Form onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}>
              <Form.Group>
                <Form.Label>User's Full Name</Form.Label>
                <Form.Control
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange}
                  placeholder="Enter Username"
                />
                <div
                  className={
                    usernameErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.username}
                </div>
              </Form.Group>

              {this.state.id ? (
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Enter Email Address"
                    readOnly
                  />
                  <div
                    className={
                      emailErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.email}
                  </div>
                  <Form.Text className="text-muted">
                    You Can't change the user email address
                  </Form.Text>
                </Form.Group>
              ) : (
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Enter Email Address"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        @amwebtech.com
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <div
                    className={
                      emailErrorLen === 0 ? "" : "alert alert-danger p-1"
                    }
                  >
                    {this.state.errors.email}
                  </div>
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.handleChange}
                  name="user_type"
                  value={this.state.user_type}
                >
                  {/* <option value={2}>User</option>
                  <option value={1}>Manager</option> */}
                  {config.userType && config.userType.length
                    ? config.userType.map((x) => (
                        <option key={x.userTypeId} value={x.userTypeId}>
                          {x.userName}
                        </option>
                      ))
                    : ""}
                </Form.Control>
              </Form.Group>
              <div>
                <Button disabled={this.state.errorlen} type="submit">
                  {this.state.id ? "Update" : "Add"}
                </Button>
              </div>
              {this.state.addEditLoading ? (
                <Loader
                  size="md"
                  color="white"
                  center
                  backdrop
                  content={
                    // <img style={{width:'150px'}} src={amwLoader}/>
                    "Loading Please Wait"
                  }
                />
              ) : (
                ""
              )}
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop: "100px",
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="md"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>

        <Container fluid className="">
          <Row>
            <Col className="">
              <div className="">
                <div className="my-3 d-flex flex-row justify-content-between h4">
                  Manage Users
                  <div className="d-flex">
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder="Search Here."
                        name="searchString"
                        value={this.state.searchString}
                        onChange={this.handleSelect}
                      />
                    </Form.Group>
                    <Button
                      className=" btn-sm ml-3"
                      onClick={() => this.setSmTrue()}
                    >
                      <FaPlus className="mr-2 mb-1" />
                      Add New
                    </Button>
                  </div>
                </div>

                <div className="my-custom-scrollbar">
                  <Table
                    striped
                    bordered
                    hover
                    responsive="lg"
                    className="mb-0"
                  >
                    <thead>
                      <tr>
                        <th
                        // onClick={()=> this.doSort('Id','desc')} style={{cursor:'pointer'}}
                        >
                          S.No
                        </th>
                        <th
                        //  onClick={()=> this.doSort('firstName','asc')} style={{cursor:'pointer'}}
                        >
                          Username
                        </th>
                        <th>Email I'd</th>
                        <th>Account Type</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {this.state.loader ? (
                      <Loader
                        size="lg"
                        color="white"
                        center
                        backdrop
                        content={
                          "Loading Please Wait"
                          // <img style={{width:'150px'}} src={amwLoader}/>
                        }
                      />
                    ) : (
                      <tbody>
                        {filterData.map((data, index) => (
                          <tr key={data.id}>
                            <td>
                              {this.state.displayLength *
                                (this.state.page - 1) +
                                index +
                                1}
                            </td>
                            <td>{data.username}</td>
                            <td>{data.email}</td>
                            <td>
                              {config.managerUserType &&
                              Number(data.user_type) ===
                                config.managerUserType.value
                                ? config.managerUserType.labelManager
                                : config.managerUserType &&
                                  config.managerUserType.labelUser
                                ? config.managerUserType.labelUser
                                : ""}
                            </td>
                            <td>
                              <Toggle
                                name="status"
                                size="md"
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                                defaultChecked={
                                  Number(data.status) === 1 ? true : false
                                }
                                onChange={() => this.handleActiveInactive(data)}
                              />
                            </td>
                            <td className="">
                              <div className="d-flex">
                                <Whisper
                                  trigger="hover"
                                  placement="bottom"
                                  speaker={<Tooltip>Edit</Tooltip>}
                                >
                                  <button
                                    onClick={() => {
                                      this.handleEdit(data);
                                    }}
                                    className="btn btn-primary btn-sm "
                                    style={{ margin: "1px" }}
                                  >
                                    <FaRegEdit />
                                  </button>
                                </Whisper>

                                <Whisper
                                  trigger="hover"
                                  placement="bottom"
                                  speaker={<Tooltip>Delete</Tooltip>}
                                >
                                  {/* <Button appearance="subtle">click</Button> */}
                                  <button
                                    onClick={() => this.handleRemove(data)}
                                    className="btn btn-danger  btn-sm"
                                    style={{ margin: "1px" }}
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                </Whisper>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </div>

                {filterData.length === 0 ? (
                  <div
                    style={{
                      fontSize: "24px",
                      textAlign: "center",
                      minHeight: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.loader ? "" : "No Items, Please Add One"}
                  </div>
                ) : (
                  ""
                )}
                {filterData.length === 0 ? (
                  ""
                ) : (
                  <Pagination
                    itemsCount={this.state.data.length}
                    pageSize={this.state.displayLength}
                    currentPage={this.state.page}
                    onPageChange={this.handleChangePage}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Tab;
