import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import { FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Modal, Loader, Tooltip, Whisper, Toggle, Alert } from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";

import { Table, Button, Form, Col, Row, Container } from "react-bootstrap";
import config from "../../../env/config";
import { matchSorter } from "match-sorter";

class Tab extends Component {
  state = {
    filterServiceById: "",
    serviceListOption: "",
    addEditLoading: false,
    tableMessage: "No Items.",
    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    allData: [],
    serviceList: [],
    sm: false,
    smd: false,
    loader: true,

    serviceId: "",
    serviceBundle: "",
    type: "",
    servicePrice: "",

    edit: 0,
    errorlen: 1,
    errors: {
      serviceId: "",
      serviceBundle: "",
      type: "",
      servicePrice: "",
    },
  };
  schema = {
    serviceId: Joi.number().required().label("Service"),
    serviceBundle: Joi.string().required().label("Service Bundle"),
    type: Joi.number().required().label('Type'),
    servicePrice: Joi.number().min(1).required().label("Service Price"),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      serviceId: this.state.serviceId,
      serviceBundle: this.state.serviceBundle,
      type: this.state.type,
      servicePrice: this.state.servicePrice,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleChange = async ({ currentTarget: input }) => {
    console.log(this.state.type)
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;

    if (input.name === "serviceBundle") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
      if (this.state.data) {
        this.checkAlreadyExitSerivcePackages();
      }
    } else {
      this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }
  };
  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
      [input.name]: input.value,
    });
    this.handleFilterSearch(this.state.searchString);
  };
  handleFilterSearch = (string) => {
    const dataForFilter = this.state.dataForFilter;
    let z = matchSorter(dataForFilter, string, { keys: ["serviceBundle"] });
    this.setState({
      data: z,
      page: 1,
    });
  };
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.data.length) {
      if (!this.state.id) {
        let existBundle = await this.state.data.filter((pack) => {
          return (
            this.state.id !== pack.id &&
            pack.serviceBundle.toLowerCase() ===
            this.state.serviceBundle.toLowerCase()
          );
        });
        if (existBundle.length) {
          this.danger("already Exist package");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      } else {
        let existBundle = await this.state.data.filter((pack) => {
          return (
            pack.serviceBundle.toLowerCase() ===
            this.state.serviceBundle.toLowerCase()
          );
        });
        if (existBundle.length) {
          this.danger("already Exist package");
          this.setState({
            checkAlreadyExit: true,
          });
        } else {
          this.setState({
            checkAlreadyExit: false,
          });
        }
      }
    }
  };
  handleFilter = async (event) => {
    event.preventDefault();

    await this.setState({
      loader: true,
      [event.target.name]: event.target.value,
    });

    if (this.state.filterServiceById) {
      let x = this.state.allData.filter(
        (y) => y.service_Id === this.state.filterServiceById
      );
      this.setState({
        loader: false,
        data: x,
        page: 1,
      });
    } else {
      this.setState({
        loader: false,
        data: this.state.allData,
      });
    }
  };
  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };
  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},
    });
  };
  setSmFalse = () => {
    this.setState({
      errors: "",
      sm: false,
      serviceId: "",
      serviceBundle: "",
      expertise: "",
      servicePrice: "",
      id: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };
  setSmdFalse = () => {
    this.setState({
      smd: false,
      errors: {},
      serviceId: "",
      serviceBundle: "",
      expertise: "",
      servicePrice: "",
      id: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };
  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getService, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        let filterArray = [];
        if (Number(res.data.status) === 200) {
          array = res.data.data;
          filterArray = array.filter((ar) => Number(ar.status) === 1);
        }
        this.setState({
          serviceList: array,
          serviceListOption: filterArray,
        });
        this.getServiceBundleList();
      })
      .catch((error) => {
        this.setState({
          loader: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  getServiceBundleList = () => {
    axios
      .post(config[config.currentUser.user_type].getServiceBundle, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          allData: array,
          loader: false,
          dataForFilter: array,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      return;
    } else if (this.state.checkAlreadyExit) {
      this.danger("already Exist package");
      return;
    }
    this.setState({
      addEditLoading: true,
    });


    axios
      .post(config[config.currentUser.user_type].addServiceBundle, {
        serviceId: this.state.serviceId,
        serviceBundle: this.state.serviceBundle,
        type: this.state.type,
        servicePrice: this.state.servicePrice,
        token: config.currentUser.token,
      })
      .then((res) => {
        if (res.status === 200) {
          this.alert("Successfully Added!");
          let resBundle = res.data.data;
          this.setState({
            serviceId: "",
            data: [...this.state.data, resBundle],
            allData: [...this.state.allData, resBundle],
            serviceBundle: "",
            expertise: "",
            servicePrice: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });

    // }
  };
  handleEdit = (data) => {
    this.setState({
      edit: 1,
      serviceId: data.service_Id,
      serviceBundle: data.serviceBundle,
      type: data.type,
      servicePrice: data.servicePrice,
      id: data.id,
      status: data.status,
      sm: true,
    });
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      addEditLoading: true,
    });
    // const errors = this.validate();

    if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Bundle");
      return;
    }
    const {
      serviceId,
      serviceBundle,
      type,
      servicePrice,
      status,
      id,
    } = this.state;
    const Bundle = {
      serviceId: serviceId,
      serviceBundle: serviceBundle,
      type: type,
      servicePrice: servicePrice,
      status: status,
      token: config.currentUser.token,
    };
    // console.log(Bundle)
    axios
      .post(
        config[config.currentUser.user_type].updateServiceBundle + id,
        Bundle
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Successfully Updated!");
          let resBundleUpd = res.data.data;

          let found = this.state.data.filter(
            (arr) => arr.id === resBundleUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);

            let data = [...this.state.data];
            data[index] = resBundleUpd;
            this.setState({ data });
          }

          let allFound = this.state.allData.filter(
            (arr) => arr.id === resBundleUpd.id
          );
          if (allFound.length) {
            const index2 = this.state.allData.indexOf(allFound[0]);
            // this.state.allData[index2] = resBundleUpd;

            let allData = [...this.state.allData];
            allData[index2] = resBundleUpd;
            this.setState({ allData });
          }
          this.setState({
            sm: false,
            serviceId: "",
            serviceBundle: "",
            expertise: "",
            servicePrice: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        } else {
          this.danger("Please Try Later!");
          // console.log(res)
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const user = {
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].deleteServiceBundle + id, user)
      .then((res) => {
        // console.log(res)
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);
          const allFound = this.state.allData.filter((obj) => obj.id !== id);
          if (found.length) {
            let page = "";
            if (found.length % this.state.displayLength) {
              page = this.state.page;
            } else {
              page = Math.ceil(found.length / this.state.displayLength);
            }
            this.setState({
              data: found,
              allData: allFound,
              page,
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            serviceId: "",
            serviceBundle: "",
            expertise: "",
            servicePrice: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data) => {
    await this.setState({

      edit: 1,
      serviceId: data.service_Id,
      serviceBundle: data.serviceBundle,
      type: data.type,
      servicePrice: data.servicePrice,
      status: Number(data.status) === 1 ? 2 : 1,
      id: data.id,
    });
    this.onUpdate();
  };
  danger = (s) => {
    Alert.warning(s, 4000);

  };
  alert = (s) => {
    Alert.success(s);
  };
  showServiceName = (id) => {
    let filtered = this.state.serviceList.filter((arr) => arr.id === id);
    if (filtered.length) {
      return filtered[0].serviceName;
    }
  };
  handleType = (exp) => {
    let z = config.type.filter((x) => Number(x.value) === Number(exp));
    if (z.length) {
      return z[0].label
    }
  };
  render() {
    // console.log(config.type)
    // console.log(this.state.type)
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );
    let serviceIdErrorLen = this.state.errors.serviceId
      ? this.state.errors.serviceId.length
      : 0;
    let serviceBundleErrorLen = this.state.errors.serviceBundle
      ? this.state.errors.serviceBundle.length
      : 0;

    let expertiseErrorLen = this.state.errors.expertise
      ? this.state.errors.expertise.length
      : 0;
    let servicePriceErrorLen = this.state.errors.servicePrice
      ? this.state.errors.servicePrice.length
      : 0;

    return (
      <div>
        <Modal
          size="xs"
          backdrop={false}
          show={this.state.sm}
          onHide={this.setSmFalse}
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.id ? "Update Bundle" : "Add Bundle"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}>
              <Form.Group>
                <Form.Label>Service</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.serviceId}
                  name="serviceId"
                  onChange={this.handleChange}
                >
                  {this.state.serviceListOption.length ? (
                    <option value="">Select Service</option>
                  ) : (
                      <option value="">Create/Active Service First</option>
                    )}
                  {this.state.serviceListOption.length
                    ? this.state.serviceListOption.map((ser) => (
                      <option key={ser.id} value={ser.id}>
                        {ser.serviceName}
                      </option>
                    ))
                    : ""}
                </Form.Control>
                <div
                  className={
                    serviceIdErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.serviceId}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Service Bundle</Form.Label>
                <Form.Control
                  name="serviceBundle"
                  value={this.state.serviceBundle}
                  onChange={this.handleChange}
                  placeholder="Enter Service Bundle Name"
                />
                <div
                  className={
                    serviceBundleErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.serviceBundle}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={this.state.type}
                  onChange={this.handleChange}
                >
                  <option value="">Choose Type</option>
                  {config.type && config.type.length ? config.type.map((x) => (
                    <option key={x.label} value={x.value}>
                      {x.label}
                    </option>
                  )) : ''}
                </Form.Control>
                <div
                  className={
                    expertiseErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.expertise}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Service Price</Form.Label>
                <Form.Control
                  type="number"
                  name="servicePrice"
                  value={this.state.servicePrice}
                  onChange={this.handleChange}
                  placeholder="Enter Service Price"
                />
                <div
                  className={
                    servicePriceErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.servicePrice}
                </div>
              </Form.Group>

              <Button disabled={this.state.errorlen} type="submit">
                {this.state.id ? "Update" : "Add"}
              </Button>
              {this.state.addEditLoading ? (
                <Loader
                  size="md"
                  color="#4285f4"
                  center
                  backdrop
                  content="Processing Please Wait."
                />
              ) : (
                  ""
                )}
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop: "100px",
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="md"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
                ""
              )}
          </Modal.Body>
        </Modal>

        <Container fluid>
          <Row>
            <Col className="">
              <div className="my-3 d-flex flex-row justify-content-between flex-wrap">
                <div className="h4">Manage Bundles</div>

                <div className="d-flex">
                  <Form.Group className="mb-0">
                    <Form.Control
                      placeholder="Search Here."
                      name="searchString"
                      value={this.state.searchString}
                      onChange={this.handleSelect}
                    />
                  </Form.Group>
                  <Form.Group className="mb-0 ml-3">
                    <Form.Control
                      as="select"
                      value={this.state.filterServiceById}
                      name="filterServiceById"
                      onChange={this.handleFilter}
                    >
                      <option value="">All Service</option>
                      {this.state.serviceList.length
                        ? this.state.serviceList.map((ser) => (
                          <option key={ser.id} value={ser.id}>
                            {ser.serviceName}
                          </option>
                        ))
                        : ""}
                    </Form.Control>
                  </Form.Group>

                  <Button
                    className=" btn-sm ml-3"
                    onClick={() => this.setSmTrue()}
                  >
                    <FaPlus className="mr-2 mb-1" />
                    Add New
                  </Button>
                </div>
              </div>
              <div className="my-custom-scrollbar">
                <Table striped bordered hover responsive="lg" className="mb-0">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Service Bundle</th>
                      <th>Service Name</th>
                      <th>Type</th>
                      <th>Service Price</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {this.state.loader ? (
                    <Loader
                      size="lg"
                      center
                      backdrop
                      content="Loading Please Wait.."
                    />
                  ) : (
                      <tbody>
                        {filterData.map((data, index) => (
                          <tr key={data.id}>
                            <td>
                              {this.state.displayLength * (this.state.page - 1) +
                                index +
                                1}
                            </td>
                            <td>{data.serviceBundle}</td>
                            <td>{this.showServiceName(data.service_Id)}</td>
                            {/* <td>{Number(data.expertise) === 3 ? "Amateur" : Number(data.expertise) === 7 ? "Professional" : Number(data.expertise) === 10 ? "Expert" : "No Expertise"}</td> */}
                            {/* <td>{this.handleExpertise(data.expertise)}</td> */}
                            <td>{this.handleType(data.type)}</td>
                            <td>{data.servicePrice}</td>
                            <td>
                              <Toggle
                                name="status"
                                size="md"
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                                defaultChecked={
                                  Number(data.status) === 1 ? true : false
                                }
                                onChange={() => this.handleActiveInactive(data)}
                              />
                            </td>
                            <td>
                              <div className="d-flex">
                                <Whisper
                                  trigger="hover"
                                  placement="bottom"
                                  speaker={<Tooltip>Edit</Tooltip>}
                                >
                                  <button
                                    onClick={() => {
                                      this.handleEdit(data);
                                    }}
                                    className="btn btn-primary btn-sm"
                                    style={{ margin: "1px" }}
                                  >
                                    <FaRegEdit />
                                  </button>
                                </Whisper>

                                <Whisper
                                  trigger="hover"
                                  placement="bottom"
                                  speaker={<Tooltip>Delete</Tooltip>}
                                >
                                  {/* <Button appearance="subtle">click</Button> */}
                                  <button
                                    onClick={() => this.handleRemove(data)}
                                    className="btn btn-danger btn-sm"
                                    style={{ margin: "1px" }}
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                </Whisper>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                </Table>
              </div>
              {filterData.length === 0 ? (
                <div
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                    minHeight: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.loader ? "" : this.state.tableMessage}
                </div>
              ) : (
                  ""
                )}
              {filterData.length === 0 ? (
                ""
              ) : (
                  <Pagination
                    itemsCount={this.state.data.length}
                    pageSize={this.state.displayLength}
                    currentPage={this.state.page}
                    onPageChange={this.handleChangePage}
                  />
                )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Tab;
