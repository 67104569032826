import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
// import {BrowserRouter as Router,Switch,Route,Redirect} from "react-router-dom";
import {Avatar,Icon,Dropdown,Alert,} from 'rsuite';

// images import 


// //Components import below this --
//Contexts import
import DataContext from '../context/dataContext';
import Data from '../../env/config';

class Header extends Component {
  // let history =  createBrowserHistory();
  state={
    
  }
  
  
  handleLogout=()=>{
    localStorage.removeItem('currentUser');
    this.context.handleLoginChange(false)
    window.location.href = Data.url + "#/login"; 
    this.alert('Succesfully Logout!')


    
  }
  danger = (s) =>{
    Alert.warning(s,4000)
    // Notification.error({
    //   title:"Error",
    //   description:s
    // })
  }
  alert = (s)=>{
    Alert.success(s)
    // Notification.success({
    //   title:s,
    //   // description:s
    // })
  }

  render() {
    // console.log('header',this.context.data)
    return (

      <Navbar className='navbar navbar-light bg-warninf' style={{
        backgroundColor: '#eeeeee',


      }} expand="lg">
       
        <Navbar.Brand className="" href={Data.url + '#/home'} >
					<img style={{ width: '12rem'}} src={this.context.data.logo} alt='logo'>
        </img>
        </Navbar.Brand>

        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
        <Nav className="ml-auto">
        
            <Dropdown 
                className="userProfile " 
                icon={<Avatar circle src={this.context.data.data ? this.context.data.data.imageUrl: ''} />}
                title={<h6 >{ this.context.data.data ? this.context.data.data.givenName : ''}</h6>}  
                noCaret placement="bottomEnd" 
                size='sm'
                >
                <Dropdown.Item panel style={{ padding: 10, width: 120}}>
                  <strong>{this.context.data.data ? this.context.data.data.name  : ''}</strong>
                </Dropdown.Item>
                <Dropdown.Item divider />
                <Dropdown.Item icon={<Icon icon="off" />} onClick={this.handleLogout}>
                  Logout
                </Dropdown.Item>
            </Dropdown>
          </Nav>
          
        {/* </Navbar.Collapse> */}
      </Navbar>
    );
  }
}

Header.contextType = DataContext;

export default Header;

