import React, { Component } from "react";
import { Form, Col, Button, Container, Row } from "react-bootstrap";
import jsPDF from "jspdf";
import Joi from "joi-browser";
import axios from "axios";
import { Modal, Alert } from "rsuite";
import DatePICKER from "react-datepicker";
//image imports


//importing data//
import data from "../../../../env/config";
import calculationContext from "../../../context/calculationContext";
import CardComponent from "./../../../common/card";
import Forminputtext from "../../../common/Forminputtext";
import PdfModal from "../../../common/PdfModal";

class ResourceWise extends Component {
  state = {
    data: data,
    weeklyHours: "",
    timeFrame: 3,
    resources: 5,
    expertise: "",
    regions: "",
    services: "",
    currencies: "",
    rateOffered: 0,
    totalMonthlyCost: 0,
    totalProjectCost: 0,
    min: 0,
    max: 0,
    totalHours: 0,
    totalCost: 0,
    selectedData: {
      regions: { regionFactor: "", regionName: "" },
      services: { expertise: "0", discount: 0 },
      currencies: { currencyFactor: "1", currencySymbol: "$" },
    },
    discount: "",
    expiryDate: "",
    currencyButton: true,
    discountButton: true,
  };
  schema = {
    projectName: Joi.string().max(24).required().label("Project Name"),
    prospectName: Joi.string().max(24).required().label("Prospect Name"),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      projectName: this.state.projectName,
      prospectName: this.state.prospectName,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  componentDidMount = () => {
    if (this.props.match.params.id) {
      const id = this.props.match.params.id.match(/\d/g).join("");
      const token = {
        token: data.currentUser.token,
      };
      const api =
        Number(data.currentUser.user_type) === 1
          ? data[data.currentUser.user_type].getTemplateById + id
          : data[data.currentUser.user_type].getTemplate +
          data.currentUser.id +
          "/" +
          id;

      axios
        .post(api, token)
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.data.estimationData);
            let estimationData = {};
            if (Number(data.currentUser.user_type) === 1) {
              estimationData = JSON.parse(res.data.data.estimationData);
            } else {
              estimationData = JSON.parse(res.data.data[0].estimationData);
            }
            this.alert("You Can Edit Now !!");

            // this.alert("You Can Edit Now !!");
            // const estimationData = JSON.parse(res.data.data.estimationData)
            // const {services,regions,currencies,selectedData} = estimationData

            this.setState({
              id,
              regions: estimationData.regions,
              services: estimationData.services,
              timeFrame: estimationData.timeFrame,
              complexity: estimationData.complexity,
              expertise: estimationData.expertise,
              discount: estimationData.discount,
              currencies: estimationData.currencies,
              selectedData: estimationData.selectedData,
              resources: estimationData.resources,
              tags: estimationData.tags,

              projectName: estimationData.projectName,
              prospectName: estimationData.prospectName,
              expiryDate: estimationData.expiryDate,
            });
            let serviceIDd = estimationData.selectedData.services.id;
            // console.log(serviceIDd)
            setTimeout(() => {
              let foundData = data.globalData.services.filter((element) => {
                return element.id === serviceIDd;
              });
              if (foundData.length) {
                // console.log(foundData)
                let services = foundData[0];
                this.setState({
                  selectedData: { ...this.state.selectedData, services },
                });
              }
            }, 1000);
            this.calculate();
          } else if (res.status === 201) {
            this.danger("Something Wrong, Try Later!");
          }
        })
        .catch((error) => {
          console.log(error);
          this.danger("Error From Server, Try Later!");
        });
    }

    let a = new Date();
    let Today =
      ("0" + a.getDate()).slice(-2) +
      "-" +
      ("0" + (a.getMonth() + 1)).slice(-2) +
      "-" +
      a.getFullYear();

    let d = new Date();
    d.setDate(d.getDate() + data.expiryDays);
    let expiryDate =
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear();

    this.setState({
      todayDate: Today,
      userId: this.state.data.currentUser.id,
      expiryDate,
    });
  };
  handleSelect = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;

    await this.setState({
      [input.name]: input.value,
      errors,
      errorlen,
    });
    if (input.name === "tags") {
      let tagArray = input.value.split(",");
      this.setState({ tags: tagArray });
    }
    if (
      input.name === "services" ||
      input.name === "regions" ||
      input.name === "currencies"
    ) {
      if (input.name === "regions") {
        let selectedRegions = input.value.split("###");
        var foundData = await data.globalData[input.name].filter((element) => {
          return selectedRegions[1] === element.id;
        });
      } else {
        foundData = await data.globalData[input.name].filter((element) => {
          return input.value === element.id;
        });
      }
      if (foundData.length) {
        this.state.selectedData[input.name] = foundData[0];
        if (input.name === "services" || input.name === "regions") {
          // console.log(foundData[0], "===");
          if (input.name === "services") {
            console.log(foundData[0].serviceName, "services");
            this.setState({
              tags: [foundData[0].serviceName],
            });
          } else {
            console.log(foundData[0], "region");
          }
        }
      }
    }
    if (input.name === "services") {
      // this.schemaForDiscount.discount = Joi.number().min(0).max(Number(this.state.selectedData.services.discount)).label('Discount')
      let expertiseValue = this.state.selectedData.services.expertise;
      this.setState({
        expertise: expertiseValue,
      });
    }
    if (input.name === "discount") {
      if (input.value > 100) {
        this.setState({ discount: 100 });
      } else if (input.value < 0) {
        this.setState({ discount: "" });
      }

      if (input.value > Number(this.state.selectedData.services.discount)) {
        this.danger("Max Discount Reached");
      } else if (input.value === "") {
        this.alert("Discount Removed");
        this.setState({ discount: "" });
      } else if (
        input.value > 0 &&
        input.value < Number(this.state.selectedData.services.discount)
      )
        this.alert("Discount Applied!");

      if (input.value === 0) {
        this.setState({
          discount: "",
        });
      }
    }

    this.calculate();
  };
  handleChange = (event) => {
    let date = new Date(event);
    let formatedExpiryDate =
      ("0" + date.getDate()).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();
    this.setState({
      expiryDate: formatedExpiryDate,
    });
  };
  handlecurrencyButton = () => {
    this.setState({
      currencyButton: !this.state.currencyButton,
    });
  };
  handleDiscountButton = () => {
    this.setState({
      discountButton: !this.state.discountButton,
    });
  };
  onSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();

    if (errors) {
      this.setState({
        errors,
      });
      let zh = Object.values(errors);
      this.danger(zh[0]);
      return;
    }

    this.setState({
      sm: true,
      smd: false,
    });
  };
  calculate = () => {
    let {
      // weeklyHours,
      resources,
      timeFrame,
      // complexity,
      expertise,
      // regions,
      // services,
      // data,
      discount,
      selectedData,
    } = this.state;

    // console.log("complexity=======", complexity)
    // console.log("expertise=======", expertise)
    // console.log("selectedData.services.monthlyPrice", selectedData.services.monthlyPrice)

    let monthlyMax = selectedData.services.monthlyPrice;
    // console.log(monthlyMax)

    let timeIndex = timeFrame * resources;
    // let rateIndex = 1;
    let rateIndex = monthlyMax * Number(expertise) / 10;
    // console.log("expertise========", expertise);
    // console.log("rateIndex========", rateIndex);
    /* if (Number(expertise) === 10) {
      rateIndex = monthlyMax * 1;
    } else if (Number(expertise) === 8.5) {
      rateIndex = monthlyMax * ;
    } else if (Number(expertise) === 7) {
      rateIndex = monthlyMax * 0.7;
    } */
    let rateOffered = rateIndex;
    // console.log("rateOffered========", rateOffered);

    if (timeIndex > 59) {
      rateOffered = rateIndex * 0.7;
    } else if (timeIndex > 23) {
      rateOffered = rateIndex * 0.8;
    } else if (timeIndex > 10) {
      rateOffered = rateIndex * 0.9;
    }
    const finalPrice =
      rateOffered *
      selectedData.regions.regionFactor *
      selectedData.currencies.currencyFactor;
    const discountPrice = (finalPrice / 100) * discount;
    const finalPriceWithDiscount = finalPrice - discountPrice;
    const totalMonthlyCost = finalPriceWithDiscount * resources;
    const totalProjectCost = finalPriceWithDiscount * resources * timeFrame;
    // console.log(finalPrice)
    this.setState({
      rateOffered: finalPrice,
      totalMonthlyCost: totalMonthlyCost,
      totalProjectCost: totalProjectCost,
      finalPriceWithDiscount,
    });
  };
  handleAdd = () => {
    const template = {
      title: this.state.projectName,
      type: "Resources",
      tags: JSON.stringify(this.state.tags),

      userId: this.state.userId,
      estimationData: JSON.stringify({
        regions: this.state.regions,
        services: this.state.services,
        timeFrame: this.state.timeFrame,
        complexity: this.state.complexity,
        expertise: this.state.expertise,
        discount: this.state.discount,
        currencies: this.state.currencies,
        selectedData: this.state.selectedData,
        resources: this.state.resources,
        projectName: this.state.projectName,
        prospectName: this.state.prospectName,
        expiryDate: this.state.expiryDate,
      }),
      token: data.currentUser.token,
    };
    let api =
      Number(data.currentUser.user_type) === 1
        ? data[data.currentUser.user_type].addTemplate
        : data[data.currentUser.user_type].addTemplate + data.currentUser.id;
    axios
      .post(api, template)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Calculation Saved!");
          this.htmlpdf();
          this.setState({
            smd: false,
            addEditLoading: false,
            // projectName: "",
            // expiryDate:'',
            // prospectName: "",

            sm: false,
          });
          // window.location.href = '#/savedcalculation'
        } else if (res.status === 201) {
          this.setState({
            regions: "",
            services: "",
            weeklyHours: "",
            timeFrame: "",
            complexity: "",
            expertise: "",
            // expiryDate:'',
            prospectName: "",
            projectName: "",
          });
          this.danger("Something Wrong, Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          regions: "",
          services: "",
          weeklyHours: "",
          timeFrame: "",
          complexity: "",
          expertise: "",
          // expiryDate:'',
          prospectName: "",
          projectName: "",
        });
        this.danger("Error From Server, Try Later!");
      });
  };
  handleUpdate = () => {
    const { id } = this.state;
    const template = {
      title: this.state.projectName,
      type: "Resources",
      status: 1,
      estimationData: JSON.stringify({
        regions: this.state.regions,
        services: this.state.services,
        weeklyHours: this.state.weeklyHours,
        timeFrame: this.state.timeFrame,
        complexity: this.state.complexity,
        expertise: this.state.expertise,
        resources: this.state.resources,
        discount: this.state.discount,
        currencies: this.state.currencies,
        selectedData: this.state.selectedData,
        projectName: this.state.projectName,
        prospectName: this.state.prospectName,
        expiryDate: this.state.expiryDate,
      }),
      token: data.currentUser.token,
    };
    let api =
      Number(data.currentUser.user_type) === 1
        ? data[data.currentUser.user_type].updateTemplate + id
        : data[data.currentUser.user_type].updateTemplate +
        data.currentUser.id +
        "/" +
        id;
    axios
      .post(api, template)
      .then((res) => {
        if (res.data.status === 200) {
          this.alert("Calculation Update!");
          window.location.href = "#/savedcalculation";
          this.handlePDF();
          this.setState({
            addEditLoading: false,
            projectName: "",
            prospectName: "",
            smd: false,
            sm: false,
            id: "",
          });
        } else if (res.status === 201) {
          this.setState({
            regions: "",
            services: "",
            weeklyHours: "",
            timeFrame: "",
            complexity: "",
            expertise: "",
            resources: "",
            projectName: "",
            prospectName: "",
            // expiryDate:'',
            sm: false,
          });
          this.danger("Something Wrong, Try Later!");
        }
      })
      .catch((error) => {
        this.setState({
          regions: "",
          services: "",
          timeFrame: "",
          complexity: "",
          expertise: "",
          discount: "",
          currencies: "",
          resources: "",
          // expiryDate:'',
          projectName: "",
          prospectName: "",
          sm: false,
        });
        this.danger("Error From Server, Try Later!");
      });
  };

  htmlpdf = () => {
    const input = document.getElementById("resourcesTemplate");
    const pdf = new jsPDF({ unit: "px", format: "a4", userUnit: "px" });

    //with inspect
    // pdf.html(input, { html2canvas: { scale: 0.66 } }).then(() => {
    //without inspect on desktop
    pdf.html(input, { html2canvas: { scale: 0.42 } }).then(() => {
      pdf.save("test.pdf");
    });
  };
  reset = () => {
    this.setState({
      services: "",
      resources: "",
      regions: "",
      timeFrame: "",
      expertise: "",
      finalPriceWithDiscount: "",
      rateOffered: "",
      discount: "",
      currencies: "",
      selectedData: {
        services: {},
        currencies: { currencyFactor: "1", currencySymbol: "$" },
        regions: {
          regionName: "Group A",
          regionFactor: "1",
          id: "89",
          status: "1",
        },
      },
    });
  };
  smdShow = () => {
    this.setState({
      smd: true,
    });
  };
  setSmdFalse = () => {
    if (this.props.match.params.id) {
      this.setState({
        smd: false,
      });
    } else {
      this.setState({
        smd: false,
        addEditLoading: false,
        projectName: "",
        // expiryDate:'',
        prospectName: "",
      });
    }
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
      // addEditLoading: false,
    });
    this.setSmdFalse();
  };
  danger = (s) => {
    Alert.error(s, 4000);
  };
  alert = (s) => {
    Alert.success(s);
  };
  handleRedirect = () => {
    window.location.href = "#/savedcalculation";
  };
  render() {
    const data = this.state.data;

    const allValue =
      this.state.services &&
      this.state.expertise &&
      this.state.timeFrame &&
      this.state.resources &&
      this.state.regions;
    const filterService = data.globalData.services.filter((ser) => {
      return Number(ser.status) === 1;
    });

    const filterCurrency = data.globalData.currencies.filter((ser) => {
      return Number(ser.status) === 1;
    });
    const filterCountries = data.globalData.countries
      ? data.globalData.countries.filter((ser) => {
        return Number(ser.status) === 1;
      })
      : {};

    return (
      <Container>
        <PdfModal
          show={this.state.sm}
          onhide={this.setSmFalse}
          prospectName={this.state.prospectName}
          todayDate={this.state.todayDate}
          projectName={this.state.projectName}
          serviceName={this.state.selectedData.services.serviceName}
          timeFrame={this.state.timeFrame}
          weeklyHours={this.state.weeklyHours}
          currencySymbol={this.state.selectedData.currencies.currencySymbol}
          finalPriceWithDiscount={this.state.finalPriceWithDiscount}
          expiryDate={this.state.expiryDate}
          id={this.state.id}
          handleAdd={this.handleAdd}
          resources={this.state.resources}
          calculationType="resources"
        />

        <Modal
          size="sm"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop: "70px",
          }}
        >
          <Modal.Header>
            <Modal.Title>Save Calculation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={this.onSubmit}>
              <Forminputtext
                label="Project Name"
                name="projectName"
                value={this.state.projectName}
                onchange={this.handleSelect}
                placeholder="Type Here"
              />
              <Forminputtext
                label="Prospect Name"
                name="prospectName"
                value={this.state.prospectName}
                onchange={this.handleSelect}
                placeholder="Type Here"
              />
              <Forminputtext
                label="Tags"
                name="tags"
                value={this.state.tags}
                onchange={this.handleSelect}
                placeholder="Enter Tags Here"
              />
              <Form.Group>
                <Form.Label>Expiry Date</Form.Label>
                <div>
                  <DatePICKER
                    value={this.state.expiryDate}
                    onChange={(date) => this.handleChange(date)}
                    minDate={new Date()}
                    maxDate={new Date(+new Date() + 12096e5)}
                  />
                </div>
              </Form.Group>

              <Button type="submit">Enter</Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Row className="border-bottom">
          <Col>
            <div className="d-flex justify-content-between align-items-center h5 my-3">
              Resources Wise Calculation
              {this.props.match.params.id ? (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={this.handleRedirect}
                >
                  {" "}
                  Back
                </Button>
              ) : (
                  ""
                )}
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col sm={12} lg={6}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted">Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.regions}
                    name="regions"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select Region</option>

                    {filterCountries.map((reg) => (
                      // <option key={reg.id} value={reg.regionId}>
                      <option
                        key={reg.id}
                        value={reg.id + "###" + reg.regionId}
                      >
                        {reg.countryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label className="text-muted"> Service *</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.services}
                    name="services"
                    onChange={this.handleSelect}
                  >
                    <option value="">Select Service</option>
                    {filterService.map((services) => (
                      <option key={services.id} value={services.id}>
                        {services.serviceName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label>
                    Time Frame in Months : <span>{this.state.timeFrame}</span>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    value={this.state.timeFrame}
                    name="timeFrame"
                    // step={3}
                    onChange={this.handleSelect}
                    min={1}
                    max={12}
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12} lg={12}>
                  <Form.Label>
                    Resources : <span>{this.state.resources}</span>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    value={this.state.resources}
                    name="resources"
                    onChange={this.handleSelect}
                    min={1}
                    max={20}
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
          <Col sm={12} lg={6} className="d-flex align-items-start">
            <CardComponent
              calculationName="resources"
              allValue={allValue}
              symbol={this.state.selectedData.currencies.currencySymbol}
              finalprice={this.state.finalPriceWithDiscount}
              totalMonthlyCost={this.state.totalMonthlyCost}
              totalProjectCost={this.state.totalProjectCost}
              discount={this.state.discount}
              weeklyhours={this.state.weeklyHours}
              timeframe={this.state.timeFrame}
              maxdiscount={this.state.selectedData.services.discount}
              services={this.state.services}
              complexity={this.state.complexity}
              filtercurrency={filterCurrency}
              handleSelect={this.handleSelect}
              resources={this.state.resources}
              smdShow={this.smdShow}
              handleDiscountButton={this.handleDiscountButton}
              handlecurrencyButton={this.handlecurrencyButton}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
ResourceWise.contextType = calculationContext;
export default ResourceWise;
