import AMW_Loader from './../images/loader.gif'
import AMW_Logo from './../images/AMW_Logo1.png'
// const url = 'http://127.0.0.1/salesestimation/';
// const url = "http://estapi.amwebtech.org/salesestimation/";

const url = "https://estapi.amwebtech.org/salesestimationlive/"

const data = {
  AMW_Loader: AMW_Loader,
  AMW_Logo: AMW_Logo,

  currentUser: {},
  globalData: {
    services: [],
    regions: [],
    currencies: [],
    countries: [],
  },
  login: url + "api/authentication/login",
  1: {
    //config data api endpoint
    getConfigData: url + "api/config/get",
    //template API endpoint
    getTemplate: url + "api/manager/template/get",
    getTemplateById: url + "api/manager/template/get/",
    addTemplate: url + "api/manager/template/add",
    updateTemplate: url + "api/manager/template/update/",
    deleteTemplate: url + "api/manager/template/delete/",

    //user API
    getUser: url + "api/manager/user/get",
    addUser: url + "api/manager/user/add",
    updateUser: url + "api/manager/user/update/",
    deleteUser: url + "api/manager/user/delete/",

    //service API
    getService: url + "api/manager/service/get",
    addService: url + "api/manager/service/add",
    updateService: url + "api/manager/service/update/",
    deleteService: url + "api/manager/service/delete/",

    //service Bundle API
    getServiceBundle: url + "api/manager/serviceBundle/get",
    addServiceBundle: url + "api/manager/serviceBundle/add",
    updateServiceBundle: url + "api/manager/serviceBundle/update/",
    deleteServiceBundle: url + "api/manager/serviceBundle/delete/",

    //service Package API
    getServicePackage: url + "api/manager/servicePackage/get",
    addServicePackage: url + "api/manager/servicePackage/add",
    updateServicePackage: url + "api/manager/servicePackage/update/",
    deleteServicePackage: url + "api/manager/servicePackage/delete/",

    //Region API Endpoint
    getRegion: url + "api/manager/region/get",
    addRegion: url + "api/manager/region/add",
    updateRegion: url + "api/manager/region/update/",
    deleteRegion: url + "api/manager/region/delete/",

    getSubRegion: url + "api/manager/subregion/get",
    addSubRegion: url + "api/manager/subregion/add",
    updateSubRegion: url + "api/manager/subregion/update/",
    deleteSubRegion: url + "api/manager/subregion/delete/",

    //currency API Endpoint
    getCurrency: url + "api/manager/currency/get",
    addCurrency: url + "api/manager/currency/add",
    updateCurrency: url + "api/manager/currency/update/",
    deleteCurrency: url + "api/manager/currency/delete/",

    //Module API Endpoint
    getServiceModule: url + "api/manager/customerService/get",
    addServiceModule: url + "api/manager/customerService/add",
    updateServiceModule: url + "api/manager/customerService/update/",
    deleteServiceModule: url + "api/manager/customerService/delete/",
  },
  2: {
    getConfigData: url + "api/config/get",
    getTemplate: url + "api/user/template/get/",
    addTemplate: url + "api/user/template/add/",
    updateTemplate: url + "api/user/template/update/",
    deleteTemplate: url + "api/user/template/delete/",

    getService: url + "api/manager/service/get",
    getRegion: url + "api/manager/region/get",
    getCurrency: url + "api/manager/currency/get",
    getServicePackage: url + "api/manager/servicePackage/get",
    getServiceBundle: url + "api/manager/serviceBundle/get",
    getServiceModule: url + "api/manager/customerService/get",

    getSubRegion: url + "api/manager/subregion/get",
  },
  user: {
    GetService: url + "api/user/getService",
  },
  url: '/',
  sidebar: {
    user: 1,
    region: 1,
    subRegion: 1,
    currency: 1,
    services: 1,
    bundles: 1,
    packages: 1,
    modules: 1
  }
};

export default data;