import React, { Component } from "react";
import axios from "axios";
// importing Component
import Head from "./Header";
import table from "../common/table";
import Hourly from "../pages/user-pages/estimation/HourlyWise";
import PackageWise from "../pages/user-pages/estimation/PackageWise";
import Bundle from "../pages/user-pages/estimation/bundle";
import ServiceWise from "../pages/user-pages/estimation/ServiceWise";
import Resource from "../pages/user-pages/estimation/ResourceWise";
import Login from "../pages/login/login";
import Logout from "../pages/login/Logout";
import Side from "./Sidebar";
//manager components
import Currency from "../../components/pages/manager/Currency";
import CSM from "../../components/pages/manager/customerServiceModule";
import Packages from "../../components/pages/manager/Packages";
import MSB from "../../components/pages/manager/manageServiceBundle";
import MSP from "../../components/pages/manager/manageServicePackage";
import Services from "../pages/manager/manageServices";
import Region from "../../components/pages/manager/region";
import SubRegion from "../../components/pages/manager/subregion";
import Users from "../pages/manager/users";
import SavedCal from "../pages/manager/savedCalculation";
import Footerr from "./Footer";
import { Alert } from "rsuite";
import { Route, HashRouter, Redirect, Switch } from "react-router-dom";
// import Switch from 'react-bootstrap/esm/Switch';
import Dashboard from "./Dashboard";
import config from "../../env/config";
import calculationContext from "../context/calculationContext";
import DataContext from "../context/dataContext";
import { Container, Row, Col, Button } from "react-bootstrap";

const Page404 = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col lg="6">
          <h1 className="m-3">
            404
            <br />
            Page Not Found!
          </h1>
          <Button href="/" className="m-3">
            Back to home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

class Home extends Component {
  state = {
    expanded: true,
  };
  componentDidMount = async () => {
    await axios
      .post(config[config.currentUser.user_type].getConfigData, {
        token: config.currentUser.token,
      })
      .then((res) => {
        // let array = [];
        if (res.status === 200) {
          let data = res.data.data;
          data = JSON.parse(data);
          // data =JSON.parse(JSON.stringify(data))
          for (var k in data) config[k] = data[k];
        }
      })
      .catch((error) => {
        this.handleLogout();
      });
    await axios
      .post(config[config.currentUser.user_type].getService, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        config.globalData.services = array;
      })
      .catch((error) => {
        // console.log(error)
        this.handleLogout();
      });

    await axios
      .post(config[config.currentUser.user_type].getRegion, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res.data.status === 200) {
          array = res.data.data;
        }
        config.globalData.regions = array;
      })
      .catch((error) => {
        // console.log(error)
      });

    await axios
      .post(config[config.currentUser.user_type].getCurrency, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res.data.status === 200) {
          array = res.data.data;
        }
        config.globalData.currencies = array;
      })
      .catch((error) => {
        // console.log(error)
      });

    await axios
      .post(config[config.currentUser.user_type].getSubRegion, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res.data.status === 200) {
          array = res.data.data;
        }
        config.globalData.countries = array;
      })
      .catch((error) => {
        // console.log(error)
      });

    let a = localStorage.getItem("currentUser");
    let b = JSON.parse(a);
    this.setState({ data: b });
  };
  handleLogout = () => {
    localStorage.removeItem("currentUser");
    this.context.handleLoginChange(false);
    window.location.href = config.url + "#/login";
    // this.alert('Please Login Again!')
  };
  handleDataChange = (e) => {
    this.setState({
      data: e,
    });
  };
  alert = (s) => {
    Alert.success(s);
  };
  changeStyle = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };
  render() {
    return (
      <calculationContext.Provider
        value={{ data: this.state, handleDataChange: this.handleDataChange }}
      >
        <HashRouter>
          <Head />
          <div className="d-flex vh-120" style={{ fontFamily: "Montserrat" }}>
            <Side updateStyle={this.changeStyle} />
            <div
              className={
                this.state.expanded
                  ? "content-responsive custom-control"
                  : "content-responsive-1 custom-control"
              }
              // className="custom-control"
              style={{ padding: 0 }}
            >
              <Switch>
                <Route exact path={"/hourly"} component={Hourly} />
                <Route exact path={"/bundle"} component={Bundle} />
                <Route exact path={"/hourly/:id"} component={Hourly} />
                <Route exact path={"/bundle/:id"} component={Bundle} />
                <Route exact path={"/resources"} component={Resource} />
                <Route exact path={"/resources/:id"} component={Resource} />
                <Route exact path={"/packagewise"} component={PackageWise} />

                <Route path={"/servicewise"} component={ServiceWise} />
                <Route exact path={"/"} component={Dashboard} />
                <Route path={"/home"} component={Dashboard} />
                <Route path={"/login"}>
                  <Login />
                </Route>

                <Route path={"/currency"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <Currency />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/services"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <Services />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/region"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <Region />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/subregion"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <SubRegion />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>

                <Route path={"/users"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <Users />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/servicemodule"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <CSM />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/servicebundle"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <MSB />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/servicepackage"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <MSP />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/package"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <Packages />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>
                <Route path={"/package/:id"}>
                  {Number(config.currentUser.user_type) === 1 ? (
                    <Packages />
                  ) : (
                      <Redirect to="/" />
                    )}
                </Route>

                <Route path={"/savedcalculation"} component={SavedCal}></Route>
                <Route path={"/table"} component={table}></Route>
                <Route exact path="*" component={Page404} />

                <Route path={"/logout"}>
                  <Logout />
                </Route>
              </Switch>
            </div>
          </div>
          <Footerr />
        </HashRouter>
      </calculationContext.Provider>
    );
  }
}
Home.contextType = DataContext;
export default Home;
