import React, { Component } from "react";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import DataContext from "./../../context/dataContext";
import config from "../../../env/config";
import { Alert } from "rsuite";

class Login extends Component {
  state = {
    loginLoading: false,
  };

  constructor() {
    super();
    if (
      window.location.href.includes("login") &&
      localStorage.getItem("currentUser")
    ) {
      window.location.href = config.url + "#/home";
    }
  }

  responseGoogle = (response) => {
    // console.log("emailAddress= ========", this.state.loginLoading);
    if (response.profileObj) {
      // console.log("response============", response)
      this.setState({ loginLoading: true });

      let emailAddress = response.profileObj.email;

      setTimeout(() => {
        axios
          .post(config.login, { email: emailAddress })
          .then((res) => {
            // console.log(res);
            this.setState({ loginLoading: false });
            if (res.data.status === 200) {
              console.log(res.data);
              res.data.profileObj = response.profileObj;
              let user = { ...res.data.data, ...response.profileObj };
              localStorage.setItem("currentUser", JSON.stringify(user));
              config.currentUser = user;
              // console.log(this.context);
              this.context.handleLoginChange(true);
              this.context.handleDataChange(user);
              console.log(this.context.data)
              this.alert("Succesfully Logged In");
              //  window.location.href='/#/home';
            } else if (res.data.status === 201) {
              this.danger(res.data.message);
            } else {
              this.danger("There is having some problem in Login In!");
              // console.log('login error from login componnent',)//use toast message here #
            }

            // window.location.href='/#/home'
          })
          .catch((error) => {
            this.setState({ loginLoading: false });
            console.log(error);
            // console.log(error.message)//use toast message here #
            this.danger(
              "We don't have your google cloud login information in our system"
            );
          });
      }, 3200);
    }
  };

  danger = (s) => {
    Alert.warning(s, 4000);
  };
  alert = (s) => {
    Alert.success(s);
  };

  render() {
    return (
      <div className="col-md-4 d-block mx-auto my-auto pt-5">
        <div
          className="card custom-card mt-5 p-5"
          style={{ borderTop: "5px solid #4285f4" }}
        >
          {this.state.loginLoading ? (
            ""
          ) : (
            <div className="text-center p-3">
              <img
                style={{ width: "12rem" }}
                src={config.AMW_Logo}
                className="img-responsive"
                alt="logo"
              />
              <h5
                className="pt-3"
                style={{
                  textAlign: "center",
                  color: "grey",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                Estimation Calculator, From AM Webtech Pvt. Ltd.{" "}
              </h5>
            </div>
          )}
          <div className="card-body p-3 text-center">
            {this.state.loginLoading ? (
              <img style={{ width: "150px" }} src={config.AMW_Loader} alt="loader" />
            ) : (
              <GoogleLogin
                clientId="1051803384347-91967jipju1ume5ul4dcr5g84bfaja56.apps.googleusercontent.com"
                buttonText="CONTINUE WITH GOOGLE"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
Login.contextType = DataContext;
export default Login;
