import React, { Component } from "react";
import Data from "../../env/config";
import { Sidenav, Nav, Icon, Dropdown } from "rsuite";
import DataContext from "../context/dataContext";
import {
  FaUsers,
  FaSave,
  FaMapMarkerAlt,
  FaHtml5,
  FaTh,
  FaBoxes,
  FaUserTie,
  FaClock,
} from "react-icons/fa";
import { GoPackage } from "react-icons/go";
import { HiCurrencyDollar } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiPinDistanceFill } from "react-icons/ri";
import config from "../../env/config";

class Sidebar extends Component {
  state = {
    activeKey: "1",
    userType: this.context.data.data ? this.context.data.data.user_type : "",
    expanded: true,
  };
  managerStyle = { display: "" };
  userStyle = { display: "none" };
  sidebarStyle = {};
  handleSelect = (eventKey) => {
    this.setState({
      activeKey: eventKey,
    });
  };
  componentDidMount = () => {
    // let usertype = this.context.data.data.user_type
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({
      userType: Number(currentUser.user_type),
    });
  };
  handleToggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
    this.props.updateStyle();
  };

  render() {
    return (
      <div className="sidebar">
        <Sidenav
          expanded={this.state.expanded}
          activeKey={this.state.activeKey}
          onSelect={this.handleSelect}
        >
          <Sidenav.Body>
            <div className="tooggleNav">
              <button
                className="sidebarCollapse"
                eventKey="1"
                onClick={this.handleToggle}
              >
                <GiHamburgerMenu />
                <span>Menu</span>
              </button>
            </div>
            <Nav>
              <Dropdown
                eventKey="2"
                title="Manager"
                icon={<Icon icon="gear-circle" />}
                style={
                  this.state.userType === 1 ? this.managerStyle : this.userStyle
                }
              >
                <Dropdown.Item
                  className={config.sidebar.user === 1 ? "" : "d-none"}
                  eventKey="2-1"
                  href={Data.url + "#/users"}
                >
                  {" "}
                  <FaUsers className="mr-2" /> Users
                </Dropdown.Item>
                <Dropdown.Item
                  className={config.sidebar.region === 1 ? "" : "d-none"}
                  eventKey="2-2"
                  href={Data.url + "#/region"}
                >
                  {" "}
                  <FaMapMarkerAlt className="mr-2" /> Region
                </Dropdown.Item>
                <Dropdown.Item
                  className={config.sidebar.subRegion === 1 ? "" : "d-none"}
                  eventKey="2-3"
                  href={Data.url + "#/subregion"}
                >
                  {" "}
                  <RiPinDistanceFill className="mr-2" /> Sub Region
                </Dropdown.Item>
                <Dropdown.Item
                  className={config.sidebar.currency === 1 ? "" : "d-none"}
                  eventKey="2-4"
                  href={Data.url + "#/currency"}
                >
                  {" "}
                  <HiCurrencyDollar className="mr-2" /> Currency
                </Dropdown.Item>

                <Dropdown.Item
                  className={config.sidebar.services === 1 ? "" : "d-none"}
                  eventKey="2-5"
                  href={Data.url + "#/services"}
                >
                  {" "}
                  <FaHtml5 className="mr-2" /> Services
                </Dropdown.Item>
                <Dropdown.Item
                  className={config.sidebar.bundles === 1 ? "" : "d-none"}
                  eventKey="2-6"
                  href={Data.url + "#/servicebundle"}
                >
                  <FaTh className="mr-2" /> Service Bundles
                </Dropdown.Item>
                <Dropdown.Item
                  className={
                    Number(config.sidebar.modules) === 1 ? "" : "d-none"
                  }
                  eventKey="2-9"
                  href={Data.url + "#/servicemodule"}
                >
                  {" "}
                  <FaBoxes className="mr-2" /> Service Modules
                </Dropdown.Item>
                <Dropdown.Item
                  className={config.sidebar.packages === 1 ? "" : "d-none"}
                  eventKey="2-7"
                  href={Data.url + "#/servicepackage"}
                >
                  {" "}
                  <GoPackage className="mr-2" /> Service Packages
                </Dropdown.Item>
                {/* <Dropdown.Item
                  eventKey="2-8"
                  href={Data.url + "#/package-creator"}
                >
                  {" "}
                  <FaCheckSquare className="mr-2" /> Package Creator
                </Dropdown.Item> */}
              </Dropdown>

              <Dropdown
                eventKey="3"
                title="Estimator"
                icon={<Icon icon="calculator" />}
              >
                <Dropdown.Item
                  href={Data.url + "#/savedcalculation"}
                  eventKey="3-1"
                >
                  {" "}
                  <FaSave className="mr-2" />
                  Saved Proposal
                </Dropdown.Item>
                <Dropdown.Item href={Data.url + "#/hourly"} eventKey="3-2">
                  <FaClock className="mr-2" /> Hourly
                </Dropdown.Item>
                <Dropdown.Item href={Data.url + "#/resources"} eventKey="3-3">
                  <FaUserTie className="mr-2" /> Resources
                </Dropdown.Item>
                <Dropdown.Item href={Data.url + "#/bundle"} eventKey="3-4">
                  <FaTh className="mr-2" /> Sevices
                </Dropdown.Item>
                <Dropdown.Item href={Data.url + '#/servicewise'} eventKey="3-3"><GoPackage className="mr-2" /> Service Modules</Dropdown.Item>
                <Dropdown.Item href={Data.url + "#/packagewise"} eventKey="3-5">
                  <GoPackage className="mr-2" /> Package
                </Dropdown.Item>
              </Dropdown>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    );
  }
}
Sidebar.contextType = DataContext;
export default Sidebar;
