import React, { Component } from 'react';



class Logout extends Component {
    state = { 
        isLoggedIn:true
     }

    componentDidMount =() =>{
        this.handleLogout()
    }
    handleLogout=()=>{
        localStorage.setItem('currentUser','');
        this.setState({
            isLoggedIn:false
        })
        // setTimeout(function () {
            window.location.href = "/"; //will redirect to your blog page (an ex: blog.html)
        //  }, 2000);
        
       
          

        
    }
    
    render() { 
      
        return (
        <div style={{
            textAlign:"center",
            padding:100
        }}>
            <h2> LogOut!!</h2>
            <button className='btn btn-danger m-4' onClick={this.handleLogout}>Logout</button>
        </div>
        )
    }
    }
export default Logout;