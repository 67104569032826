import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import { Table, Button, Form, Col, Row ,Container} from "react-bootstrap";
import config from "../../../env/config";
import {FaPlus,FaRegEdit,FaRegTrashAlt} from 'react-icons/fa'
import { Modal, Loader ,Tooltip, Whisper,Toggle,Alert} from "rsuite";
import {matchSorter} from 'match-sorter'

class Tab extends Component {
  state = {
    addEditLoading: false,
    tableMessage: "No Items, Please Add One",
    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    serviceList: [],
    sm: false,
    smd: false,
    loader: true,

    moduleName: "",
    timeRequired: "",
    complexity: "",
    summary: "",

    edit: 0,
    errorlen: 1,
    errors: {
      moduleName: "",
      timeRequired: "",
      complexity: "",
      summary: "",
    },
  };
  schema = {
    moduleName: Joi.string().required().label('Module Name'),
    timeRequired: Joi.number().required().label('Time Required'),
    // complexity: Joi.number().required().label('Complexity'),
    summary: Joi.string().required().label('Summary'),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      moduleName: this.state.moduleName,
      timeRequired: this.state.timeRequired,
      // complexity: this.state.complexity,
      summary: this.state.summary,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleChange = async({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";

    const errorlen = errorMessage;
    if (input.name === "moduleName") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
      this.checkAlreadyExitSerivcePackages();
    } else {
      this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }
  };

  handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
        [input.name]: input.value,
      });
    this.handleFilter(this.state.searchString)
  };

  handleFilter = (string) =>{
      const dataForFilter = this.state.dataForFilter
      let z = matchSorter(dataForFilter, string, {keys: ['moduleName']})
      this.setState({
          data:z,page:1
      })
  }
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.data.length) {
      if (!this.state.id) {
        let existModule = await this.state.data.filter(
          (pack) => {
            return this.state.id !== pack.id &&
            pack.moduleName.toLowerCase() ===
              this.state.moduleName.toLowerCase()
          } );
          if (existModule.length)   {
            this.danger("already Exist package");
            this.setState({
              checkAlreadyExit: true,
            });         
          } else {
            this.setState({
              checkAlreadyExit: false,
            });  
          }
      } else {
        let existModule = await this.state.data.filter(
          (pack) => {
           return pack.moduleName.toLowerCase() ===
            this.state.moduleName.toLowerCase()
          });
          if (existModule.length)   {
            this.danger("already Exist package");
            this.setState({
              checkAlreadyExit: true,
            });         
          } else {
              this.setState({
                checkAlreadyExit: false,
              });  
          }
      }      
    }
  };

  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };
  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},
    });
  };
  setSmFalse = () => {
    this.setState({
      sm: false,
      moduleName: "",
      timeRequired: "",
      complexity: "",
      summary: "",
      id: "",
      errors: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };
  setSmdFalse = () => {
    this.setState({
      smd: false,
      errors: {},
      moduleName: "",
      timeRequired: "",
      complexity: "",
      summary: "",
      id: "",
      addEditLoading: false,
      checkAlreadyExit: false,
    });
  };
  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getServiceModule, {
        token: config.currentUser.token,
      })
      .then((res) => {
        let array = [];
        if (res && res.data.data.length && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          data: array,
          dataForFilter:array,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();
    console.log('errors======', errors)
    console.log("========", {
      moduleName: this.state.moduleName,
      timeRequired: this.state.timeRequired,
      complexity: this.state.complexity,
      summary: this.state.summary,
      token: config.currentUser.token,
    })
    if (errors) {
      this.setState({
        errors,
      });
      return;
    } else if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Module");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    // const existModule = this.state.data.filter(service=>
    //   service.moduleName === this.state.moduleName
    //   )
    // if(existModule.length){
    //   this.danger('Thi Service Model Already Exist!')
    //   this.setState({
    //     moduleName: "",
    //     timeRequired: "",
    //     complexity:"",
    //     summary:"",
    //     id: "",
    //     sm: false,
    //     edit: 1,
    //     addEditLoading: false,
    //   });
    // }else{
      axios
      .post(config[config.currentUser.user_type].addServiceModule, {
        moduleName: this.state.moduleName,
        timeRequired: this.state.timeRequired,
        complexity: 'High',
        summary: this.state.summary,
        token: config.currentUser.token,
      })
      .then((res) => {
        if (res.data.status === 200) {
          // console.log(res.status,res.message)
          this.alert("Successfully Added!");
          // this.componentDidMount();
          let resModule = res.data.data;

          this.setState({
            data: [...this.state.data, resModule],
            moduleName: "",
            timeRequired: "",
            complexity: "",
            summary: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
//  }
  };
  handleEdit = (data) => {
    // console.log(data, "handle edit ------");

    this.setState({
      edit: 1,
      moduleName: data.moduleName,
      timeRequired: data.timeRequired,
      complexity: data.complexity,
      summary: data.summary,
      id: data.id,
      sm: true,
      status:data.status
    });
  };
  onUpdate = (event) => {
    if(event){
      event.preventDefault();
    }
    const errors = this.validate();
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }else if (this.state.checkAlreadyExit) {
      this.danger("Already Exist Module");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    const {
      moduleName,
      timeRequired,
      complexity,
      summary,
      id,
      status
    } = this.state;
    const serviceModule = {
      moduleName,
      timeRequired,
      complexity: 'high',
      summary,
      status,
      token: config.currentUser.token,
    };

    axios
      .post(
        config[config.currentUser.user_type].updateServiceModule + id,
        serviceModule
      )
      .then((res) => {
        // console.log(res)
        if (res.data.status === 200) {
          // console.log(res);
          this.alert("Successfully Updated!");
          // this.componentDidMount();
          let resServiceModuleUpd = res.data.data;

          let found = this.state.data.filter(
            (arr) => arr.id === resServiceModuleUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resServiceModuleUpd;
            let data = [...this.state.data]
            data[index] = resServiceModuleUpd;
            this.setState({data});
            
          }
          this.setState({
            sm: false,
            // loader:false,
            moduleName: "",
            timeRequired: "",
            complexity: "",
            summary: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }else(
          this.danger('Please Try Later!')
        )
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const { id } = this.state;
    this.setState({
      addEditLoading: true,
    });
    const serviceModule = {
      token: config.currentUser.token,
    };

    axios
      .post(
        config[config.currentUser.user_type].deleteServiceModule + id,
        serviceModule
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);
          if (found.length) {

            let page = ''
            if(found.length % this.state.displayLength){
              page =this.state.page
            }else{
              page = Math.ceil(found.length  / this.state.displayLength)
            }
            this.setState({
              data: found,
              page
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            moduleName: "",
            timeRequired: "",
            complexity: "",
            summary: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  handleActiveInactive = async (data)=>{
    await this.setState({
       edit: 1,
       moduleName: data.moduleName,
       timeRequired:data.timeRequired,
       complexity:data.complexity,
       summary:data.summary,
       status: Number(data.status) === 1 ? 2 : 1 ,
       id: data.id,
      //  loader:true
       
     });
     this.onUpdate();
 
   
     };
  danger = (s) => {
    Alert.warning(s,4000)
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s)
    // Notification.success({
    //   title: s,
    //   // description:s
    // });
  };

  handleComplexity = (exp) => {
    if (config.complexity && config.complexity.length) {
      let z = config.complexity.filter(x=>(
         Number(x.value) === Number(exp)
      ))
      if (z.length) {
        return z[0].label
      }
    }
   }
  render() {
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );
    let moduleNameErrorLen = this.state.errors.moduleName
      ? this.state.errors.moduleName.length
      : 0;

    let timeRequiredErrorLen = this.state.errors.timeRequired
      ? this.state.errors.timeRequired.length
      : 0;
    let complexityErrorLen = this.state.errors.complexity
      ? this.state.errors.complexity.length
      : 0;
    let summaryErrorLen = this.state.errors.summary
      ? this.state.errors.summary.length
      : 0;

    return (
      <div>
       
        <Modal size='xs' backdrop={false} show={this.state.sm} onHide={this.setSmFalse}>
          <Modal.Header>
            <Modal.Title>{this.state.id ? "Update Module" : "Add Module"}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}>
              <Form.Group>
                <Form.Label>Service Module</Form.Label>
                <Form.Control
                  name="moduleName"
                  value={this.state.moduleName}
                  onChange={this.handleChange}
                  placeholder="Enter Service Module"
                />
                <div
                  className={
                    moduleNameErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.moduleName}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Time Required in Hrs</Form.Label>
                <Form.Control
                  name="timeRequired"
                  type='number'
                  value={this.state.timeRequired}
                  onChange={this.handleChange}
                  placeholder="Enter Required Time"
                />
                <div
                  className={
                    timeRequiredErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.timeRequired}
                </div>
              </Form.Group>

              
{/* 
              <Form.Group>
                <Form.Label>Complexity</Form.Label>
                <Form.Control
                    as='select'
                    name="complexity"
                  value={this.state.complexity}
                  onChange={this.handleChange}
                  >
                  <option value="">Choose Complexity</option>
                  {config.complexity && config.complexity.length ?  config.complexity.map(com=>(
                    <option key={com.value} value = {com.value}>{com.label}</option>
                  )) : ''}
                  </Form.Control>
                  <div
                  className={
                    complexityErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.complexity}
                </div>
              </Form.Group> */}




              <Form.Group>
                <Form.Label>Summary</Form.Label>
                <Form.Control
                  name="summary"
                  as="textarea" rows={6}
                  value={this.state.summary}
                  onChange={this.handleChange}
                  placeholder="Enter Summary"
                />
                <div
                  className={
                    summaryErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.summary}
                </div>
              </Form.Group>

              <Button disabled={this.state.errorlen} type="submit">
                {this.state.id ? "Update" : "Add"}
              </Button>
              {this.state.addEditLoading ? (
                <Loader
                  size="lg"
                  color="#4285f4"
                  center
                  backdrop
                  content="Processing Please Wait."
                />
              ) : (
                ""
              )}
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop:'100px'
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="lg"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>
    <Container fluid>
      <Row>
      <Col className="">
      <div className=''>
      <div className="my-3 d-flex flex-row justify-content-between h4">Manage Service Modules
          
      <div className="d-flex">
        <Form.Group className="mb-0" >
          <Form.Control placeholder='Search Here.' 
            name='searchString' 
            value ={this.state.searchString} 
            onChange={this.handleSelect}
          />
        </Form.Group>
        <Button className=" btn-sm ml-3" onClick={() => this.setSmTrue()}>
        <FaPlus className='mr-2 mb-1'/>
          Add New
        </Button>
        </div>

          
          </div>
          <div className="my-custom-scrollbar"> 
        <Table striped bordered hover responsive="lg" className="mb-0">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Service Module</th>
              <th>Required(Hrs.)</th>
              {/* <th>Complexity</th> */}
              <th>Summary</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          {this.state.loader ? (
            <Loader size="lg" center  backdrop content="Loading Please Wait.." />
          ) : (
            <tbody>
              {filterData.map((data,index) => (
                <tr key={data.id}>
                  <td>{this.state.displayLength *(this.state.page -1)+ index+1}</td>
                  <td>{data.moduleName}</td>
                  <td>{data.timeRequired}</td>
                  {/* <td>{Number(data.complexity)===3?"High":Number(data.complexity)===7?"Medium":Number(data.complexity)===10?"Low":"No complexity"}</td> */}
                  {/* <td>{this.handleComplexity(data.complexity)}</td> */}
                  
                  <td>{data.summary}</td>
                  <td>
                  <Toggle 
                  name='status'
                  size="md" 
                  checkedChildren="Active" 
                  unCheckedChildren="Inactive"
                  defaultChecked={Number(data.status) === 1 ? true : false}
                   onChange={()=>this.handleActiveInactive(data)}/>
                   
                  </td>
                  <td>
                  <div className="d-flex">
                  <Whisper
                      trigger="hover"
                      placement='bottom'
                      speaker={
                        <Tooltip>
                          Edit
                        </Tooltip>
                      }>
                      <button
                      onClick={() => {
                        this.handleEdit(data);
                      }}
                      className="btn btn-primary btn-sm"
                      style={{margin:'1px'}}
                    >
                      <FaRegEdit/>
                    </button>
                  </Whisper>
                    
                    
                    <Whisper
                      trigger="hover"
                      placement='bottom'
                      speaker={
                        <Tooltip>
                          Delete
                        </Tooltip>
                      }
                    >
                      {/* <Button appearance="subtle">click</Button> */}
                      <button
                      onClick={() => this.handleRemove(data)}
                      className="btn btn-danger btn-sm"
                      style={{margin:'1px'}}
                    >
                      <FaRegTrashAlt/>
                    </button>
                    </Whisper>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
       </div>
        {filterData.length === 0 ? (
          <div
            style={{
              fontSize: "24px",
              textAlign: "center",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.loader?'':this.state.tableMessage}
          </div>
        ) : (
          ""
        )}
        {filterData.length === 0 ? (
          ""
        ) : (
          <Pagination
            itemsCount={this.state.data.length}
            pageSize={this.state.displayLength}
            currentPage={this.state.page}
            onPageChange={this.handleChangePage}
          />
        )}
         </div>
        </Col>
          </Row>
          </Container>
      </div>
    );
  }
}

export default Tab;
