import React, { Component } from "react";
import { Modal } from "rsuite";
import { Button } from "react-bootstrap";
import data from "./../../env/config";
import headerImage from "./../../images/header.png";
import footerImage from "./../../images/footer.png";

class PdfModule extends Component {
  state = {};

  render() {
    return (
      <>
        <Modal
          full
          backdrop={true}
          // show={true}
          show={this.props.show}
          onHide={this.props.onhide}
          className=""
        >
          <Modal.Body>
            {this.props.calculationType === "hourly" && (
              <div
                id="hourlyTemplate"
                style={{
                  minHeight: "1500px",
                  width: "1070px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <div>
                  <img
                    src={headerImage}
                    style={{ width: "100%" }}
                    alt="header"
                  />
                  <div
                    className="responsive-mobile"
                    style={{
                      marginLeft: "120px",
                      marginRight: "120px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                    }}
                  >
                    <h3 className="d-flex justify-content-center">
                      Estimation for Project
                    </h3>

                    <div className="d-flex justify-content-between mt-5 h5 mobile-align">
                      <div>
                        <b>Dear</b> <b>{this.props.prospectName}</b>,
                      </div>

                      <div>
                        <b> Issued Date : {this.props.todayDate}</b>
                      </div>
                    </div>
                    <br />

                    <div className="text-justify h5">
                      Thank you for considering the collaboration with AM
                      Webtech for the Project <b>"{this.props.projectName}"</b>.
                      We ensure the accomplishment of this project fitting the
                      times. AM Webtech has a record of identifying key Business
                      objectives, client requisite, and attaining the objective.
                    </div>

                    <br />
                    <div className="h5">
                      <b>Contract Details </b>: This contract is made for hiring
                      of <b>{this.props.serviceName} </b>
                      service from AM Webtech for a period of
                      <b>
                        {" "}
                        {this.props.timeFrame}{" "}
                        {this.props.timeFrame === 1 ? "Week" : "Weeks"}{" "}
                      </b>
                      and the total time frame is{" "}
                      <b>{this.props.timeFrame * this.props.weeklyHours}</b>{" "}
                      hours. The Hourly rate offered for this contract is{" "}
                      <b>
                        {this.props.currencySymbol + " "}
                        {(
                          Math.round(this.props.finalPriceWithDiscount * 100) /
                          100
                        ).toFixed(2)}
                      </b>
                      .
                    </div>
                    <br />
                    <div className="h5">
                      The following proposal lays out the work details including
                      service charges. If you have questions, please feel free
                      to reach out via email or phone.
                    </div>
                    <div className="d-flex justify-content-center my-5 h5">
                      {" "}
                      Thank you!
                    </div>

                    <div className="mt-5 d-flex h5 justify-content-between mobile-align">
                      <div>
                        <b> Expiry Date : {this.props.expiryDate}</b>
                      </div>
                      <div>
                        {data.currentUser.name} <br />
                        AM Webtech Pvt. Ltd <br />
                        {data.currentUser.email}
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  src={footerImage}
                  style={{
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  alt="footer"
                />
              </div>
            )}
            {this.props.calculationType === "resources" && (
              <div
                id="resourcesTemplate"
                style={{
                  minHeight: "1500px",
                  width: "1070px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <div>
                  <img alt="logo" src={headerImage} style={{ width: "100%" }} />

                  <div
                    className=" h5 responsive-mobile"
                    style={{
                      marginLeft: "120px",
                      marginRight: "120px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                    }}
                  >
                    <h3 className="d-flex justify-content-center">
                      Estimation for Project
                    </h3>
                    <div className="d-flex justify-content-between mt-5 h5 mobile-align">
                      <div>
                        <b>Dear</b> <b>{this.props.prospectName}</b>,
                      </div>
                      <div>
                        <b> Issued Date : {this.props.todayDate}</b>
                      </div>
                    </div>
                    <br />

                    <div className="text-justify h5">
                      Thank you for considering the collaboration with AM
                      Webtech for the Project
                      <b>"{this.props.projectName}"</b>. We ensure the
                      accomplishment of this project fitting the times. AM
                      Webtech has a record of identifying key Business
                      objectives, client requisite, and attaining the objective.
                    </div>
                    <br />
                    <div className="h5">
                      <b>Contract Details </b>: This contract is made for hiring
                      of <b> {this.props.serviceName} </b>
                      service from AM Webtech for a period of{" "}
                      <b>
                        {" "}
                        {this.props.timeFrame}
                        {this.props.timeFrame === 1 ? " Month" : " Months"}{" "}
                      </b>
                      and the total selected resources are{" "}
                      <b>{this.props.resources} </b> in Number. The monthly rate
                      offered for this contract is{" "}
                      <b>
                        {" "}
                        {this.props.currencySymbol + " "}
                        {(
                          Math.round(this.props.finalPriceWithDiscount * 100) /
                          100
                        ).toFixed(2)}
                      </b>
                      / resource.
                    </div>
                    <br />
                    <div className="h5">
                      The following proposal lays out the work details including
                      service charges. If you have questions, please feel free
                      to reach out via email or phone.
                    </div>
                    <div className="d-flex justify-content-center my-5 h5">
                      {" "}
                      Thank you!
                    </div>

                    <div className="mt-5 d-flex h5 justify-content-between mobile-align">
                      <div>
                        <b>Expiry Date : {this.props.expiryDate}</b>
                      </div>
                      <div>
                        {data.currentUser.name} <br />
                        AM Webtech Pvt. Ltd <br />
                        {data.currentUser.email}
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  alt="logo"
                  src={footerImage}
                  style={{
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              </div>
            )}
            {this.props.calculationType === "bundle" && (
              <div
                id="bundleTemplate"
                style={{
                  minHeight: "1500px",
                  width: "1070px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <div>
                  <img
                    src={headerImage}
                    style={{ width: "100%" }}
                    alt="header"
                  />
                  <div
                    className="responsive-mobile"
                    style={{
                      marginLeft: "120px",
                      marginRight: "120px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                    }}
                  >
                    <h3 className="d-flex justify-content-center">
                      Estimation for Project
                    </h3>

                    <div className="d-flex justify-content-between mt-5 h5 mobile-align">
                      <div>
                        <b>Dear</b> <b>{this.props.prospectName}</b>,
                      </div>

                      <div>
                        <b> Issued Date : {this.props.todayDate}</b>
                      </div>
                    </div>
                    <br />

                    <div className="text-justify h5">
                      Thank you for considering the collaboration with AM
                      Webtech for the Project <b>"{this.props.projectName}"</b>.
                      We ensure the accomplishment of this project fitting the
                      times. AM Webtech has a record of identifying key Business
                      objectives, client requisite, and attaining the objective.
                    </div>
                    <br />
                    <div className="h5">
                      <b>Contract Details </b>: This contract is made for hiring
                      of <b>{this.props.serviceName} </b>
                      Service Bundles from AM Webtech for a period of
                      <b>
                        {" "}
                        {this.props.timeFrame}{" "}
                        {this.props.timeFrame === 1 ? "Month." : "Months."}{" "}
                      </b>
                      The Final rate offered for this contract is{" "}
                      <b>
                        {this.props.currencySymbol + " "}
                        {(
                          Math.round(this.props.finalPriceWithDiscount * 100) /
                          100
                        ).toFixed(2)}
                      </b>
                    </div>
                    <br />
                    <div className="h5">
                      The following proposal lays out the work details including
                      service charges. If you have questions, please feel free
                      to reach out via email or phone.
                    </div>
                    <div className="d-flex justify-content-center my-5 h5">
                      {" "}
                      Thank you!
                    </div>

                    <div className="mt-5 d-flex h5 justify-content-between mobile-align">
                      <div>
                        <b> Expiry Date : {this.props.expiryDate}</b>
                      </div>
                      <div>
                        {data.currentUser.name} <br />
                        AM Webtech Pvt. Ltd <br />
                        {data.currentUser.email}
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  src={footerImage}
                  style={{
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  alt="footer"
                />
              </div>
            )}
            {this.props.calculationType === "packages" && (
              <div
                id="packageTemplate"
                style={{
                  minHeight: "1500px",
                  width: "1070px",
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                <div>
                  <img
                    src={headerImage}
                    style={{ width: "100%" }}
                    alt="header"
                  />
                  <div
                    className="responsive-mobile"
                    style={{
                      marginLeft: "120px",
                      marginRight: "120px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                    }}
                  >
                    <h3 className="d-flex justify-content-center">
                      Estimation for Project
                    </h3>

                    <div className="d-flex justify-content-between mt-5 h5 mobile-align">
                      <div>
                        <b>Dear</b> <b>{this.props.prospectName}</b>,
                      </div>
                      <div>
                        <b> Issued Date : {this.props.todayDate}</b>
                      </div>
                    </div>
                    <br />
                    <div className="text-justify h5">
                      Thank you for considering the collaboration with AM
                      Webtech for the Project <b>"{this.props.projectName}"</b>.
                      We ensure the accomplishment of this project fitting the
                      times. AM Webtech has a record of identifying key Business
                      objectives, client requisite, and attaining the objective.
                    </div>
                    <br />
                    <div className="h5">
                      <b>Contract Details </b>: This contract is made for hiring
                      of <b>{this.props.serviceName} </b>
                      Service Bundle Packages from AM Webtech for a period of
                      <b>
                        {" "}
                        {this.props.timeFrame}{" "}
                        {this.props.timeFrame === 1 ? "Month." : "Months."}{" "}
                      </b>
                      The Final rate offered for this contract is{" "}
                      <b>
                        {this.props.currencySymbol + " "}
                        {(
                          Math.round(this.props.finalPriceWithDiscount * 100) /
                          100
                        ).toFixed(2)}
                      </b>
                    </div>
                    <br />
                    <div className="h5">
                      The following proposal lays out the work details including
                      service charges. If you have questions, please feel free
                      to reach out via email or phone.
                    </div>
                    <div className="d-flex justify-content-center my-5 h5">
                      {" "}
                      Thank you!
                    </div>
                    <div className="mt-5 d-flex h5 justify-content-between mobile-align">
                      <div>
                        <b> Expiry Date : {this.props.expiryDate}</b>
                      </div>
                      <div>
                        {data.currentUser.name} <br />
                        AM Webtech Pvt. Ltd <br />
                        {data.currentUser.email}
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={footerImage}
                  style={{
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  alt="footer"
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="m-2"
              onClick={this.props.handleAdd}
              // onClick={this.props.id ? this.handleUpdate : this.handleAdd}
              size="sm"
            >
              {this.props.id ? "New Copy" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default PdfModule;
