import React, { Component } from "react";
import axios from "axios";
import {Form} from 'react-bootstrap'
import config from '../../env/config';
// import config from '../.././config/config.js'
import DataTable from 'react-data-table-component'
import {matchSorter} from 'match-sorter'

class table extends Component {
    state = {  }
    componentDidMount = () =>{
        axios
      .post(config[config.currentUser.user_type].getRegion, {
        token: config.currentUser.token,
      })
      .then(res => {
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          regionList: array,
          data:array
        });
      })
      .catch(error => {
        this.setState({
          loader: false,
        });
      });
    
    }
    
    handleSelect = async ({ currentTarget: input }) => {
        await this.setState({
            [input.name]: input.value,
          });
        this.handleFilter(this.state.searchString)
    };
    
    handleFilter = (string) =>{
        const data = this.state.data
        let z = matchSorter(data, string, {keys: ['regionName']})
        this.setState({
            regionList:z
        })
    }
    render() { 
      const pagiPage = Number(5)
        const columns = [
            {
              name: 'Region Name',
              selector: 'regionName',
              sortable: true,
            },
            {
              name: 'Region Factor',
              selector: 'regionFactor',
              sortable: true,
            //   right: true,
            },

            {
                name: 'Status',
                selector: 'status',
                sortable:true
            }
          ];
          const customStyles = {
            rows: {
              style: {
                // backgroundColor:'lightgreen'
              }
            },
            headCells: {
              style: {
                // paddingLeft: '8px', // override the cell padding for head cells
                // paddingRight: '8px',
                backgroundColor:'lightblue'

              },
            },
            cells: {
              style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
              },
            },
          };
          
        return ( <div >
            <DataTable title ='Region list'
             columns = {columns} 
             data ={this.state.regionList}
             selectableRows
             striped
             pagination 
             responsive      
             selectableRowsHighlight
             //  progressPending
             button
             highlightOnHover
             pointerOnHover
             fixedHeader
             paginationPerPage ={pagiPage}
             subHeader 
             customStyles ={customStyles}
             subHeaderComponent ={
             <Form.Group>
                <Form.Control
                name='searchString' 
                value ={this.state.searchString} 
                placeholder ='Search Here..' 
                onChange={this.handleSelect}/>
             </Form.Group>}  />
        </div> );
    }
}
 
export default table;