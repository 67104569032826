import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
class Footer extends Component {
    state = {  }
    render() { 
        return ( <div >
        <Navbar className='navbar navbar-light fixed-bottom' style={{
        backgroundColor: '#eeeeee',
      }} >
        <Navbar.Brand  >
        </Navbar.Brand>
        
        
        <Nav className="ml-auto">
        
            <Nav.Link >
            © AM Webtech Pvt. Ltd 2020
            </Nav.Link>

          </Nav>
      </Navbar>
        </div> );
    }
}
 
export default Footer;