import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import {FaPlus,FaRegEdit,FaRegTrashAlt} from 'react-icons/fa'
import { Modal, Loader ,Tooltip, Whisper,Toggle,Alert} from "rsuite";

import Pagination from "../../common/pagination";
import { paginate } from "../../common/paginate";
import { Table, Button, Form, Col, Row ,Container} from "react-bootstrap";
import config from "../../../env/config";
import {matchSorter} from 'match-sorter'

class Tab extends Component {
  state = {
    addEditLoading: false,
    displayLength: 7,
    addColumn: false,
    loading: false,
    page: 1,
    sortcolumn: { path: "id", order: "asc" },
    data: [],
    sm: false,
    username: "",
    email: "",
    smd: false,
    loader: true,

    edit: 0,
    errorlen: 1,
    errors: {
      countryCode: "",
      countryName: "",
    },
  };
  schema = {
    countryName: Joi.string().min(3).max(24).required().label('Country Name'),
    countryCode: Joi.string().min(3).max(24).required().label('Country Code'),
  };
  validate = () => {
    const options = { abortEarly: false };
    const data = {
      countryName: this.state.countryName,
      countryCode: this.state.countryCode,
    };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    if (!schema[name]) {
      return "";
    }
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : ""; //chnages here 1 of else part in strinng to null
  };
  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else errors[input.name] = "";
    const errorlen = errorMessage;
    
    if (input.name === "countryname") {
      await this.setState({
        [input.name]: input.value,
        errors,
        errorlen, 
      });
      this.checkAlreadyExitSerivcePackages();
    } else {
      this.setState({
        [input.name]: input.value,
        errors,
        errorlen,
      });
    }
  };
   handleSelect = async ({ currentTarget: input }) => {
    await this.setState({
        [input.name]: input.value,
      });
    this.handleFilter(this.state.searchString)
  };

  handleFilter = (string) =>{
      const dataForFilter = this.state.dataForFilter
      let z = matchSorter(dataForFilter, string, {keys: ['countryName','countryCode']})
      this.setState({
          data:z,page:1
      })
  }
  checkAlreadyExitSerivcePackages = async () => {
    if (this.state.data.length) {
      if (!this.state.id) {
        let existSubRegion = this.state.data.filter(
          (pack) => {
            // console.log(pack)
            return this.state.id !== pack.id &&
            pack.countryname.toLowerCase() ===
              this.state.countryname.toLowerCase()
          } );
          if (existSubRegion.length)   {
            // console.log(existSubRegion);
            this.danger("already Exist package");
            this.setState({
              checkAlreadyExit: true,
            });         
          } else {
            this.setState({
              checkAlreadyExit: false,
            });  
          }
      } else {
        let existSubRegion =  this.state.data.filter(
          (pack) => {
           return pack.countryname.toLowerCase() ===
            this.state.countryname.toLowerCase()
          });
          if (existSubRegion.length)   {
            this.danger("already Exist package");
            this.setState({
              checkAlreadyExit: true,
            });         
          } else {
              this.setState({
                checkAlreadyExit: false,
              });  
          }
      }      
    }
  };
  handleChangePage = (dataKey) => {
    this.setState({
      page: dataKey,
    });
  };
  setSmTrue = () => {
    this.setState({
      sm: true,
      user: {},

    });
  };
  setSmFalse = () => {
    this.setState({
      errors: {},
      countryname: "",
      countryCode: "",
      sm: false,
      addEditLoading: false,
      checkAlreadyExit: false,
      id:''
    });
  };
  setSmdFalse = () => {
    this.setState({
      smd: false,
      errors: {},
      countryname: "",
      countryCode: "",
      addEditLoading: false,
      checkAlreadyExit: false,
      id:''
    });
  };
  componentDidMount = () => {
    axios
      .post(config[config.currentUser.user_type].getRegion, {
        token: config.currentUser.token,
      })
      .then(res => {
        // console.log(res.data.data)
        let array = [];
        if (res && res.status === 200) {
          array = res.data.data;
        }
        this.setState({
          regionList: array,
          
        //   loader: false,
        });
        this.getSubRegion()
      })
      .catch(error => {
        this.setState({
          loader: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
        console.log(error);
      });
      
  };
  getSubRegion = () => {
        axios
          .post(config[config.currentUser.user_type].getSubRegion, {
            token: config.currentUser.token,
          })
          .then((res) => {
            // console.log(res.data.data)
            let array = [];
            if (res && res.status === 200) {
              array = res.data.data;
            }
            this.setState({
              data: array,
              dataForFilter:array,
              loader: false,
            });
          })
          .catch(error => {
            this.setState({
              loader: false,
            });
            this.danger(
              "There is Having Some Problem from the server, Please try Again Later!"
            );
          console.log(error);

          });
          
  };
  onAddSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }else if (this.state.checkAlreadyExit) {
      this.danger("already Exist Region");
      return;
    }
    this.setState({
      addEditLoading: true,
    });
    // console.log(this.state)
    axios
      .post(config[config.currentUser.user_type].addSubRegion, {
        countryName: this.state.countryName,
        countryCode: this.state.countryCode,
        regionId:this.state.regionId,
        token: config.currentUser.token,
      })
      .then(res => {
        if (res.data.status === 200) {
          this.alert("Successfully Added!");
          let resRegion = res.data.data;
        //   config.globalData.regions = [...this.state.data, resRegion];

          this.setState({
            data: [...this.state.data, resRegion],
            countryName: "",
            countryCode: "",
            id: "",
            sm: false,
            edit: 1,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });

  };
  handleEdit = (data) => {
    this.setState({
      edit: 1,
      countryName: data.countryName,
      countryCode: data.countryCode,
      regionId: data.regionId,
      status:data.status,
      id: data.id,
      sm: true,
    });
  };
  onUpdate = (event) => {
    if (event) {
      event.preventDefault();
    }
    const errors =this.validate()
    if (errors) {
      this.setState({
        errors,
      });
      return;
    }else if (this.state.checkAlreadyExit) {
      this.danger("already Exist package");
      return;
    }

    this.setState({
      addEditLoading: true,
    });
    
    const { countryCode,countryName,regionId ,status,id} = this.state;
    const region = {
        countryName,
      countryCode,
      regionId,
      status,
      token: config.currentUser.token
    };
    axios
      .post(config[config.currentUser.user_type].updateSubRegion + id, region)
      .then(res => {
        if (res.data.status === 200) {
          this.alert("Successfully Updated!");

          let resRegionUpd = res.data.data;
          let found = this.state.data.filter(
            (arr) => arr.id === resRegionUpd.id
          );
          if (found.length) {
            const index = this.state.data.indexOf(found[0]);
            // this.state.data[index] = resRegionUpd;

            let data = [...this.state.data]
            data[index] = resRegionUpd;
            this.setState({data});

            // this.setState({
            //   data:{...this.state.data,[index]:resRegionUpd}
            // })
          }

          this.setState({
            sm: false,
            countryName: "",
            countryCode: "",
            regionId:'',
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }else{
          this.danger('Please Try Later!')
        }
      })
      .catch(error => {
        this.setState({
          addEditLoading: false,
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  
  };
  handleActiveInactive = async (data)=>{
    await this.setState({
       edit: 1,
       countryName: data.countryName,
       countryCode: data.countryCode,
       regionId:data.regionId,
       status: Number(data.status) === 1 ? 2 : 1 ,
       id: data.id,
     });
     this.onUpdate();
 
   
  };
  handleRemove = (data) => {
    this.setState({
      smd: true,
      id: data.id,
    });
  };
  handleDelete = () => {
    const id = this.state.id;
    this.setState({
      addEditLoading: true,
    });
    const user = {
      token: config.currentUser.token,
    };

    axios
      .post(config[config.currentUser.user_type].deleteSubRegion + id, user)
      .then(res => {
        if (res.data.status === 200) {
          this.alert("Successfully Deleted!");
          const found = this.state.data.filter((obj) => obj.id !== id);
          if (found.length) {
            let page = ''
            if(found.length % this.state.displayLength){
              page =this.state.page
            }else{
              page = Math.ceil(found.length  / this.state.displayLength)
            }

            this.setState({
              data: found,
              page,
            });
          } else {
            this.setState({
              data: [],
              page: 1,
            });
          }

          this.setState({
            smd: false,
            countryname: "",
            countryCode: "",
            id: "",
            edit: 0,
            addEditLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          addEditLoading: false,
          // tableMessage:'There is Having Some Problem from the server, Please try Again Later!'
        });
        this.danger(
          "There is Having Some Problem from the server, Please try Again Later!"
        );
      });
  };
  danger = (s) => {
    Alert.warning(s,4000)
    // Notification.error({
    //   title: "Error",
    //   description: s,
    // });
  };
  alert = (s) => {
    Alert.success(s)
    // Notification.success({
    //   title: s,
    //   // description:s
    // });
  };
  showRegionName =  (id) => {
      // console.log(id)
    let filtered = this.state.regionList.filter(arr => arr.id === id);
    if (filtered.length) {
      return filtered[0].regionName;
    } else {
      return "";
    }
    // console.log(filtered)
  };
  render() {
    const filterData = paginate(
      this.state.data,
      this.state.page,
      this.state.displayLength
    );
    let countryNameErrorLen = this.state.errors.countryName
      ? this.state.errors.countryName.length
      : 0;
    let countryCodeErrorLen = this.state.errors.countryCode
      ? this.state.errors.countryCode.length
      : 0;

			const filterRegion = config.globalData.regions?config.globalData.regions.filter((ser) => { return Number(ser.status) === 1}):{}
			
    return (
      <div>
 
        

        <Modal size='xs' backdrop={false} show={this.state.sm} onHide={this.setSmFalse}>
          <Modal.Header>
            <Modal.Title>{this.state.id ? "Update Region" : "Add Region"}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form onSubmit={this.state.id ? this.onUpdate : this.onAddSubmit}>
              <Form.Group>
                <Form.Label>Country Name</Form.Label>
                <Form.Control
                  name="countryName"
                  value={this.state.countryName}
                  onChange={this.handleChange}
                  placeholder="Enter Region Name"
                />
                <div
                  className={
                    countryNameErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.countryName}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Country Code</Form.Label>
                <Form.Control
                  name="countryCode"
                  value={this.state.countryCode}
                  onChange={this.handleChange}
                  placeholder="Enter Country Code"
                />
                <div
                  className={
                    countryCodeErrorLen === 0 ? "" : "alert alert-danger p-1"
                  }
                >
                  {this.state.errors.countryCode}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Region</Form.Label>
                <Form.Control
                  as='select'
                  name="regionId"
                  value={this.state.regionId}
                  onChange={this.handleChange}
                  placeholder="Enter Region Factor"
                >
                    {filterRegion.map(reg => (
                      <option key={reg.id} value={reg.id}>
                        {reg.regionName}
                      </option>
                    ))}
                    {/* {<option value={}>{}</option>} */}
                </Form.Control>
               
              </Form.Group>

              <Button disabled={this.state.errorlen} type="submit">
                {this.state.id ? "Update" : "Add"}
              </Button>
              {this.state.addEditLoading ? (
                <Loader
                  size="md"
                  color="#4285f4"
                  center
                  backdrop
                  content="Processing Please Wait."
                />
              ) : (
                ""
              )}
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="xs"
          backdrop={false}
          show={this.state.smd}
          onHide={this.setSmdFalse}
          style={{
            marginTop:'100px'
          }}
        >
          <Modal.Header>
            <Modal.Title>Are You Sure you Want to Delete ?</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              onClick={() => this.handleDelete()}
              className="m-2 btn btn-danger"
            >
              Delete
            </Button>
            <Button onClick={() => this.setSmdFalse()} className="m-2">
              Cancel
            </Button>
            {this.state.addEditLoading ? (
              <Loader
                size="md"
                color="#4285f4"
                center
                backdrop
                content="Deleting Please Wait."
              />
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>

      <Container fluid className="">
      <Row>
      <Col className="">
      <div>
        <div className="my-3 d-flex flex-row justify-content-between h4">Manage Sub Region
          
        <div className="d-flex">
        <Form.Group className="mb-0" >
          <Form.Control placeholder='Search Here.' 
            name='searchString' 
            value ={this.state.searchString} 
            onChange={this.handleSelect}
          />
        </Form.Group>
        <Button className=" btn-sm ml-3" onClick={() => this.setSmTrue()}>
        <FaPlus className='mr-2 mb-1'/>
          Add New
        </Button>
        </div>
          
        </div>
        <div className="my-custom-scrollbar"> 
        <Table striped bordered hover responsive="lg" className="mb-0">
          <thead>
            <tr>
              <th>S.No</th>
              <th> Country Name</th>
              <th>Country Code</th>
              <th>Region</th>
              <th>Status</th>

              <th>Actions</th>
            </tr>
          </thead>
          {this.state.loader ? (
            <Loader size="lg" center backdrop content="Loading Please Wait.." />
          ) : (
            <tbody>
              {filterData.map((data,index) => (
                <tr key={data.id}>
                  <td>{this.state.displayLength *(this.state.page -1)+ index+1}</td>
                  <td>{data.countryName}</td>
                  <td>{data.countryCode}</td>
                  <td>{this.showRegionName(data.regionId)}</td>
                  <td>
                  <Toggle 
                
                  name='status'
                  size="md" 
                  checkedChildren="Active" 
                  unCheckedChildren="Inactive"
                  defaultChecked={Number(data.status) === 1 ? true : false}
                   onChange={()=>this.handleActiveInactive(data)}/>
                  </td>
                  <td>
                  <div className="d-flex">
                  <Whisper
                      trigger="hover"
                      placement='bottom'
                      speaker={
                        <Tooltip>
                          Edit
                        </Tooltip>
                      }>
                      <button 
                      onClick={() => {
                        this.handleEdit(data);
                      }}
                      className="btn btn-primary btn-sm"
                      style={{margin:'1px'}}
                    >
                     <FaRegEdit/>
                    </button>
                  </Whisper>
                    
                    
                    <Whisper
                      trigger="hover"
                      placement='bottom'
                      speaker={
                        <Tooltip>
                          Delete
                        </Tooltip>
                      }
                    >
                      {/* <Button appearance="subtle">click</Button> */}
                      <button disabled={data.id === 104?true:false}
                      onClick={() => this.handleRemove(data)}
                      className="btn btn-danger  btn-sm"
                      style={{margin:'1px'}}
                    >
                      <FaRegTrashAlt/>
                    </button>
                    </Whisper>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
    </div>
        {filterData.length === 0 ? (
          <div 
            style={{
              fontSize: "24px",
              textAlign: "center",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.loader?'':'No Items, Please Add One'}
          </div>
        ) : (
          ""
        )}

        {filterData.length === 0 ? (
          ""
        ) : (
          <Pagination
            itemsCount={this.state.data.length}
            pageSize={this.state.displayLength}
            currentPage={this.state.page}
            onPageChange={this.handleChangePage}
          />
        )}
         </div>
        </Col>
          </Row>
          </Container>
      </div>
    );
  }
}

export default Tab;
